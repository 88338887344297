// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalBillFormDiv {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  color: #000;
}

.TotleBillDiv {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  padding-right: 60px;
}

.TotalBillQ {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}

.TotalBillA {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.TotalBillFNL {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
}

.ButtonColor {
  border-radius: 8px;
  border: 1px solid #c3cbdc;
  opacity: 0.5;
  background: #daddec;
  color: var(--main, #0f1c35);
  font-weight: 600;
}

.billModal {
  max-width: 900px;
  height: 360px;
}

.bill {
  background-color: #3b58ff;
  color: #fff;
}

.bill2 {
  background-color: #424553;
  color: #fff;
}

.bill:hover {
  background-color: #3b58ff;
  color: #fff;
}

.bill2:hover {
  background-color: #424553;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/purchasebills/CreatePurchaseBill.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,2BAAA;EACA,kBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;AACD;;AAEA;EACC,aAAA;EACA,yBAAA;EACA,SAAA;EACA,mBAAA;AACD;;AACA;EACC,cAAA;EACA,eAAA;EACA,gBAAA;AAED;;AAAA;EACC,WAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAGD;;AAAA;EACC,WAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAGD;;AAAA;EACC,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,2BAAA;EACA,gBAAA;AAGD;;AAAA;EACC,gBAAA;EACA,aAAA;AAGD;;AADA;EACC,yBAAA;EACA,WAAA;AAID;;AAFA;EACC,yBAAA;EACA,WAAA;AAKD;;AAHA;EACC,yBAAA;EACA,WAAA;AAMD;;AAJA;EACC,yBAAA;EACA,WAAA;AAOD","sourcesContent":[".TotalBillFormDiv {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\tpadding-left: 10px;\n\talign-items: center;\n\tgap: 10px;\n\tfont-weight: 600;\n\tcolor: #000;\n}\n\n.TotleBillDiv {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tgap: 30px;\n\tpadding-right: 60px;\n}\n.TotalBillQ {\n\tcolor: #000000;\n\tfont-size: 14px;\n\tfont-weight: 600;\n}\n.TotalBillA {\n\tcolor: #000;\n\tfont-size: 14px;\n\tfont-weight: 400;\n\tline-height: 100%;\n}\n\n.TotalBillFNL {\n\tcolor: #000;\n\tfont-size: 18px;\n\tfont-weight: 700;\n\tline-height: 100%;\n}\n\n.ButtonColor {\n\tborder-radius: 8px;\n\tborder: 1px solid #c3cbdc;\n\topacity: 0.5;\n\tbackground: #daddec;\n\tcolor: var(--main, #0f1c35);\n\tfont-weight: 600;\n}\n\n.billModal {\n\tmax-width: 900px;\n\theight: 360px;\n}\n.bill {\n\tbackground-color: #3b58ff;\n\tcolor: #fff;\n}\n.bill2 {\n\tbackground-color: #424553;\n\tcolor: #fff;\n}\n.bill:hover {\n\tbackground-color: #3b58ff;\n\tcolor: #fff;\n}\n.bill2:hover {\n\tbackground-color: #424553;\n\tcolor: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
