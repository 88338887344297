import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { fetchPurchaseReceive, sendEmail } from '../../services/purchase_receive';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLoader from '../../component/PageLoader';
import SendEmail from '../components/SendEmail';

function PurchaseReceiveSendEmail() {
  const params = useParams()
  const { id } = params
  const { userInfo } = useSelector((state) => state)
  const suburl = localStorage.getItem('suburl');
  const navigate = useNavigate();
  const [toEmails, setToEmails] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [purchaseReceiveDetails, setPurchaseReceiveDetails] = useState(null)
  const [emailFormData, setEmailFormData] = useState({
    purchase_receive_id: null,
    from: "",
    to: [],
    subject: "",
    body: "",
    isAttachment: true
  })

  // sending email
  async function handleSend(formData) {
    try {
      await sendEmail(formData)
      toast.success("Email Sent Successfully.")
      navigate(`/user/${suburl}/purchases-recieve`)
    } catch (error) {
      throw error
    } finally {

    }
  };

  function handleClose() {
    navigate(`/user/${suburl}/purchases-receive`)
  }


  async function handleFetchPurchaseReceive() {
    try {
      setIsLoading(true)
      const { purchaseReceive } = await fetchPurchaseReceive({ filterBy: "purchase_receive_id", filterValue: id })
      const purchaseReceiveData = purchaseReceive[0]
      setPurchaseReceiveDetails(purchaseReceiveData)
    } catch (error) {
      toast.error("Something went wrong!")
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (purchaseReceiveDetails && userInfo) {

      const { purchase_receive_id } = purchaseReceiveDetails
      const vendorContactEmailList = purchaseReceiveDetails?.purchase_order.vendor?.contact_person?.length > 0 ? purchaseReceiveDetails?.purchase_order.vendor?.contact_person?.map((contact) => {
        return contact?.email
      }) : []

      setEmailFormData((prev) => ({
        ...prev,
        to: vendorContactEmailList,
        from: `${userInfo?.userInfo?.data?.userData?.first_name} ${userInfo?.userInfo?.data?.userData?.last_name}`,
        purchase_receive_id,
        subject: `Purchase Receive from ${userInfo?.userInfo?.data?.userData?.first_name + " " + userInfo?.userInfo?.data?.userData?.last_name} (Purchase Receive: #${purchase_receive_id})`
      }))

      setToEmails(vendorContactEmailList)
    }
  }, [purchaseReceiveDetails, userInfo])

  useEffect(() => {
    if (id) {
      handleFetchPurchaseReceive()
    }
  }, [id])

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <SendEmail pdfName="Purchase Receive" pdfId={`PR-${purchaseReceiveDetails?.purchase_receive_id}`} toEmails={toEmails} formData={emailFormData} handleSend={handleSend} handleClose={handleClose} />
  );
}

export default PurchaseReceiveSendEmail