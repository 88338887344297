import React from 'react'
import Modal from '../../../../component/modal/Modal'
import BulkPaymentTerms from '../../BulkPaymentTerm'

function VendorBulkPaymentTermModal({ openPaymentTermsModal, setOpenPaymentTermsModal, getAllVendor, setBulkAction, bulkAction, handleBulkPaymentTermsUpdate }) {
  return (
    <Modal
      className="add-payment-terms-modal"
      isOpen={openPaymentTermsModal}
      onClose={() => setOpenPaymentTermsModal(false)}
      title="Payment Terms"
      cancelButton={true}
    >
      <BulkPaymentTerms
        getAllVendor={getAllVendor}
        setBulkAction={setBulkAction}
        bulkAction={bulkAction}
        isOpen={openPaymentTermsModal}
        onClose={() => setOpenPaymentTermsModal(false)}
        handleSaveClick={handleBulkPaymentTermsUpdate}
      />
    </Modal>
  )
}

export default VendorBulkPaymentTermModal