import React from 'react'
import AdvanceFilter from '../../../../component/Filter/AdvanceFilter';

function VendorAdvanceFilter({ isAdvanceFilterOpen, handleOnKeyDownCb, handleAdvanceFilterDropdown, advanceFilterSelectInputList, handleRemoveSelectedFilterInput, advanceFilterOptions, handleOnFocusSelectedFilterInput, setadvanceFilterSelectInputList, setIsAdvanceFilterOpen, handleApplyFilters }) {
  return (
    <AdvanceFilter
      modalLabel="Note: You can create a bill for either Yet to Receive Items or Received Items."
      isOpen={isAdvanceFilterOpen}
      onKeyDownCb={handleOnKeyDownCb}
      advanceFilterOptions={advanceFilterOptions.vendor}
      handleAdvanceFilterDropdown={handleAdvanceFilterDropdown}
      advanceFilterSelectInputList={advanceFilterSelectInputList}
      handleRemoveSelectedFilterInput={handleRemoveSelectedFilterInput}
      onFocusSelectedFilterInput={handleOnFocusSelectedFilterInput}
      handleOnChangeSelectedFilterInput={(key, value) => {
        const updatedData = advanceFilterSelectInputList.map((item) => {
          if (item?.key === key) {
            return {
              ...item,
              value,
            };
          }

          return item;
        });
        setadvanceFilterSelectInputList(updatedData);
      }}
      onClose={() => {
        setIsAdvanceFilterOpen(false);
      }}
      onApply={handleApplyFilters}
    />
  )
}

export default VendorAdvanceFilter