import React from 'react';
import Modal from '../../../../component/modal/Modal';
import Button from '../../../../component/ui/Button';

function UpdatePOStatusModel({
	isUpdatePOStatusModelOpen,
	setIsUpdatePOStatusModelOpen,
	handleUpdatePOStatus,
	status,
}) {
	return (
		<Modal
			className="deleteVendorsModal"
			title="Confirm Update PO Status"
			isOpen={isUpdatePOStatusModelOpen}
			onClose={() => {
				setIsUpdatePOStatusModelOpen(false);
			}}
			cancelButton={() => {
				setIsUpdatePOStatusModelOpen(false);
			}}
		>
			<p
				style={{
					textAlign: 'center',
					backgroundColor: '#E6F5FF',
					padding: '10px',
					borderRadius: '5px',
					fontWeight: '600',
					color: 'black',
				}}
			>
				Are you sure you want to change update PO status of Purchase Order?
			</p>

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: 10,
					justifyContent: 'end',
				}}
			>
				<Button
					type="button"
					className="mt-0 btn-gray add-pro"
					button="Cancel"
					onClick={() => {
						setIsUpdatePOStatusModelOpen(false);
					}}
				/>

				<Button
					type="button"
					button="Confirm"
					className="mt-0 px-4 btn-danger relative"
					onClick={handleUpdatePOStatus}
				/>
			</div>
		</Modal>
	);
}

export default UpdatePOStatusModel;
