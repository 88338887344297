import React, { useEffect, useState } from 'react';
import Button from '../../component/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const PaymentMadeDetails = () => {
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();


	const navigateRouter = (path) => {
		navigate(path);
	};




	return (
		<div style={{ backgroundColor: '#EDEDF7' }}>
			<div className="">
				<div className="">
					<div className="product-pg-header add-product">
						<p>Payment Made Detail</p>
						<div className="add-product-button">
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Edit"
							// onClick={() => handleSavePurchaseReceive(0)}
							/>
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Pdf/Print"
							// onClick={() => handleSavePurchaseReceive(1)}
							/>
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Send Email"
							// onClick={() => handleSavePurchaseReceive(1)}
							/>
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Delete"
							// onClick={() => handleSavePurchaseReceive(1)}
							/>
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => navigateRouter(`/user/${suburl}/payment-made`)}
							/>
						</div>
					</div>
				</div>



				{/* Payment Made Details */}
				<div >
					<div style={{ maxWidth: "750px", margin: "19px auto", maxHeight: "666px", backgroundColor: "#fff", padding: "20px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
						{/* Store Details */}
						<div style={{ border: '1px solid #555B67', padding: '12px' }}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ marginBottom: "20px", }}>
									<h4 style={{ marginBottom: "13px", fontWeight: "bold", fontSize: '22px' }}>Medical Store</h4>
									<p style={{ color: "#555B67", fontSize: '14px' }}>Rajasthan</p>
									<p style={{ color: "#555B67", fontSize: '14px' }}>India</p>
									<p style={{ color: "#555B67", fontSize: '14px' }}>aditbhargava.devoy@gmail.com</p>
								</div>

								<div style={{ marginTop: '16px' }}>
									<h2 style={{ fontSize: '30px', color: '#000000', fontWeight: '600', textAlign: 'end', marginTop: '8px', marginBottom: '12px' }}>BILL</h2>
									<p>Bill#ABCD</p>
								</div>
							</div>

							{/* Payment Info */}

							<h5 style={{
								margin: "8px 262px 28px 280px",
								fontWeight: "600",
								borderBottom: '1px solid black',
								fontSize: '20px',
								textAlign: 'center',
								maxWidth: '148px',
								paddingBottom: "5px"
							}}>Payment Made</h5>
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", marginBottom: "20px" }}>

								<div>
									<div className='d-flex'>
										<div style={{ marginTop: '5px', color: '#555B67' }}>
											<p style={{ marginBottom: '16px' }}>Payment#</p>
											<p style={{ marginBottom: '16px' }}>Payment Date</p>
											<p style={{ marginBottom: '16px' }}>Reference Number</p>
											<p style={{ marginBottom: '16px' }}>Payment Mode</p>
											<p style={{ marginBottom: '16px' }}>Paid Through</p>
											<p style={{ marginBottom: '16px' }}>Amount Paid In Words</p>
										</div>
										<div style={{ width: '270px', marginLeft: '35px', fontWeight: 'bold' }}>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '20px' }}>5</p>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '15px' }}>23/03/2024</p>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '15px' }}>Ref-00001</p>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '15px' }}>Cash</p>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '15px' }}>Petty Cash</p>
											<p style={{ borderBottom: '1px solid #E5E6E8' }}>Indian Rupee Two Hundred Twenty Only</p>
										</div>
									</div>
								</div>

								<div style={{ backgroundColor: '#84AD5F', width: '170px', height: '110px', marginTop: '18px', color: 'white', textAlign: 'center' }}>
									<div style={{ marginTop: '32px' }}>
										<p style={{ marginRight: '24px' }}>	Amount Paid</p>
										<p style={{ fontSize: '20px', fontWeight: '600' }}>	Rs. 200.00</p>
									</div>
								</div>
							</div>

							{/* Paid To */}
							<div style={{ marginBottom: "20px" }}>
								<p style={{ fontWeight: "bold", margin: 0 }}>Paid To</p>
								<a href="#" style={{ color: "#3B58FF", textDecoration: "none", fontWeight: "bold" }}>ABC Company Private Limited</a>
							</div>

							{/* Table */}
							<table style={{ width: "100%", border: "1px solid #dee2e6", borderCollapse: "collapse", textAlign: "left" }}>
								<thead>
									<tr style={{ backgroundColor: "#f1f3f5", fontWeight: "bold", color: '#555B67' }}>
										<th style={{ padding: "8px", border: "1px solid #dee2e6", textAlign: 'left' }}>Bill Number</th>
										<th style={{ padding: "8px", border: "1px solid #dee2e6", textAlign: 'left' }}>Bill Date</th>
										<th style={{ padding: "8px", border: "1px solid #dee2e6", textAlign: 'right' }}>Bill Amount</th>
										<th style={{ padding: "8px", border: "1px solid #dee2e6", textAlign: 'right' }}>Payment Amount</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ padding: "8px", border: "1px solid #dee2e6", textAlign: 'left', color: '#3B58FF' }}>
											ABC123
										</td>
										<td style={{ padding: "8px", border: "1px solid #dee2e6", textAlign: 'left', color: '#555B67' }}>23/03/2024</td>
										<td style={{ padding: "8px", border: "1px solid #dee2e6", textAlign: 'right', color: '#555B67' }}>Rs. 220.00</td>
										<td style={{ padding: "8px", border: "1px solid #dee2e6", textAlign: 'right', color: '#555B67' }}>Rs. 220.00</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}

export default PaymentMadeDetails