import React, { useState, useEffect } from 'react';
import Button from '../../component/ui/Button';
import InputSelect from '../../component/inputSelect/InputSelect';
import axios from 'axios';
import { APP_API_URL } from '../../utils/xhr-instance';
import { toast } from 'react-toastify';
import { fetchPaymentTerm } from '../../services/vendor';

const BulkPaymentTerms = ({ bulkAction, getAllVendor, setBulkAction, onClose }) => {
	const [error, setError] = useState('');
	const [paymentTerms, setPaymentTerms] = useState([]);
	const [selectedPaymentTerm, setSelectedPaymentTerm] = useState('');
	const [paymentTermParams, setPaymetTermParams] = useState({
		page: 1,
		limit: 10,
		search: '',
	});

	const handleBulkPaymentTermsUpdate = async (e) => {
		e.preventDefault();
		if (!selectedPaymentTerm) {
			toast.error('Please select Payment Term! Payment terms cannot be empty.');
			return;
		}
		try {
			if (bulkAction.length === 0) {
				throw new Error('Please select vendors!');
			}

			const token = localStorage.getItem('authToken');
			const { data } = await axios.put(
				`${APP_API_URL}/vendor/update-currency-payment-terms`,
				{
					vendor_ids: bulkAction,
					payment_terms: selectedPaymentTerm?.split(' ')[1],
					currency: null,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				},
			);

			getAllVendor();
			setBulkAction([]);
			toast.success(data.message || 'Bulk Payment term updated successfully.');
		} catch (error) {
			toast.error(error.message || 'Something went wrong!');
		} finally {
			onClose();
		}
	};

	const handleFetchPaymentTerm = async () => {
		try {
			const paymentTermData = await fetchPaymentTerm(paymentTermParams);
			if (paymentTermData?.length > 0) {
				const paymentTermOptions = paymentTermData.map((payment) => {
					return {
						key: payment?.payment_terms_id,
						value: 'Net ' + payment?.term_name,
					};
				});
				setPaymentTerms(paymentTermOptions);
			}
		} catch (error) {
			toast.error('Something went wrong!');
		}
	};

	//TODO: Debouncing left
	useEffect(() => {
		handleFetchPaymentTerm();
	}, [paymentTermParams?.search]);

	return (
		<div>
			<form
				className="add-productgroup-form"
				onSubmit={handleBulkPaymentTermsUpdate}
			>
				<span>
					<InputSelect
						options={paymentTerms || []}
						label="Payment Terms"
						returnInputValue={true}
						placeholder="Vendor Payment Terms"
						value={selectedPaymentTerm}
						onFocusCb={handleFetchPaymentTerm}
						onChange={(value) => {
							if (value !== '') {
								setPaymetTermParams((prev) => ({
									...prev,
									search: value,
								}));
							}
						}}
						onSelect={(value) => {
							setSelectedPaymentTerm(value);
						}}
						autoFocus={true}
						error={error?.payment_terms}
					/>
					<Button
						type="submit"
						className="btn-primary mt-4 px-5 w-auto"
						button="Done"
					></Button>
				</span>
			</form>
		</div>
	);
};

export default BulkPaymentTerms;
