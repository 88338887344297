// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice-head {
  background: transparent;
  padding: 20px 0px 5px;
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  min-width: 600px;
}

.status-toggle {
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 0.5rem;
}
.status-toggle .form-check-input {
  border-radius: 20px !important;
  width: 35px;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/PurchaseOrderInvoice/index.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;EACA,qBAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,gBAAA;AACD;;AACA;EACC,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;AAED;AADC;EACC,8BAAA;EACA,WAAA;AAGF","sourcesContent":[".invoice-head {\n\tbackground: transparent;\n\tpadding: 20px 0px 5px;\n\twidth: 60%;\n\tmargin: auto;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tmin-width: 600px;\n}\n.status-toggle {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: end;\n\tcolumn-gap: 0.5rem;\n\t.form-check-input {\n\t\tborder-radius: 20px !important;\n\t\twidth: 35px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
