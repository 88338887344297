import React, { useEffect, useState } from 'react';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import InputField from '../../../../component/form/FormInput';
import { fetchVendors } from '../../../../services/vendor';
import OrderTable from '../../OrderTable';
import { toast } from 'react-toastify';
import tooltipIcon from '../../../../assets/image/tooltip_vendor name.png';
import OpenPOAlert from '../../../../assets/image/Open PO Alert.svg';
import settingIcon from '../../../../assets/image/settings2.svg';
import EmailCommunication from '../EmailCommunication/EmailCommunication';
import {
	addDeliveryMethod,
	fetchPurchaseOrderNumber,
	getDeliveryMethod,
} from '../../../../services/purchaseOrder';
import Modal from '../../../../component/modal/Modal';
import Button from '../../../../component/ui/Button';
import Configure from '../../../../component/Configure/Configure';
import AddVendorModal from '../../AddNewVendor';
import VendorDetailsDrawer from '../VendorDetailsDrawer';

function PurchaseOrderForm({
	formData,
	handleFormData,
	disabled,
	paramId,
	tableError,
	error,
	setIsTableErrorShow,
}) {
	const [vendorDetails, setVendorDetails] = useState(null);
	const [vendorDetailsList, setVendorDetailsList] = useState([]);
	const [deliveryMethodList, setDeliveryMethodList] = useState([]);
	const [vendorList, setVendorList] = useState([]);
	const [vendorParams, setVendorParams] = useState({
		filterBy: '',
		filterValue: '',
	});
	const [searchDeliveryMethod, setSearchDeliveryMethod] = useState(formData?.delivery_method);
	const [isAddNewDeliveryMethodOpen, setIsAddNewDeliveryMethodOpen] = useState(false);
	const [addNewDeliveryMethod, setAddNewDeliveryMethod] = useState('');
	const [isVendorDetailsDrawerOpen, setIsVendorDetailsDrawerOpen] = useState(false);
	const [isConfigurePurchaseOrderModalOpen, setIsConfigurePurchaseOrderModalOpen] = useState(false);
	const [isAddNewVendorModalOpen, setIsAddNewVendorModalOpen] = useState(false);
	const [isAutoGeneratedPurchaseOrder, setIsAutoGeneratedPurchaseOrder] = useState(true);
	const [forceRender, setForceRender] = useState(false);
	const [currentDate, setCurrentDate] = useState('');
	const [communicationEmail, setCommunicationEmail] = useState([]);

	function triggerForceRender() {
		setForceRender(!forceRender); // Toggle the state to force re-render
	}

	function handleSetVendorDetails(vendor_id) {
		// Find the vendor details based on the provided vendor ID
		const vendorDetails = vendorDetailsList.find((vendor) => vendor?.vendor_id === vendor_id);

		// console.log('Vendor Details Found:', vendorDetails);
		// Update the vendor details state
		setVendorDetails(vendorDetails);

		if (vendorDetails?.contact_person) {
			// Extract emails from contact_person array
			const emails = vendorDetails.contact_person.reduce((acc, contact) => {
				if (contact?.email) {
					acc.push(contact.email);
				}
				return acc;
			}, []);

			// Update form data with extracted emails
			handleFormData('email_communication', emails);

			if (emails.length > 0) {
				const mappedEmails = emails.map((email) => ({
					isSelected: false,
					email,
				}));

				setCommunicationEmail(mappedEmails);
			}
		}
		handlePurchaseOrder();
	}

	//TODO: Add Delivery method
	//TODO: Create new delivery method set to form data and into deliveryMethod list and auto select to delivery method
	async function handleAddNewDeliveryMethodFrom() {
		const body = {
			method_name: addNewDeliveryMethod,
		};

		const deliveryMethod = await addDeliveryMethod(body);
		const updatedDeliveryMethodList = [
			{ key: deliveryMethod?.method_name, value: deliveryMethod?.method_name },
			...deliveryMethodList,
		];
		setDeliveryMethodList(updatedDeliveryMethodList);
		setIsAddNewDeliveryMethodOpen(false);
		setAddNewDeliveryMethod('');
		handleFormData('delivery_method', deliveryMethod?.method_name);
		triggerForceRender();
	}

	// TODO: Search Vendor with api call,
	async function handleFetchSearchVendor() {
		try {
			const data = await fetchVendors(vendorParams);

			const vendorOptionList = data.vendors.map((vendor) => {
				return {
					key: vendor?.vendor_id,
					value: vendor?.vendor_name,
				};
			});

			if (formData.vendor_id) {
				const isVendorFound = vendorOptionList.find((vendor) => vendor.key === formData.vendor_id);
				if (!isVendorFound) {
					setVendorParams((prev) => ({
						...prev,
						filterBy: 'vendor_id',
						filterValue: Number(formData.vendor_id),
					}));
				}
			} else {
				setVendorDetailsList(data.vendors);
				setVendorList(vendorOptionList);
			}
		} catch (error) {
			console.log(error);
		}
	}
	// TODO: Search Delivery method with api call,
	async function handleSearchDeliveryMethod() {
		try {
			const params = {
				filterBy: 'method_name',
				filterValue: searchDeliveryMethod,
			};

			const data = await getDeliveryMethod(params);

			const deliveryMethodOptionList = data.map((delivery) => {
				return {
					key: delivery?.method_name,
					value: delivery?.method_name,
				};
			});
			setDeliveryMethodList(deliveryMethodOptionList);
		} catch (error) {
			toast.error('Something went wrong while searching for delivery method!');
		}
	}

	async function handlePurchaseOrder() {
		try {
			const purchaseOrderNumber = await fetchPurchaseOrderNumber();
			handleFormData('purchase_order_number', purchaseOrderNumber);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}
	const numberOfProducts = formData.purchase_order_products.length;

	const totalQuantity = formData.purchase_order_products.reduce((total, product) => {
		return total + (product.order_quantity || 0); // Ensure that quantity is a number
	}, 0);

	useEffect(() => {
		handleFetchSearchVendor();
		handleSearchDeliveryMethod();
		handlePurchaseOrder();
	}, []);

	useEffect(() => {
		if (vendorParams) {
			handleFetchSearchVendor();
		}

		if (searchDeliveryMethod) {
			handleSearchDeliveryMethod();
		}
	}, [vendorParams, searchDeliveryMethod]);

	useEffect(() => {
		const today = new Date();
		const formattedDate = today.toISOString().split('T')[0];
		setCurrentDate(formattedDate);
	}, []);

	return (
		<div>
			<div
				className="max-w-1297"
				style={{ padding: '1rem 2rem 0' }}
			>
				<div className="row">
					<div className="col-6">
						<InputSelect
							options={vendorList}
							label="Vendor Name"
							placeholder="Vendor Name"
							required={true}
							error={error?.vendor_id}
							value={formData?.vendor_id}
							returnInputValue={false}
							onChange={(value) => {
								setVendorParams((prev) => ({
									...prev,
									filterBy: 'vendor_name',
									filterValue: value,
								}));
							}}
							onSelect={(option) => {
								handleSetVendorDetails(option.key);
								handleFormData('vendor_id', option.key);
							}}
							autoFocus={true}
							onAddNew={() => setIsAddNewVendorModalOpen(true)}
							className="labelClass"
							leftIcons={
								formData?.vendor_id && [
									{
										src: OpenPOAlert,
										alt: 'showVendorDetails',
										onClick: () => setIsVendorDetailsDrawerOpen(true),
									},
									{
										src: tooltipIcon, // Image 2 path
										alt: 'Icon 2',
										onClick: () => setIsVendorDetailsDrawerOpen(true),
									},
								]
							}
						/>
					</div>
					<div className="col-3">
						<InputField
							label="Order Number #"
							placeholder="PO-001"
							type="text"
							required={true}
							error={error?.purchase_order_number}
							value={formData.purchase_order_number}
							onChange={(value) => handleFormData('purchase_order_number', value)}
							disabled={isAutoGeneratedPurchaseOrder}
							rightIcon={{
								src: settingIcon,
								alt: 'Purchase order configure icon',
								onClick: () => setIsConfigurePurchaseOrderModalOpen(true),
							}}
						/>
					</div>
					<div className="col-3">
						<InputField
							label="Date"
							placeholder="Enter Date"
							type="date"
							error={error?.date}
							value={formData.date || currentDate}
							onChange={(value) => {
								handleFormData('date', value);
							}}
							max={currentDate}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-7">
						<InputField
							label="Delivery Address"
							placeholder="Delivery Address"
							type="text"
							required={true}
							error={error?.delivery_address}
							returnInputValue={true}
							value={formData.delivery_address}
							onChange={(value) => handleFormData('delivery_address', value)}
						/>
					</div>

					<div className="col-5">
						<InputField
							label="Expected delivery Date"
							placeholder="expected delivery Date"
							type="date"
							error={error?.expected_delivery_date}
							value={formData.expected_delivery_date}
							onChange={(value) => {
								if (new Date(value) < new Date().setHours(0, 0, 0, 0)) {
									handleFormData('expected_delivery_date', '');
									toast.error('Expected Delivery Date cannot be in the past.');
									return;
								}
								handleFormData('expected_delivery_date', value);
							}}
							required={true}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<InputField
							label="Reference #"
							placeholder="Reference #"
							type="text"
							error={error?.remarks}
							value={formData.remarks}
							onChange={(value) => handleFormData('remarks', value)}
						/>
					</div>
					<div className="col-6">
						<InputSelect
							options={deliveryMethodList}
							onAddNew={() => setIsAddNewDeliveryMethodOpen(true)}
							label="Delivery Methods"
							placeholder="Delivery Methods"
							error={error?.delivery_method}
							returnInputValue={false}
							value={formData.delivery_method}
							onChange={(value) => setSearchDeliveryMethod(value)}
							onSelect={(value) => handleFormData('delivery_method', value.value)}
						/>
					</div>
				</div>
			</div>

			{/* Purchase order Table */}
			<OrderTable
				tableData={formData.purchase_order_products}
				setTableData={handleFormData}
				disabled={disabled}
				paramId={paramId}
				tableError={tableError}
				setIsTableErrorShow={setIsTableErrorShow}
			/>

			{/* <div
				className="d-flex "
				style={{
					border: '1px solid #C3CBDC',
					height: '36px',
					backgroundColor: '#F6F6F9',
					display: 'flex',
					alignItems: 'center',
					paddingLeft: '10px',
					paddingRight: '230px',
					justifyContent: 'space-between',
					width: '97%',
					margin: 'auto',
				}}
			>
				<div>
					<span
						style={{
							color: 'var(--sub, #555B67)',
							fontWeight: '400',
							fontSize: '14px',
						}}
					>
						Total Items :
					</span>
					<span
						style={{
							color: 'var(--sub, #555B67)',
							fontWeight: '700',
							fontSize: '14px',
						}}
					>
						{numberOfProducts}
					</span>
				</div>

				<div
					style={{
						color: 'var(--sub, #555B67)',
						fontSize: 'px',
						fontWeight: '700',
					}}
				>
					<span>Total Quantity:</span> <span>{totalQuantity}</span>
				</div>
			</div> */}
			<EmailCommunication
				formData={formData}
				communicationEmail={communicationEmail}
				setCommunicationEmail={setCommunicationEmail}
				handleFormData={handleFormData}
			/>

			{isAddNewDeliveryMethodOpen && (
				<Modal
					className="delivery-modal"
					title="Add New Delivery Method"
					isOpen={isAddNewDeliveryMethodOpen}
					onClose={() => setIsAddNewDeliveryMethodOpen(false)}
					cancelButton={true}
				>
					<div>
						<InputField
							placeholder="Add New Delivery method"
							value={addNewDeliveryMethod}
							onChange={(value) => {
								setAddNewDeliveryMethod(value);
							}}
						/>
						<br />
						<Button
							button="Add"
							type="button"
							className="btn btn-primary"
							onClick={handleAddNewDeliveryMethodFrom}
						/>
					</div>
				</Modal>
			)}

			{isConfigurePurchaseOrderModalOpen && (
				<Configure
					formData={formData}
					handleFormData={handleFormData}
					isAutoGeneratedPurchaseOrder={isAutoGeneratedPurchaseOrder}
					setIsAutoGeneratedPurchaseOrder={setIsAutoGeneratedPurchaseOrder}
					isConfigurePurchaseOrderModalOpen={isConfigurePurchaseOrderModalOpen}
					setIsConfigurePurchaseOrderModalOpen={setIsConfigurePurchaseOrderModalOpen}
				/>
			)}

			{isAddNewVendorModalOpen && (
				<AddVendorModal
					handleFormData={handleFormData}
					vendorList={vendorList}
					setVendorList={setVendorList}
					onClose={() => setIsAddNewVendorModalOpen(false)}
				/>
			)}

			{
				<VendorDetailsDrawer
					isOpen={isVendorDetailsDrawerOpen}
					onClose={() => setIsVendorDetailsDrawerOpen(false)}
					vendorDetails={vendorDetails}
				/>
			}
		</div>
	);
}

export default PurchaseOrderForm;
