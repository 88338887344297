import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortbydot from '../../assets/image/sort_by_group.svg';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Pagination from '../../component/pagination';

import {
	bulkUpdateCurrencyOrPaymentTerms,
	change_status,
	vendor_list,
} from '../../store/action/vendor';
import { toast } from 'react-toastify';
import { ExportTable } from '../../store/action/export/Export';
import { CreditNoteColoum } from './creditNotecoloum';
import DeleteCrediteNoteModel from './components/DeleteCrediteNoteModel';
// import Modal from '../../component/modal/Modal';
// import AddPaymentTerms from './AddPaymentTerms';
// import UpdateCurrency from './UpdateCurrecny';
// import { PurchaseOrderColoum } from './purchaseOrdereColoum';

const CreditNote = () => {
	const suburl = localStorage.getItem('suburl');
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [vendorList, setVendorLiat] = useState([]);
	const [loader, setLoader] = useState(false);
	const [bulkAction, setBulkAction] = useState([]);
	const [filterparam, setFilterparam] = useState('');
	const [sortParam, setSortParam] = useState('');
	const vendorallList = useSelector((state) => state?.vendorReducer?.vendorList?.data);
	const [tableData, setTableData] = useState([]);
	const [headerDropDown, setHeaderDropDown] = useState('All Credit Note');
	const [stateCondition, setStateCondition] = useState('');
	const [selectedVendorsListForDelete, setSelectedVendorsListForDelete] = useState([]);

	const [isDeleteBulkConfirmationModalOpen, setIsDeleteBulkConfirmationModalOpen] = useState(false);

	const [bulkUpdateList, setBulkUpdateList] = useState([
		{ label: 'Currency' },
		{
			label: 'Payment Terms',
		},
	]);
	const [openPaymentTermsModal, setOpenPaymentTermsModal] = useState(false);

	const [openCurrencyModal, setCurrencyModal] = useState(false);

	const [paymentTerms, setPaymentTerms] = useState('');

	const navigate = useNavigate();

	const navigateRouter = (path) => {
		navigate(path);
	};
	const onRowClick = (id) => {
		if (id) {
			navigate(`/user/${suburl}/credit-note-create/${id}`);
		}
	};

	const getAllList = (param, value) => {
		if (!param) {
			param = `page=${currentPage}&pageSize=20`;
		}
		setLoader(true);
		// setStateCondition(value)
		dispatch(vendor_list(param))
			.then((res) => {
				if (res?.success) {
					setVendorLiat(res?.data);
					setLoader(false);
				} else {
					setVendorLiat([]);
					setLoader(false);
				}
			})
			.catch((err) => {
				setVendorLiat([]);
				setLoader(false);
			});
	};

	const FilterDatalist = (param, value, dropDownData) => {
		setFilterparam(param);
		setStateCondition(value);
		setHeaderDropDown(dropDownData);
		setSortParam('');
		setCurrentPage(1);
	};
	const sortDataList = (param) => {
		setSortParam(param);
		setCurrentPage(1);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		getAllList();
	}, []);

	useEffect(() => {
		let data = [];
		vendorallList?.vendors?.map((ele) => {
			data?.push({
				id: ele.vendor_id,
				NAME: ele?.vendor_name,
				'VENDOR DISPLAY NAME': ele?.vendor_display_name,
				'WORK PHONE': ele?.phone_mobile,
				PAYABLE: 'N/A',
			});
		});
		setTableData(data);
		let toatlPages = Math.ceil(vendorallList?.totalCount / 20);
		setTotalPages(toatlPages);
	}, [vendorallList]);

	useEffect(() => {
		let param = `${filterparam}${filterparam && '&'}${sortParam}${sortParam && '&'
			}page=${currentPage}&pageSize=20`;
		getAllList(param);
	}, [sortParam, filterparam, currentPage]);


	const exportData = (param) => {
		ExportTable(param);
	};

	async function handleBulkDelete() {
		// try {
		// 	await deleteVendorService(bulkAction);
		// 	toast.success('Vendor Deleted Successfully.');
		// 	setIsDeleteBulkConfirmationModalOpen(false);
		// 	setBulkAction([]);
		// 	getAllVendor();
		// } catch (error) {
		// 	toast.error(error?.message || 'Something went wrong');
		// }
	}

	function handleOpenBulkDeleteCrediteNoteModal() {
		const selectedVendorsList = tableData?.reduce((acc, item) => {
			if (bulkAction.includes(item.id)) {
				acc.push(item.NAME);
				return acc;
			}
			return acc;
		}, []);
		setSelectedVendorsListForDelete(selectedVendorsList);
		setIsDeleteBulkConfirmationModalOpen(true);
	}

	return (
		<div>
			{bulkAction && bulkAction.length > 0 ? (
				<div className="product-pg-header">
					<div className="dropdown text-end">
						<p className="d-block link-dark text-decoration-none">Bulk Actions</p>
					</div>
					<div className="add-product-button">
						<Button
							type="button"
							button="Print"
							className="mt-0 px-4 btn-gray"
							onClick={() => window.print()}
						/>

						<Button
							type="button"
							button="Delete"
							className="mt-0 px-4 btn-gray relative"
							onClick={handleOpenBulkDeleteCrediteNoteModal}
						></Button>

						<Button
							type="button"
							button="Bulk Update"
							className="mt-0 px-4 btn-gray relative"
						></Button>

						<Button
							type="button"
							className="mt-0 btn-danger add-pro"
							button=""
							close
							onClick={() => navigateRouter(`/user/${suburl}/credit-note`)}
						/>
						{/* <div
							className="dropdown-menu show font-14 mt-2"
							style={{
								width: '250px',
								position: 'absolute',
								zIndex: 1000,
								maxHeight: '300px',
								overflowY: 'auto',
							}}
						>
							{bulkUpdateList?.map((result, index) => (
								<div
									key={result.id}
									className={`dropdown-item mt-1`}
									onClick={() => {
										if (result.label === 'Currency') {
											setCurrencyModal(true);
										} else {
											setOpenPaymentTermsModal(true);
										}
									}}
								>
									{result.label}
								</div>
							))}
						</div> */}



					</div>
				</div>
			) : (
				<div className="product-pg-header add-product">
					<div className="dropdown text-end">
						<a
							className="d-block link-dark text-decoration-none dropdown-toggle"
							id="dropdownUser1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<p>{headerDropDown}</p>
						</a>
						<ul
							className="dropdown-menu font-14"
							aria-labelledby="dropdownUser1"
							style={{ minWidth: '240px' }}
						>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist('orderBy=product_code&sortBy=asc', '', 'All Credit Note')
									}
								>
									All Credit Note
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() => FilterDatalist('filterBy=status&filterValue=1', 'active', 'Draft')}
								>
									Draft
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist('filterBy=crm&filterValue=0', 'crm', 'Pending Approval ')
									}
								>
									Pending Approval
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() => FilterDatalist('filterBy=crm&filterValue=0', 'crm', 'Open')}
								>
									Open
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() => FilterDatalist('filterBy=crm&filterValue=0', 'crm', 'Close')}
								>
									Close
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() => FilterDatalist('filterBy=crm&filterValue=0', 'crm', 'Void')}
								>
									Void
								</a>
							</li>
						</ul>
					</div>

					<div className="add-product-button column-gap-0">
						<Button
							type="button"
							className="btn-primary mt-0 px-5"
							button="Create New Credit Note"
							add
							onClick={() => navigateRouter(`/user/${suburl}/credit-note-create`)}
						/>
						<div className="dropdown text-end ms-3">
							<a
								href="#"
								className="d-block link-dark text-decoration-none"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img src={sortbydot} />
							</a>
							{/* date , reason, created_at , description,status,reference_number, */}
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li>
									<a className="dropdown-item sortBy">Sort By</a>
								</li>

								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList(`orderBy=vendor_display_name&sortBy=asc`)}
									>
										Created Time
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList(`orderBy=reason&sortBy=asc`)}
									>
										Modified Time
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList(`orderBy=created_at&sortBy=asc`)}
									>
										Date
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList(`orderBy=description&sortBy=asc`)}
									>
										Credit Note#
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList(`orderBy=description&sortBy=asc`)}
									>
										Vendor Name
									</a>
								</li>

								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList(`orderBy=reference_number&sortBy=asc`)}
									>
										Amount
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList(`orderBy=reference_number&sortBy=asc`)}
									>
										Balance
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => exportData(`vendor`)}
									>
										Export Credit Note
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList(`orderBy=reference_number&sortBy=asc`)}
									>
										Refresh
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}

			{isDeleteBulkConfirmationModalOpen && (
				<DeleteCrediteNoteModel
					isDeleteBulkConfirmationModalOpen={isDeleteBulkConfirmationModalOpen}
					setIsDeleteBulkConfirmationModalOpen={setIsDeleteBulkConfirmationModalOpen}
					selectedVendorsListForDelete={selectedVendorsListForDelete}
					handleBulkDelete={handleBulkDelete}
					purchaseOrderLength={bulkAction.length}
				/>

			)}

			{loader ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: '100%', marginTop: '20%' }}
				>
					<span
						className="Page_loader"
						role="status"
						aria-hidden="true"
					></span>
				</div>
			) : (
				<div className="productTable-body">
					<Table
						row={tableData}
						coloum={CreditNoteColoum}
						className="productTable"
						onRowClick={onRowClick}
						action={setBulkAction}
					/>
				</div>
			)}
			<Pagination
				currentPage={currentPage}
				totalPages={totalPages}
				onChangePage={handlePageChange}
			/>

			{/* {openPaymentTermsModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openPaymentTermsModal}
						onClose={() => setOpenPaymentTermsModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<AddPaymentTerms
							isOpen={openPaymentTermsModal}
							onCLose={() => setOpenPaymentTermsModal(false)}
							handleSaveClick={handleBulkPaymentTermsUpdate}
						/>
					</Modal>
				</div>
			)} */}
			{/* {openCurrencyModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openCurrencyModal}
						onClose={() => setCurrencyModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<UpdateCurrency
							isOpen={openCurrencyModal}
							onCLose={() => setCurrencyModal(false)}
							handleSaveClick={handleBulkCurrencyUpdate}
						/>
					</Modal>
				</div>
			)} */}
		</div>
	);
};

export default CreditNote;
