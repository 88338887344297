import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { fetchPurchaseOrders, sendEmail } from '../../services/purchaseOrder';
import { useSelector } from 'react-redux';
import PageLoader from '../../component/PageLoader';
import SendEmail from '../components/SendEmail';

function EmailModal() {
	const params = useParams()
	const { id } = params
	const { userInfo } = useSelector((state) => state)
	const suburl = localStorage.getItem('suburl');
	const navigate = useNavigate();
	const [toEmails, setToEmails] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(null)
	const [emailFormData, setEmailFormData] = useState({
		purchase_order_id: null,
		purchase_order_number: "",
		from: "",
		to: [],
		subject: "",
		body: "",
		isAttachment: true
	})

	// sending email
	async function handleSend(formData) {
		try {
			await sendEmail(formData)
			toast.success("Email Sent Successfully.")
			navigate(`/user/${suburl}/purchases-order`)
		} catch (error) {
			throw error
		}
	};

	function handleClose() {
		navigate(`/user/${suburl}/purchases-order`)
	}


	async function handleFetchPurchaseOrderDetails() {
		try {
			setIsLoading(true)
			const { purchaseOrders } = await fetchPurchaseOrders({ filterBy: "purchase_order_id", filterValue: id })
			const purchaseOrderDetails = purchaseOrders[0]
			setPurchaseOrderDetails(purchaseOrderDetails)
		} catch (error) {
			toast.error("Something went wrong!")
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (id) {
			handleFetchPurchaseOrderDetails()
		}
	}, [id])

	useEffect(() => {
		if (purchaseOrderDetails && userInfo) {

			const { purchase_order_id, purchase_order_number } = purchaseOrderDetails
			const vendorContactEmailList = purchaseOrderDetails?.vendor?.contact_person?.length > 0 ? purchaseOrderDetails?.vendor?.contact_person?.map((contact) => {
				return contact?.email
			}) : []

			setEmailFormData((prev) => ({
				...prev,
				to: vendorContactEmailList,
				from: `${userInfo?.userInfo?.data?.userData?.first_name} ${userInfo?.userInfo?.data?.userData?.last_name}`,
				purchase_order_id,
				purchase_order_number,
				subject: `Purchase Order from ${userInfo?.userInfo?.data?.userData?.first_name + " " + userInfo?.userInfo?.data?.userData?.last_name} (Purchase Order #: ${purchase_order_number})`
			}))
			setToEmails(vendorContactEmailList)
		}
	}, [purchaseOrderDetails, userInfo])

	if (isLoading) {
		return <PageLoader />
	}



	return (
		<SendEmail
			pdfName="Purchase Order"
			pdfId={`${purchaseOrderDetails?.purchase_order_number}`}
			toEmails={toEmails}
			formData={emailFormData}
			handleSend={handleSend}
			handleClose={handleClose}
		/>
	);
}

export default EmailModal;
