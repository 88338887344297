import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import '../Vendors.scss';
import ReadOnlyTable from '../../../component/InputTable/ReadOnlyTable';
import { deleteVendorContactPerson } from '../../../store/action/vendor';
import Modal from '../../../component/modal/Modal';
import Button from '../../../component/ui/Button';
import ConfirmationModal from '../../../component/modal/ConfirmationModal';

const ContactPersonTable = ({ tableData, vendorId, setTableData, className, handleModalOpen }) => {
	const dispatch = useDispatch();
	const [isDeleteContactModalOpen, setIsDeleteContactModalOpen] = useState(false)
	const [deleteContactPerson, setDeleteContactPerson] = useState(null)

	function deleteRow() {
		try {
			const contactPersonId = deleteContactPerson?.contact_person_id;
			const index = deleteContactPerson?.index;
			if (vendorId && contactPersonId) {
				dispatch(deleteVendorContactPerson(vendorId, contactPersonId))
					.then((res) => {
						if (res?.success) {
							toast.success(res?.message);
						} else {
							toast.error('Error in deleting contact person');
						}
					})
					.catch((err) => {
						toast.error(err?.message || 'Error in deleting contact person');
					});
			}

			const updatedTableData = tableData.filter((_, i) => i !== index);
			setTableData(updatedTableData);
			setIsDeleteContactModalOpen(false)
		} catch (error) {
			toast.error("Something went wrong! Please try after sometime.")
		}
	}

	const columns = [
		{ name: 'salutation', label: 'Salutation', type: 'text' },
		{ name: 'name', label: 'Name', type: 'text' },
		{ name: 'mobile', label: 'Mobile', type: 'text' },
		{ name: 'work_phone', label: 'Work Phone', type: 'text' },
		{ name: 'email', label: 'Email', type: 'text' },
	];

	const initialRows = [
		{
			salutation: '',
			name: '',
			mobile: '',
			work_phone: '',
			email: '',
		},
	];

	function handlDeleteContact(index) {
		const contactPersonDetails = tableData?.[index];
		setDeleteContactPerson({ index, ...contactPersonDetails })
		setIsDeleteContactModalOpen(true)
	}

	return (
		<div>
			<div
				className={`add-vendor-contactTable add-product-inputTable`}
				style={{ marginTop: '0' }}
			>
				<ReadOnlyTable
					className={`th-width  ${className}`}
					initialRows={initialRows}
					columns={columns}
					value={tableData}
					disabled={true}
					deleteRow={handlDeleteContact}
					handleModalOpen={handleModalOpen}
				/>
			</div>
			{
				isDeleteContactModalOpen &&

				<ConfirmationModal
					content={
						<p
							style={{
								textAlign: 'center',
								backgroundColor: '#E6F5FF',
								padding: '10px',
								borderRadius: '5px',
								fontWeight: '500',
								color: 'black',
							}}
						>
							Are you sure you want to delete <span style={{
								fontWeight: '600',
								color: 'black',
							}}>{deleteContactPerson?.first_name + " " + deleteContactPerson?.last_name}</span> contact?
						</p>


					}
					isOpen={isDeleteContactModalOpen}
					title="Delete Vendor"
					onCancel={() => {
						setIsDeleteContactModalOpen(false);
					}}
					onOk={deleteRow}
				/>

				// <Modal className="confirmatinBoxModal" title="Delete Contact" isOpen={isDeleteContactModalOpen}
				// 		onClose={() => {
				// 			setIsDeleteContactModalOpen(false);
				// 		}}
				// 		cancelButton={() => {
				// 			setIsDeleteContactModalOpen(false);
				// 		}}>

				// <p
				// 	style={{
				// 		textAlign: 'center',
				// 		backgroundColor: '#E6F5FF',
				// 		padding: '10px',
				// 		borderRadius: '5px',

				// 	}}
				// >
				// 	Are you sure you want to delete <span style={{
				// 		fontWeight: '600',
				// 		color: 'black',
				// 	}}>{deleteContactPerson?.first_name + " " + deleteContactPerson?.last_name}</span> contact?
				// </p>
				// 		<div
				// 			style={{
				// 				display: 'flex',
				// 				alignItems: 'center',
				// 				gap: 10,
				// 				justifyContent: 'end',
				// 			}}
				// 		>
				// 			<Button
				// 				type="button"
				// 				className="mt-0 btn-gray add-pro"
				// 				button="Cancel"
				// 				onClick={() => {
				// 					setIsDeleteContactModalOpen(false);
				// 				}}
				// 			/>

				// 			<Button
				// 				type="button"
				// 				button="Confirm"
				// 				className="mt-0 px-4 btn-danger relative"
				// 				onClick={deleteRow}
				// 			/>
				// 		</div>
				// 	</Modal>
			}
		</div>
	);
};

export default ContactPersonTable;
