import React, { useEffect, useRef, useState } from 'react';
import renderContent from './CustomersDetails/renderContent';
import Header from '../../components/Header';
import { useNavigate } from "react-router-dom";
import NavigationTabsCustomersDetails from './CustomersDetails/NavigationTabsCustomersDetails';

const CustomersDetails = () => {
  const suburl = localStorage.getItem("suburl");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Information');

  const navigateRouter = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Header
        headerTitle="Customers Details"
        closeButton={{
          showCloseButton: true,
          handler: () => navigate(`/user/${suburl}/customers`),
        }}
        buttons={[
          {
            id: 1,
            className: 'btn-gray mt-0 px-4 ',
            button: 'Edit',
            // handler: () => navigate(/user/${suburl}/purchases-order-edit/${id}),
          },
          {
            id: 2,
            className: 'btn-gray mt-0 px-4 ',
            button: 'Clone',
          },
          {
            id: 3,
            className: 'mt-0 POStausButton',
            button: (
              <div
                className="dropdown"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  height: '12px',
                  width: '133px',
                  paddingLeft: '0%'
                }}
              >
                <button
                  className="btn btn-light dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    height: '18px',
                    width: '100px',
                    fontSize: '14px',
                    border: 'none',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    cursor: 'default',
                  }}
                >
                  New Transaction
                </button>
                <ul
                  className="dropdown-menu font-14 DropdownMenuSize  DropdownPadding"
                  style={{ minWidth: '150px', }}
                >

                  <li>

                    <a

                      className="dropdown-item"

                      onClick={() => {

                        // SetUpdatePOStatus('is_approved');

                        // setIsUpdatePOStatusModelOpen(true);

                      }}

                      style={{ cursor: 'pointer', fontSize: '14px' }}

                    >

                      Invoice

                    </a>

                  </li>

                  <li>

                    <a

                      className="dropdown-item"

                      onClick={() => {

                        // SetUpdatePOStatus('is_issued');

                        // setIsUpdatePOStatusModelOpen(true);

                      }}

                      style={{ cursor: 'pointer', fontSize: '14px' }}

                    >

                      Receive Payment

                    </a>

                  </li>

                  <li>

                    <a

                      className="dropdown-item"

                      onClick={() => {

                        // SetUpdatePOStatus('is_in_transit');

                        // setIsUpdatePOStatusModelOpen(true);

                      }}

                      style={{ cursor: 'pointer', fontSize: '14px' }}

                    >

                      Sales Order

                    </a>

                  </li>

                  <li>

                    <a

                      className="dropdown-item"

                      onClick={() => {

                        // SetUpdatePOStatus('is_in_transit');

                        // setIsUpdatePOStatusModelOpen(true);

                      }}

                      style={{ cursor: 'pointer', fontSize: '14px' }}

                    >

                      Package

                    </a>

                  </li>

                  <li>

                    <a

                      className="dropdown-item"

                      onClick={() => {

                        // SetUpdatePOStatus('is_in_transit');

                        // setIsUpdatePOStatusModelOpen(true);

                      }}

                      style={{ cursor: 'pointer', fontSize: '14px' }}

                    >

                      Delivery Challan

                    </a>

                  </li>

                  <li>

                    <a

                      className="dropdown-item"

                      onClick={() => {

                        // SetUpdatePOStatus('is_in_transit');

                        // setIsUpdatePOStatusModelOpen(true);

                      }}

                      style={{ cursor: 'pointer', fontSize: '14px' }}

                    >

                      Credite Note

                    </a>

                  </li>

                </ul>

              </div>

            ),

          },



          {

            id: 4,

            className: 'mt-0 btn-gray add-pro',

            button: 'Mark as Inactive',

            handler: async () => {

              // await setIsShowPdfView(true);

              // printPurchaseOrderPdf();

            },

          },

          {

            id: 5,

            className: 'btn-gray add-pro',

            button: 'Delete',

            // handler: () => setIsConvertToBillOpen(true),

          },





        ].filter(Boolean)}

        dropDownButtons={[]}

      />

      <div style={{ backgroundColor: '#EDEDF7', marginTop: '-10px', paddingLeft: '10px', }}>

        <NavigationTabsCustomersDetails

          activeTab={activeTab}

          setActiveTab={setActiveTab}

        />

      </div>



      <div className="tab-content ">

        {renderContent(activeTab)} {/* Pass activeTabMain here */}

      </div>





    </div>

  )

}



export default CustomersDetails