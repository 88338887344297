module.exports = {
	vendor: [
		{
			key: 'vendor_name',
			value: 'Vendor Name',
		},
		{ key: 'vendor_display_name', value: 'Vendor Display Name' },
		{ key: 'email', value: 'Email' },
		{ key: 'phone_mobile', value: 'Phone Number' },
		{ key: 'vendor_id', value: 'Vendor Id' },
		{ key: 'status', value: 'Status' },
	],
	purchase_order: [
		{
			key: 'vendor_name',
			value: 'Vendor Name',
		},
		{ key: 'purchase_order_number', value: 'Puchase Order Number' },
		{ key: 'purchase_order_id', value: 'Purchase Order Id' },
		// { key: 'is_received', value: 'Is Received' },
		// { key: 'is_billed', value: 'Is Billed' },
		// { key: 'status', value: 'Status' },
	],
};
