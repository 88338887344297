import React, { useState } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputSelect from '../../component/inputSelect/InputSelect';
import BillTable from './BillTable';

const CreatePurchaseBill = () => {
	const today = new Date().toISOString().split('T')[0];
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [date, setDate] = useState(today);
	const [isErrortable, setIsTableError] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [disabled, setDisabled] = useState(id ? true : false);
	const [TableError, setTableError] = useState(false);

	const navigateRouter = (path) => {
		navigate(path);
	};
	return (
		<div>
			<div className="">
				<div className="">
					<div className="product-pg-header add-product">
						<p>Create New Purchase Bill</p>
						<div className="add-product-button">
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save"
								// onClick={() => setDisabled(false)}
							/>
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save as Draft"
								// onClick={() => setDisabled(false)}
							/>
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => navigateRouter(`/user/${suburl}/purchases-bills`)}
							/>
						</div>
					</div>
				</div>
				{/*  Vendor Form */}
				<div
					className="max-w-1297"
					style={{ padding: '1rem 2rem 0' }}
				>
					<div className="row">
						<div className="col">
							<InputSelect
								label="Vendor Name"
								placeholder="Vendor Name"
								required={true}
								value={[]}
								// onChange={(value) => handleBasicInputChange('vendor_name', value)}
								// disabled={disabled}
								// error={error?.vendor_name}
								autoFocus={true}
							/>
						</div>
						<div className="col">
							<InputSelect
								label="Purchase Order No"
								placeholder="Purchase Order No"
								required={true}
								value={[]}
								// onChange={(value) => handleBasicInputChange('vendor_name', value)}
								// disabled={disabled}
								// error={error?.vendor_name}
							/>
						</div>
						<div className="col">
							<InputField
								label="Bill No #"
								placeholder="Bill No #"
								type="text"
								required={true}
								// onChange={(value) => handleBasicInputChange('vendor_display_name', value)}
								// value={vendorBasicData.vendor_display_name}
								// disabled={disabled}
								// required={true}
								// error={error?.vendor_display_name}
								// onFocus={(e) => {
								// 	isVendorDisplayNameTouched.current = true;
								// }}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<InputField
								label="Date"
								placeholder="Enter Date"
								type="date"
								value={date}
								onChange={(val) => setDate(val)}
								// autoFocus={disabled ? false : true}
								// disabled={disabled}
								required={true}
								// error={error?.date}
							/>
						</div>
						<div className="col">
							<InputField
								label="Due Date"
								placeholder="Enter Date"
								type="date"
								value={date}
								onChange={(val) => setDate(val)}
								// autoFocus={disabled ? false : true}
								// disabled={disabled}
								required={true}
								// error={error?.date}
							/>
						</div>
						<div className="col">
							<InputField
								label="Bill Amount"
								placeholder="Delivery Address"
								type="text"
								// required={true}
								// value={vendorBasicData.email}
								// onChange={(value) => handleBasicInputChange('email', value)}
								// disabled={disabled}
								// error={error?.email}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<InputSelect
								label="Payment Method"
								placeholder="Vendor Name"
								required={true}
								value={[]}
								// onChange={(value) => handleBasicInputChange('vendor_name', value)}
								// disabled={disabled}
								// error={error?.vendor_name}
							/>
						</div>
						<div className="col">
							<InputField
								label="Discount"
								placeholder="Discount"
								type="text"
								// required={true}
								// value={vendorBasicData.email}
								// onChange={(value) => handleBasicInputChange('email', value)}
								// disabled={disabled}
								// error={error?.email}
							/>
						</div>

						<div className="col">
							<InputField
								label="Freight"
								placeholder="Freight"
								type="text"
								// required={true}
								// value={vendorBasicData.email}
								// onChange={(value) => handleBasicInputChange('email', value)}
								// disabled={disabled}
								// error={error?.email}
							/>
						</div>

						<div className="col">
							<InputField
								label="Other Adjustment"
								placeholder="Other Adjustment"
								type="text"
								// required={true}
								// value={vendorBasicData.email}
								// onChange={(value) => handleBasicInputChange('email', value)}
								// disabled={disabled}
								// error={error?.email}
							/>
						</div>
					</div>
				</div>
				{/* Bill Table */}
				<BillTable
					tableData={tableData}
					setTableData={setTableData}
					disabled={disabled}
					paramId={id}
					tableError={TableError}
					setIsTableErrorShow={setIsTableError}
				/>
			</div>
		</div>
	);
};

export default CreatePurchaseBill;
