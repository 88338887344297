import axios from 'axios';
import { APP_API_URL } from '../../utils/xhr-instance';

export async function create_purchase_bill(body) {
	const token = localStorage.getItem('authToken');
	const { data } = await axios.post(`${APP_API_URL}/purchase-receive/create-purchase-bill`, body, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	return data;
}

export const fetchPurchaseBill = async (params) => {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axios.get(`${APP_API_URL}/purchase-bill/get-purchase-bill`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
};
