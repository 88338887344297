import axios from 'axios';
import { APP_API_URL } from '../../utils/xhr-instance';

export async function getPackagingList(params) {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axios.get(`${APP_API_URL}/product/packaging-list`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
}

export async function getProductList(params) {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axios.get(`${APP_API_URL}/product/getAllProducts`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
}
