export const PurchaseOrderColoum = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 100,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'PURCHASE ORDER#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 100,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'VENDOR NAME',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'RECEIVED',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const status = params.data?.RECEIVED || '';

			if (status) {
				if (status === '0') {
					return (
						<svg
							width="17"
							height="17"
							viewBox="0 0 17 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginTop: '11px' }}
						>
							<mask
								id="mask0_6369_3920"
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="17"
								height="17"
							>
								<circle
									cx="8.5"
									cy="8.5"
									r="8.5"
									fill="#D9D9D9"
								/>
							</mask>
							<g mask="url(#mask0_6369_3920)">
								<rect
									x="-8.86963"
									width="17"
									height="17"
									fill="#3B58FF"
								/>
								<rect
									x="8.13037"
									width="17"
									height="17"
									fill="#3B58FF"
								/>
							</g>
						</svg>
					);
				} else if (status === '1') {
					return (
						<svg
							width="17"
							height="17"
							viewBox="0 0 17 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginTop: '11px' }}
						>
							<mask
								id="mask0_6369_3920"
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="17"
								height="17"
							>
								<circle
									cx="8.5"
									cy="8.5"
									r="8.5"
									fill="#D9D9D9"
								/>
							</mask>
							<g mask="url(#mask0_6369_3920)">
								<rect
									x="-8.86963"
									width="17"
									height="17"
									fill="#D9D9D9"
								/>
								<rect
									x="8.13037"
									width="17"
									height="17"
									fill="#D9D9D9"
								/>
							</g>
						</svg>
					);
				} else if (status === '2') {
					return (
						<svg
							width="17"
							height="17"
							viewBox="0 0 17 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginTop: '11px' }}
						>
							<mask
								id="mask0_6369_3920"
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="17"
								height="17"
							>
								<circle
									cx="8.5"
									cy="8.5"
									r="8.5"
									fill="#D9D9D9"
								/>
							</mask>
							<g mask="url(#mask0_6369_3920)">
								<rect
									x="-8.86963"
									width="17"
									height="17"
									fill="#D9D9D9"
								/>
								<rect
									x="8.13037"
									width="17"
									height="17"
									fill="#3B58FF"
								/>
							</g>
						</svg>
					);
				}
			} else {
				return <p>{status}</p>;
			}
		},
	},
	{
		field: 'BILLED',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 50,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const status = params.data?.BILLED || '';

			if (status) {
				if (status === '0') {
					return (
						<svg
							width="17"
							height="17"
							viewBox="0 0 17 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginTop: '11px' }}
						>
							<mask
								id="mask0_6369_3920"
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="17"
								height="17"
							>
								<circle
									cx="8.5"
									cy="8.5"
									r="8.5"
									fill="#D9D9D9"
								/>
							</mask>
							<g mask="url(#mask0_6369_3920)">
								<rect
									x="-8.86963"
									width="17"
									height="17"
									fill="#3B58FF"
								/>
								<rect
									x="8.13037"
									width="17"
									height="17"
									fill="#3B58FF"
								/>
							</g>
						</svg>
					);
				} else if (status === '1') {
					return (
						<svg
							width="17"
							height="17"
							viewBox="0 0 17 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginTop: '11px' }}
						>
							<mask
								id="mask0_6369_3920"
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="17"
								height="17"
							>
								<circle
									cx="8.5"
									cy="8.5"
									r="8.5"
									fill="#D9D9D9"
								/>
							</mask>
							<g mask="url(#mask0_6369_3920)">
								<rect
									x="-8.86963"
									width="17"
									height="17"
									fill="#D9D9D9"
								/>
								<rect
									x="8.13037"
									width="17"
									height="17"
									fill="#D9D9D9"
								/>
							</g>
						</svg>
					);
				} else if (status === '2') {
					return (
						<svg
							width="17"
							height="17"
							viewBox="0 0 17 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginTop: '11px' }}
						>
							<mask
								id="mask0_6369_3920"
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="17"
								height="17"
							>
								<circle
									cx="8.5"
									cy="8.5"
									r="8.5"
									fill="#D9D9D9"
								/>
							</mask>
							<g mask="url(#mask0_6369_3920)">
								<rect
									x="-8.86963"
									width="17"
									height="17"
									fill="#D9D9D9"
								/>
								<rect
									x="8.13037"
									width="17"
									height="17"
									fill="#3B58FF"
								/>
							</g>
						</svg>
					);
				}
			} else {
				return <p>{status}</p>;
			}
		},
	},

	{
		field: 'EXPECTED DELIVERY',
		headerClass: 'HeaderBg right-align',
		suppressSizeToFit: true,
		minWidth: 100,
		flex: 1,
		cellClass: 'left-align',
	},
];

export const convertToBillColumn = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'PURCHASE ORDER#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'ORDER DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
];

export const Receivecolumn = [
	{
		field: 'PO#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellStyle: { color: '#555B67' },
		headerStyle: { color: '#555B67' },
	},

	{
		field: 'VENDOR NAME',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},

	{
		field: 'RECEIVED',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 50,
		flex: 1,
		cellStyle: { color: '#3B58FF' },
		headerStyle: { color: '#555B67' },
	},

	{
		field: 'BILLED',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'EXPECTED DELIVERY DATE',
		suppressSizeToFit: true,
		minWidth: 70,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
];
export const Ordercolumn = [
	{
		field: 'ORDER#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 50,
		flex: 1,
		cellStyle: { color: '#3B58FF' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellStyle: { color: '#555B67' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
	},
	{
		field: 'DUE DATE',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'AMOUNT',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'BALANCE DUE',
		suppressSizeToFit: true,
		minWidth: 70,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
];
