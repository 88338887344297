import React, { useState } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputSelect from '../../component/inputSelect/InputSelect';
import add from '../../assets/image/add.svg';
import './CreditNote.scss';
import CreditNoteTable from './CreditNoteTable';
import VendorDetailsDrawer from '../purchaseOrder/components/VendorDetailsDrawer';



const CreateCreditNote = () => {
  const today = new Date().toISOString().split('T')[0];
  const suburl = localStorage.getItem('suburl');
  const [isVendorDetailsDrawerOpen, setIsVendorDetailsDrawerOpen] = useState(false);

  const param = useParams();
  const id = param?.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = useState(today);
  const [isErrortable, setIsTableError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [disabled, setDisabled] = useState(id ? true : false);
  const [TableError, setTableError] = useState(false);
  const [vendorDetails, setVendorDetails] = useState(null)


  const navigateRouter = (path) => {
    navigate(path);
  };
  return (
    <div>
      <div className="">
        <div className="">
          <div className="product-pg-header add-product">
            <p>Create New Credit Note</p>
            <div className="add-product-button">
              <Button
                type="button"
                className="mt-0 px-4 ButtonBGColor"
                button="Save as Draft"
              // onClick={() => setDisabled(false)}
              />
              <Button
                type="button"
                className="mt-0 px-4 ButtonBGColor"
                button="Save as Open"
              // onClick={() => setDisabled(false)}
              />
              <Button
                type="button"
                className="mt-0 btn-danger add-pro"
                button=""
                close
                onClick={() => navigateRouter(`/user/${suburl}/credit-note`)}
              />
            </div>
          </div>
        </div>
        {/*  Vendor Form */}
        <div
          className="max-w-1297"
          style={{ padding: '1rem 2rem 0' }}
        >
          <div className="row">
            <div className="col">
              <InputSelect
                label="Vendor Name"
                placeholder="Vendor Name"
                required={true}
                value={[]}
                // onChange={(value) => handleBasicInputChange('vendor_name', value)}
                // disabled={disabled}
                // error={error?.vendor_name}
                autoFocus={true}
              />
              <a
                href='#'
                style={{ fontSize: '12px', paddingBottom: '10px' }}
                onClick={() => setIsVendorDetailsDrawerOpen(true)}>
                View Vendor Details
              </a>

            </div>

            <div className="col">
              <InputField
                label="Credit Note #"
                placeholder="INV-008"
                type="text"
                required={true}
              // onChange={(value) => handleBasicInputChange('vendor_display_name', value)}
              // value={vendorBasicData.vendor_display_name}
              // disabled={disabled}
              // required={true}
              // error={error?.vendor_display_name}
              // onFocus={(e) => {
              // 	isVendorDisplayNameTouched.current = true;
              // }}
              />
            </div>
          </div>
          <div className="row">

            <div className="col">
              <InputField
                label="Credit Note Date*"
                placeholder="24/02/2024"
                type="date"
                value={date}
                onChange={(val) => setDate(val)}
                // autoFocus={disabled ? false : true}
                // disabled={disabled}
                required={true}
              // error={error?.date}
              />
            </div>
            <div className="col">
              <InputField
                label="References # "
                placeholder="Ref-00005"
                type="text"
              // required={true}
              // value={vendorBasicData.email}
              // onChange={(value) => handleBasicInputChange('email', value)}
              // disabled={disabled}
              // error={error?.email}
              />
            </div>
          </div>
        </div>
        <br />

        {/* Bill Table */}
        <CreditNoteTable
          tableData={tableData}
          setTableData={setTableData}
          disabled={disabled}
          paramId={id}
          tableError={TableError}
          setIsTableErrorShow={setIsTableError}
        />
      </div>

      <div style={{
        border: '1px solid #C3CBDC',
        height: '36px',
        backgroundColor: '#F6F6F9',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        width: '96%',
        margin: 'auto',


      }}>
        <div>
          <span style={{
            color: 'var(--sub, #555B67)',
            fontWeight: '400',
            fontSize: '14px',
          }}>Total Items : </span><span style={{
            color: 'var(--sub, #555B67)',
            fontWeight: '700',
            fontSize: '14px',

          }}>5</span>
        </div>
      </div>
      <br />
      <br />






      <div style={{
        height: '112px',
        border: '1px solid #C3CBDC',
        backgroundColor: '#DADDEC',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'

      }} >
        <div className='TotalBillFormDiv'>
          <InputField
            label="Discont [%]"
            style={{
              width: '106px'
            }}
            placeholder="10%"


          />
          <InputField
            label="Adj"
            style={{
              width: '106px'
            }}
            placeholder="0.00"


          />
          <InputField
            label="Round Off"
            style={{
              width: '106px'
            }}
            placeholder="0.00"


          />


        </div>

        <div className='TotleBillDiv'>
          <div>
            <p className='TotalBillQ'>Sub Total</p>
            <p className='TotalBillQ'>Taxes</p>
            <p className='TotalBillF'>Total Bill</p>


          </div>
          <div>
            <p className='TotalBillA'>₹ 200.00/-</p>
            <p className='TotalBillA'>₹ 235.00/-</p>
            <p className='TotalBillF'>₹ 6257.00/-</p>



          </div>

        </div>

      </div>

      {
        <VendorDetailsDrawer isOpen={isVendorDetailsDrawerOpen} onClose={() => setIsVendorDetailsDrawerOpen(false)} vendorDetails={vendorDetails} />
      }


    </div>
  );
};

export default CreateCreditNote;
