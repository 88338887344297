import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortbydot from '../../assets/image/sort_by_group.svg';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Pagination from '../../component/pagination';
import { toast } from 'react-toastify';
import { ExportTable } from '../../store/action/export/Export';
import {
	exportPurchaseOrderData,
	fetchPurchaseOrders,
	update_bulk_purchase_order_status,
} from '../../services/purchaseOrder';
import { getDate } from '../../utils/dateUtils';
import advanceFilterIcon from '../../assets/image/advanceFilter.svg';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import FullCircle from '../../assets/image/full_circle.svg';
import HalfCircle from '../../assets/image/half_circle.svg';
import EmptyCircle from '../../assets/image/empty_circle.svg';
import pdfMake from 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';
import AdvanceFilter from '../../component/Filter/AdvanceFilter';
import axios from 'axios';
import { APP_API_URL } from '../../utils/xhr-instance';
import advanceFilterOptions from '../../component/Filter/advanceFilterOptions';
import clearAllFilter from '../../assets/image/clearAllFilter.svg';
import addFilter from '../../assets/image/AddFilter.svg';
import AdvanceFilterSelectInput from '../../component/Filter/AdvanceFilterSelectInput';
import DeletePurchaseOrderModel from './components/DeletePurchaseOrderModel';

const PurchaseOrder = () => {
	const navigate = useNavigate();
	const suburl = localStorage.getItem('suburl');
	const [totalPages, setTotalPages] = useState(0);
	const [loader, setLoader] = useState(false);
	const [bulkAction, setBulkAction] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [headerDropDown, setHeaderDropDown] = useState('Active Purchase Orders');
	const [sortDirection, setSortDirection] = useState({ purchase_order_id: 'asc' });
	const [stateCondition, setStateCondition] = useState('');
	const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
	const [isDeleteBulkConfirmationModalOpen, setIsDeleteBulkConfirmationModalOpen] = useState(false);
	const [selectedPRListForDelete, setSelectedPRListForDelete] = useState([]);

	const [purchaseOrderParams, setPurchaseOrderParams] = useState({
		page: 1,
		pageSize: 20,
		orderBy: 'purchase_order_id',
		sortBy: 'asc',
		filterBy: null,
		filterValue: null,
	});
	const [isAppliedAdvanceFilter, setIsAppliedAdvanceFilter] = useState(false);
	const [advanceFilterParams, setAdvanceFilterParams] = useState({
		page: 1,
		totalPages: 0,
		pageSize: 20,
		filters: {},
	});
	const [advanceFilterSelectInputList, setadvanceFilterSelectInputList] = useState([]);

	const [deselectRows, setDeselectRows] = useState(null);
	const handleDeselectAll = (deselectAllRowsFn) => {
		setDeselectRows(() => deselectAllRowsFn);
	};

	const navigateRouter = (path) => {
		navigate(path);
	};

	const onRowClick = (id) => {
		if (id) {
			navigate(`/user/${suburl}/purchases-order-details/${id}`);
		}
	};

	const FilterDatalist = (param, value, dropDownData) => {
		setStateCondition(value);
		setHeaderDropDown(dropDownData);
		setPurchaseOrderParams((prev) => ({
			...prev,
			...param,
		}));
	};

	const sortIconMap = {
		asc: <IoIosArrowRoundUp />,
		desc: <IoIosArrowRoundDown />,
	};

	const sortDataList = (param) => {
		const newDirection = sortDirection[param.orderBy] === 'desc' ? 'asc' : 'desc';
		setSortDirection({ [param.orderBy]: newDirection });
		setPurchaseOrderParams((prev) => ({
			...prev,
			orderBy: param.orderBy,
			sortBy: newDirection,
			filterValue: null,
		}));
	};

	const handlePageChange = (page) => {
		setPurchaseOrderParams((prev) => ({
			...prev,
			page,
		}));
	};

	const exportData = (param) => {
		ExportTable(param);
	};

	const PurchaseOrderColoum = [
		{
			field: '',
			headerClass: 'HeaderBg',
			checkboxSelection: true,
			headerCheckboxSelection: true,
			minWidth: 50,
			maxWidth: 50,
			lockPosition: true,
		},
		{
			field: 'DATE',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 100,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'PO#',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 140,
			flex: 1,
			cellClass: 'left-align',
			cellRenderer: (params) => {
				const PO = JSON.parse(params.data['PO#']);
				return (
					<p>
						{PO?.po_number}
						{PO?.is_draft === 1 && (
							<span
								style={{
									backgroundColor: '#718096',
									color: 'white',
									borderRadius: '20px',
									padding: '2px 10px',
									paddingBottom: '3px',
									marginLeft: '10px',
									fontSize: '11px',
								}}
							>
								Draft
							</span>
						)}
					</p>
				);
			},
		},
		{
			field: 'VENDOR NAME',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 400,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'Status',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 175,
			flex: 1,
			cellClass: 'left-align',
			cellRenderer: (params) => {
				const status = params.data?.Status || ''; // Get the status value
				let statusCode = '';

				// Set the image based on status value
				switch (status) {
					case 'Received':
						statusCode = '#6AA247';
						break;
					case 'Pending':
						statusCode = '#FFC008';
						break;
					case 'Pending Approval':
						statusCode = '#FFC008';
						break;
					case 'Approved':
						statusCode = '#6AA247';
						break;
					case 'Issued':
						statusCode = '#3B58FF';
						break;
					case 'Closed':
						statusCode = '#989C95';
						break;
					case 'Deleted':
						statusCode = '#D30000';
						break;
					case 'Partially Received':
						statusCode = '#FFA500';
						break;
					case 'In-Transit':
						statusCode = '#11caf0';
						break;
					default:
						statusCode = '#DC3545';
						break;
				}

				// Return the image inside a flex container for centering
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '24px',
							fontSize: '12px',
							borderRadius: '5px',
							fontWeight: 'bold',
							margin: '8px 0px',
							color: '#ffffff',
							backgroundColor: statusCode,
						}}
					>
						{status}
					</div>
				);
			},
		},
		{
			field: 'Received',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 40,
			flex: 1,
			cellClass: 'left-align',
			cellRenderer: (params) => {
				const received = JSON.parse(params.data?.Received) || '';
				console.log({ received });
				const { is_received, is_partially_received, status } = received;
				// Logic for rendering circles
				if (
					is_received === 1 &&
					status !== 'Pending Approval' &&
					status !== 'In-Transit' &&
					status !== 'Cancelled' &&
					status !== 'Issued'
				) {
					// Full Circle
					return (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
							}}
						>
							<img
								alt="is_receive?"
								src={FullCircle}
							/>
						</div>
					);
				}

				if (
					is_partially_received === 1 &&
					status !== 'Pending Approval' &&
					status !== 'In-Transit' &&
					status !== 'Cancelled' &&
					status !== 'Issued'
				) {
					// Half Circle
					return (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
							}}
						>
							<img
								alt="is_receive?"
								src={HalfCircle}
							/>
						</div>
					);
				}
				// if (
				// 	((status === 'Pending Approval' ||
				// 		status === 'In-Transit' ||
				// 		status === 'Cancelled' ||
				// 		status === 'Issued') &&
				// 		(is_partially_received === 1 ||
				// 			is_received === 1 ||
				// 			(is_partially_received === 1 && is_received === 1))) ||
				// 	status === 'Pending Approval' ||
				// 	status === 'In-Transit' ||
				// 	status === 'Cancelled' ||
				// 	status === 'Issued'
				// ) {
				// Empty Circle
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<img
							alt="is_receive?"
							src={EmptyCircle}
						/>
					</div>
				);
				// }
			},
		},
		{
			field: 'Billed',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 40,
			flex: 1,
			cellClass: 'left-align',
			cellRenderer: (params) => {
				const billed = JSON.parse(params.data?.Billed) || '';
				const { is_billed, is_partially_billed } = billed;
				// Logic for rendering circles
				if (is_billed === 1) {
					// Full Circle
					return (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
							}}
						>
							<img
								alt="is_receive?"
								src={FullCircle}
							/>
						</div>
					);
				}

				if (is_partially_billed === 1) {
					// Half Circle
					return (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
							}}
						>
							<img
								alt="is_receive?"
								src={HalfCircle}
							/>
						</div>
					);
				}

				// Empty Circle
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<img
							alt="is_receive?"
							src={EmptyCircle}
						/>
					</div>
				);
			},
		},
		{
			field: 'Expected By',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 120,
			flex: 1,
			cellClass: 'left-align',
		},
	];

	async function handleUpdateBulkPurchaseOrder(status) {
		try {
			// 'is_issued', 'is_cancelled', is_deleted
			if (bulkAction.length === 0) {
				toast.error('Please select purchase orders!');
				return;
			}

			const body = {
				status,
				purchase_order_ids: bulkAction,
			};
			await update_bulk_purchase_order_status(body);
			toast.success('Status updated successfully!');
			setBulkAction(false);
			setBulkAction([]);
			deselectRows && deselectRows();
			if (isDeleteBulkConfirmationModalOpen) {
				setIsDeleteBulkConfirmationModalOpen(false);
			}
			handleFetchPurcahseOrders();
		} catch (error) {
			console.log(error.message);
			toast.error(error.message || 'Something went wrong while updating the Status!');
		}
	}

	function handleOpenBulkDeletePurchaseOrderModal() {
		const selectedVendorsList = tableData?.reduce((acc, item) => {
			if (bulkAction.includes(item.id)) {
				console.log(item);
				acc.push(item['PO#']);
				return acc;
			}
			return acc;
		}, []);
		setSelectedPRListForDelete(selectedVendorsList);
		setIsDeleteBulkConfirmationModalOpen(true);
	}

	async function handleDownloadTableData() {
		try {
			if (bulkAction.length === 0) {
				toast.error('Please select purchase orders!');
				return;
			}
			const params = {
				purchase_order_ids: bulkAction.join(','),
			};
			await exportPurchaseOrderData(params);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	async function generatePdfToPrintAndDownload() {
		const filterValue = bulkAction.join(',');
		const { purchaseOrders } = await fetchPurchaseOrders({
			filterBy: 'purchase_order_id',
			filterValue,
		});
		const docDefinitions = [];
		purchaseOrders.forEach((po) => {
			const {
				is_received,
				is_billed,
				vendor,
				purchase_order_number,
				date,
				organisation,
				delivery_address,
				purchase_order_products,
			} = po;
			docDefinitions.push({
				content: [
					{
						columns: [
							{
								text: `Received Status: ${is_received ? 'Received' : 'Not Received'}`,
								style: 'status',
								color: is_received ? '#6AA247' : 'red',
							},
							{ text: '|' },
							{
								text: `Bill Status: ${is_billed ? 'Billed' : 'Pending'}`,
								style: 'status',
								color: is_billed ? '#6AA247' : 'red',
							},
						],
						margin: [0, 0, 0, 10],
					},
					{
						text: vendor?.vendor_name,
						style: 'vendorName',
						margin: [0, 20, 0, 5],
					},
					{
						text: [
							vendor?.address,
							'\nRajasthan\nIndia\n',
							{ text: `${vendor?.phone_mobile} | ${vendor?.email}`, bold: true },
						],
						style: 'vendorAddress',
					},
					{
						columns: [
							{
								stack: [
									{ text: 'Purchase Order', style: 'subheader' },
									{ text: `#${purchase_order_number}`, style: 'orderNumber' },
									{ text: `Order Date: ${getDate(date)}`, style: 'orderDate' },
								],
								margin: [0, 20, 0, 10],
							},
						],
					},
					{
						columns: [
							{
								stack: [
									{ text: 'Vendor Address', style: 'label' },
									{ text: organisation?.organisation_name, style: 'detail' },
									{ text: `DL No.: ${vendor?.drug_license_number || 'N/A'}`, style: 'detail' },
									{ text: `GSTIN: ${vendor?.vendor_other_details?.gst || 'N/A'}`, style: 'detail' },
								],
							},
							{
								stack: [
									{ text: 'Deliver To', style: 'label' },
									{ text: delivery_address, style: 'detail' },
								],
							},
						],
						columnGap: 20,
					},
					{
						table: {
							widths: ['5%', '45%', '25%', '25%'],
							body: [
								[
									{ text: '#', bold: true },
									{ text: 'ITEMS AND DESCRIPTION', bold: true },
									{ text: 'PACKAGING', bold: true },
									{ text: 'ORDERED', bold: true },
								],
								...purchase_order_products.map((prod, index) => [
									{ text: index + 1 },
									{ text: prod.product.product_name, color: 'blue' },
									{ text: prod.product_packaging },
									{ text: prod.order_quantity },
								]),
							],
						},
						margin: [0, 20, 0, 10],
					},
					{
						text: 'Authorized Signature --------------------',
						margin: [0, 30, 0, 0],
						alignment: 'left',
					},
				],
				styles: {
					status: {
						fontSize: 10,
						margin: [0, 0, 0, 5],
					},
					vendorName: {
						fontSize: 16,
						bold: true,
					},
					vendorAddress: {
						fontSize: 12,
					},
					subheader: {
						fontSize: 14,
						bold: true,
					},
					orderNumber: {
						fontSize: 12,
						margin: [0, 5, 0, 5],
					},
					orderDate: {
						fontSize: 12,
					},
					label: {
						fontSize: 10,
						bold: true,
						margin: [0, 5, 0, 5],
					},
					detail: {
						fontSize: 10,
						margin: [0, 2, 0, 2],
					},
				},
			});
		});

		let mergedContent = [];
		docDefinitions.forEach((doc) => {
			mergedContent = mergedContent.concat(doc.content); // Concatenate all content
			// Optionally, you can add a page break between the documents
			mergedContent.push({ text: '', pageBreak: 'after' });
		});

		// Create the final merged document definition
		const mergedDocDefinition = {
			content: mergedContent,
			styles: {
				header: { fontSize: 16, bold: true },
				subheader: { fontSize: 12 },
			},
		};

		return mergedDocDefinition;
	}

	async function handlePrintAndDownloadPdf(isPrint) {
		setLoader(true);
		const mergedDocDefinition = await generatePdfToPrintAndDownload();
		if (isPrint) {
			pdfMake.createPdf(mergedDocDefinition).print();
		} else {
			pdfMake.createPdf(mergedDocDefinition).download();
		}
		setLoader(false);
	}

	function mappedTableData(data) {
		return data?.map((purchase) => {
			return {
				id: purchase.purchase_order_id,
				DATE: getDate(purchase?.date),
				'PO#': purchase?.purchase_order_number,
				'VENDOR NAME': purchase?.vendor?.vendor_name,
				Status: purchase?.status || 'N/A',
				Received: JSON.stringify({
					is_receive: purchase.is_receive,
					is_partially_received: purchase.is_partially_received,
				}),
				Billed: JSON.stringify({
					is_billed: purchase?.is_billed,
					is_partially_billed: purchase?.is_partially_billed,
				}),
				'Expected By': getDate(purchase?.expected_delivery_date),
			};
		});
	}

	async function handleApplyFilters() {
		const params = advanceFilterSelectInputList.reduce((acc, item) => {
			if (item?.value) {
				acc[item.key] = item?.value;
				return acc;
			}
			return acc;
		}, {});

		try {
			const token = localStorage.getItem('authToken');
			const { data } = await axios.post(
				`${APP_API_URL}/purchase-order/search-filter`,
				{ ...advanceFilterParams, filters: { ...params } },
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				},
			);
			const purchaseOrderFilterData = mappedTableData(data?.data?.purchaseOrders);
			setTableData(purchaseOrderFilterData);
			setAdvanceFilterParams((prev) => ({
				...prev,
				totalPages: data.data.totalPages,
				pageSize: data.data.pageSize,
				filters: {
					...prev.filters,
					...params,
				},
			}));
			setIsAdvanceFilterOpen(false);
			setIsAppliedAdvanceFilter(true);
		} catch (error) {
			toast.error('Something went wrong! Try again.');
		}
	}

	// selecting the filter from modal advance filter
	async function handleAdvanceFilterDropdown(option) {
		if (!option) return;

		const { key, value } = option;
		const isAlreadyPresent = advanceFilterSelectInputList.find((item) => item.key === key);

		if (isAlreadyPresent) {
			return;
		}

		setadvanceFilterSelectInputList([
			...advanceFilterSelectInputList,
			{
				key: key,
				label: value,
				data: [],
			},
		]);
	}

	// removing the selected inputs from both modal and tables
	function handleRemoveSelectedFilterInput(key) {
		const updatedFilterInputs = advanceFilterSelectInputList.filter((item) => item?.key !== key);
		setadvanceFilterSelectInputList(updatedFilterInputs);
	}

	async function handleOnFocusSelectedFilterInput(key) {
		let vendorList = [];
		const data = await fetchPurchaseOrders({ filterBy: key, filterValue: '' });
		const selectInputData = data?.purchaseOrders?.reduce((acc, purchaseOrder) => {
			if (purchaseOrder[key] || key === 'vendor_name') {
				if (key === 'vendor_name') {
					acc.push({
						key: purchaseOrder.vendor?.vendor_name,
						value: purchaseOrder.vendor?.vendor_name,
					});
					return acc;
				} else {
					acc.push({
						key: purchaseOrder[key],
						value: purchaseOrder[key],
					});
					return acc;
				}
			}
			return acc;
		}, []);

		const updatedSelectInputData = advanceFilterSelectInputList.map((item) => {
			if (item.key === key) {
				return {
					...item,
					data: [...vendorList, ...selectInputData],
				};
			}
			return item;
		});

		setadvanceFilterSelectInputList(updatedSelectInputData);
	}

	// searching in the option list for selected input
	async function handleOnKeyDownCb(key, value) {
		try {
			const params = {
				filterBy: key,
				filterValue: value,
			};
			const { vendors } = await fetchPurchaseOrders(params);

			const optionsList = vendors?.map((vendor) => {
				return {
					key: vendor[key],
					value: vendor[key],
				};
			});

			const updatedAdvanceFilterSelectInputList = advanceFilterSelectInputList?.map((item) => {
				if (item[key]) {
					return {
						...item,
						data: optionsList,
					};
				}
				return item;
			});
			setadvanceFilterSelectInputList(updatedAdvanceFilterSelectInputList);
		} catch (error) {
			console.log(error);
		}
	}

	// clearing the advance filter
	const handleClearAdvanceFilter = useCallback(() => {
		setIsAppliedAdvanceFilter(false);
		setadvanceFilterSelectInputList([]);
		setAdvanceFilterParams((prev) => ({
			...prev,
			filters: {},
		}));
		setPurchaseOrderParams((prev) => ({
			...prev,
			filterBy: null,
			filterValue: null,
		}));
	}, [advanceFilterSelectInputList]);

	// when 1 select input is left on the table and then it removed to reset the filter
	useEffect(() => {
		if (advanceFilterSelectInputList.length === 0 && isAppliedAdvanceFilter) {
			handleClearAdvanceFilter();
		}

		if (advanceFilterSelectInputList.length > 0 && isAppliedAdvanceFilter) {
			handleApplyFilters();
		}
	}, [advanceFilterSelectInputList, isAppliedAdvanceFilter]);

	const handleFetchPurcahseOrders = async () => {
		try {
			setLoader(true);
			const { purchaseOrders, totalPages } = await fetchPurchaseOrders(purchaseOrderParams);
			const mappedPurchaseOrderTableData = purchaseOrders.map((purchase) => {
				return {
					id: purchase.purchase_order_id,
					DATE: getDate(purchase?.date),
					'PO#': JSON.stringify({
						po_number: purchase?.purchase_order_number,
						is_draft: purchase?.is_draft,
					}),
					'VENDOR NAME': purchase?.vendor?.vendor_name,
					Status: purchase?.status || 'N/A',
					Received: JSON.stringify({
						is_received: purchase.is_received,
						is_partially_received: purchase.is_partially_received,
						status: purchase?.status,
					}),
					Billed: JSON.stringify({
						is_billed: purchase?.is_billed,
						is_partially_billed: purchase?.is_partially_billed,
					}),
					'Expected By': getDate(purchase?.expected_delivery_date),
				};
			});
			setTableData(mappedPurchaseOrderTableData);
			setTotalPages(totalPages);
		} catch (error) {
			toast.error('Something went wrong!');
		} finally {
			setLoader(false);
		}
	};

	useEffect(() => {
		handleFetchPurcahseOrders();
	}, [purchaseOrderParams]); // sortParam, filterparam, currentPage

	return (
		<div>
			{bulkAction && bulkAction.length > 0 ? (
				<div className="product-pg-header">
					<div className="dropdown text-end">
						<p className="d-block link-dark text-decoration-none">Bulk Actions</p>
					</div>
					<div className="add-product-button">
						<Button
							type="button"
							button="Delete"
							className="mt-0 px-4 btn-gray"
							// onClick={() => handleUpdateBulkPurchaseOrder("is_deleted")}
							onClick={handleOpenBulkDeletePurchaseOrderModal}
						/>

						<div className="dropdown">
							<button
								className="btn btn-gray mt-0 px-4 dropdown-toggle"
								style={{
									width: '86px',
									height: '32px',
									fontSize: '14px',
									fontWeight: '500',
								}}
								type="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								Share
							</button>
							<ul
								className="dropdown-menu font-14"
								style={{ minWidth: '86px' }}
							>
								<li>
									<a
										className="dropdown-item"
										href="#"
										onClick={() => handlePrintAndDownloadPdf(true)}
									>
										Print
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										href="#"
										onClick={() => handlePrintAndDownloadPdf(false)}
									>
										Download Pdf
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										href="#"
										onClick={handleDownloadTableData}
									>
										Download Excel
									</a>
								</li>
							</ul>
						</div>

						<div className="dropdown">
							<button
								className="btn btn-gray mt-0 px-4 dropdown-toggle"
								style={{
									width: '136px',
									height: '32px',
									fontSize: '14px',
									fontWeight: '500',
								}}
								type="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								Bulk Update
							</button>
							<ul
								className="dropdown-menu font-14"
								style={{ minWidth: '136px' }}
							>
								<li>
									<a
										className="dropdown-item"
										href="#"
										onClick={() => handleUpdateBulkPurchaseOrder('is_issued')}
									>
										Mark As Issued
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										href="#"
										onClick={() => handleUpdateBulkPurchaseOrder('is_cancelled')}
									>
										Cancel Order
									</a>
								</li>
							</ul>
						</div>
						<Button
							type="button"
							button="Convert To Bill"
							disabled={true}
							className="mt-0 px-4 btn-gray"
						/>
						<Button
							type="button"
							button=""
							className="mt-0 px-4 btn-danger"
							close
							onClick={() => {
								setBulkAction(false);
								setBulkAction([]);
								deselectRows && deselectRows();
							}}
						/>
					</div>
				</div>
			) : (
				<div className="product-pg-header add-product">
					<div
						className="dropdown text-end d-flex"
						style={{ gap: '12px' }}
					>
						<a
							className="d-block link-dark text-decoration-none dropdown-toggle"
							id="dropdownUser1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<p>{headerDropDown}</p>
						</a>
						<ul
							className="dropdown-menu font-14"
							aria-labelledby="dropdownUser1"
							style={{ minWidth: '240px' }}
						>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{
												orderBy: 'purchase_order_id',
												sortBy: 'asc',
												filterBy: null,
												filterValue: null,
											},
											'',
											'Active Purchase Orders',
										)
									}
								>
									Active Purchase Orders
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'is_draft', filterValue: 1 }, 'active', 'Draft')
									}
								>
									Draft
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_pending_approval', filterValue: 1 },
											'is_pending_approval',
											'Pendding Approval',
										)
									}
								>
									Pendding Approval
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_approved', filterValue: 1 },
											'is_approved',
											'Approved',
										)
									}
								>
									Approved
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'is_issued', filterValue: 1 }, 'is_issued', 'Issued')
									}
								>
									Issued
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'is_billed', filterValue: 1 }, 'is_billed', 'Billed')
									}
								>
									Billed
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'is_closed', filterValue: 1 }, 'is_closed', 'Closed')
									}
								>
									Closed
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_cancelled', filterValue: 1 },
											'is_cancelled',
											'Cancelled',
										)
									}
								>
									Cancelled
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_received', filterValue: 1 },
											'is_received',
											'Received',
										)
									}
								>
									Received
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_partially_received', filterValue: 1 },
											'is_partially_received',
											'Partially Received',
										)
									}
								>
									Partially Received
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_partially_billed', filterValue: 1 },
											'is_partially_billed',
											'Billed and Not Received',
										)
									}
								>
									Billed and Not Received
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'is_in_transit', filterValue: 1 },
											'is_in_transit',
											'In-Transit',
										)
									}
								>
									In-Transit
								</a>
							</li>
						</ul>
						<div>
							{isAppliedAdvanceFilter ? (
								<div>
									<img
										src={clearAllFilter}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
										onClick={handleClearAdvanceFilter}
									/>
									<a
										onClick={handleClearAdvanceFilter}
										style={{
											color: '#FF0000',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Clear All Filters
									</a>
								</div>
							) : (
								<div>
									<img
										src={advanceFilterIcon}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
									/>
									<a
										onClick={() => setIsAdvanceFilterOpen(true)}
										style={{
											color: '#2E63F6',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Advanced Filters
									</a>
								</div>
							)}
						</div>
					</div>

					<div className="add-product-button column-gap-0">
						<Button
							type="button"
							className="btn-primary mt-0 px-5"
							button="Create New Purchase Order"
							add
							onClick={() => navigateRouter(`/user/${suburl}/purchases-order-create`)}
						/>
						<div className="dropdown text-end ms-3">
							<a
								href="#"
								className="d-block link-dark text-decoration-none"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img src={sortbydot} />
							</a>
							{/* date , reason, created_at , description,status,reference_number, */}
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li>
									<a
										className="dropdown-item sortBy"
										style={{ cursor: 'not-allowed' }}
									>
										Sort By
									</a>
								</li>

								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_order_id', sortBy: 'asc' })}
									>
										Purchase Order Id
										<span>{sortIconMap[sortDirection?.purchase_order_id]}</span>
									</a>
								</li>

								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'date', sortBy: 'asc' })}
									>
										Date
										<span>{sortIconMap[sortDirection?.date]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'created_at', sortBy: 'asc' })}
									>
										Created Time
										<span>{sortIconMap[sortDirection?.created_at]}</span>
									</a>
								</li>

								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'vendor_name', sortBy: 'asc' })}
									>
										Vendor Name
										<span>{sortIconMap[sortDirection?.vendor_name]}</span>
									</a>
								</li>
								{/* <li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'bill_amount', sortBy: 'asc' })}
									>
										Bill Amount
										<span>{sortIconMap[sortDirection?.bill_amount]}</span>
									</a>
								</li> */}
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() =>
											sortDataList({ orderBy: 'expected_delivery_date', sortBy: 'asc' })
										}
									>
										Expected Delivery Date
										<span>{sortIconMap[sortDirection?.expected_delivery_date]}</span>
									</a>
								</li>

								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'updated_at', sortBy: 'asc' })}
									>
										Last Modified time
										<span>{sortIconMap[sortDirection?.updated_at]}</span>
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => exportData(`purchase_order`)}
									>
										Export purchase Order
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_order_id', sortBy: 'asc' })}
									>
										Refresh
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: 10,
					padding: '0px 10px',
				}}
			>
				{isAppliedAdvanceFilter && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 10,
						}}
					>
						{advanceFilterSelectInputList.map((item) => (
							<AdvanceFilterSelectInput
								options={item?.data}
								placeholder={`Select ${item?.label}`}
								value={item?.value}
								onFocusCb={() => {
									handleOnFocusSelectedFilterInput(item?.key);
								}}
								onKeyDownCb={(value) => {
									handleOnKeyDownCb(item?.key, value);
								}}
								onChange={(value) => {
									const updatedData = advanceFilterSelectInputList.map((_item) => {
										if (_item?.key === item?.key) {
											return {
												..._item,
												value: value.value,
											};
										}
										return _item;
									});
									setadvanceFilterSelectInputList(updatedData);
								}}
								removeInput={() => handleRemoveSelectedFilterInput(item?.key)}
								className="selectInput"
							/>
						))}
						<div style={{ marginBottom: '8px' }}>
							<img
								src={addFilter}
								alt=""
								onClick={() => setIsAdvanceFilterOpen(true)}
							/>
						</div>
					</div>
				)}
			</div>

			{isDeleteBulkConfirmationModalOpen && (
				<DeletePurchaseOrderModel
					isDeleteBulkConfirmationModalOpen={isDeleteBulkConfirmationModalOpen}
					setIsDeleteBulkConfirmationModalOpen={() => {
						setBulkAction(false);
						setBulkAction([]);
						setSelectedPRListForDelete([]);
						deselectRows && deselectRows();
						setIsDeleteBulkConfirmationModalOpen(false);
					}}
					selectedVendorsListFoPR={selectedPRListForDelete}
					handleBulkDelete={() => handleUpdateBulkPurchaseOrder('is_deleted')}
					purchaseOrderLength={bulkAction.length}
				/>
			)}

			{loader ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: '100%', marginTop: '20%' }}
				>
					<span
						className="Page_loader"
						role="status"
						aria-hidden="true"
					></span>
				</div>
			) : (
				<div className="productTable-body">
					<Table
						row={tableData}
						coloum={PurchaseOrderColoum}
						className="productTable"
						onRowClick={onRowClick}
						action={setBulkAction}
						onDeselectAll={handleDeselectAll}
					/>
				</div>
			)}
			<Pagination
				currentPage={purchaseOrderParams.page}
				totalPages={totalPages}
				onChangePage={handlePageChange}
			/>

			<AdvanceFilter
				modalLabel="Note: You can create a bill for either Yet to Receive Items or Received Items."
				isOpen={isAdvanceFilterOpen}
				onKeyDownCb={handleOnKeyDownCb}
				advanceFilterOptions={advanceFilterOptions.purchase_order}
				handleAdvanceFilterDropdown={handleAdvanceFilterDropdown}
				advanceFilterSelectInputList={advanceFilterSelectInputList}
				handleRemoveSelectedFilterInput={handleRemoveSelectedFilterInput}
				onFocusSelectedFilterInput={handleOnFocusSelectedFilterInput}
				handleOnChangeSelectedFilterInput={(key, value) => {
					const updatedData = advanceFilterSelectInputList.map((item) => {
						if (item?.key === key) {
							return {
								...item,
								value,
							};
						}

						return item;
					});
					setadvanceFilterSelectInputList(updatedData);
				}}
				onClose={() => {
					setIsAdvanceFilterOpen(false);
				}}
				onApply={handleApplyFilters}
			/>
		</div>
	);
};

export default PurchaseOrder;
