import React, { useState } from 'react';
import './ContactPersons.scss';
import CombinedShape from '../../../assets/image/Combined-Shape.svg'
import { getDate } from '../../../utils/dateUtils';
import { useNavigate } from 'react-router-dom';

const OpenPOSTab = ({ purchaseOrderDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  const suburl = localStorage.getItem('suburl');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {
        purchaseOrderDetails?.length > 0 ?
          purchaseOrderDetails?.map((po) => (
            <div style={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              fontFamily: " Arial, sans-serif",
              margin: "10px 0px"

            }}>
              {/* Status Bar */}
              <div className="status-bar">
                {po.status}
              </div>

              {/* Header */}
              <div className="order-header" onClick={toggleDropdown}>
                <div className="order-info">
                  <span className="order-id">{po.purchase_order_number} <img src={CombinedShape} alt="" style={{ padding: '3px' }} onClick={() => {
                    navigate(`/user/${suburl}/purchases-order-details/${po.purchase_order_id}`)
                  }} /></span>
                  <span className="order-date">{getDate(po.date)}</span>
                </div>
                <div className='d-flex align-items-center ' style={{ gap: '10px' }}>
                  <div className="order-summary d-flex flex-column">
                    <span>Total Items: {po.purchase_order_products.length}</span>
                    <span className="expected-date">EXPECTED BY {getDate(po.expected_delivery_date)}</span>
                  </div>
                  <div>
                    <span>{isOpen ? '▲' : '▼'}</span>
                  </div>
                </div>
              </div>

              {/* Dropdown Content */}
              {isOpen && (
                <div className="order-items">
                  {po?.purchase_order_products?.map((prod, index) => (
                    <div key={prod?.product_id + index} className="order-item">
                      <div>{prod?.product?.product_name}</div>
                      <div>{prod.product_packaging}</div>
                      <div>{prod.order_quantity}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>

          ))
          : <div>Not Available</div>
      }

    </div>
  );
};

export default OpenPOSTab;


//  <br />


//       <div className="purchase-order-card">
//         {/* Status Bar */}
//         <div className="status-bar" style={{ backgroundColor: '#3B58FF' }}>
//           Partially Received
//         </div>

//         {/* Header */}
//         <div className="order-header" onClick={toggleDropdown}>
//           <div className="order-info">
//             <span className="order-id">PO-0011 <img src={CombinedShape} alt="" style={{ padding: '3px' }} /></span>
//             <span className="order-date">26/09/2024</span>
//           </div>
//           <div className='d-flex align-items-center ' style={{ gap: '10px' }}>
//             <div className="order-summary d-flex flex-column">
//               <span>Total Items: 10</span>
//               <span className="expected-date">EXPECTED BY 10/10/2024</span>
//             </div>
//             <div>
//               <span>{isOpen ? '▲' : '▼'}</span>
//             </div>
//           </div>

//         </div>

//         {/* Dropdown Content */}
//         {isOpen && (
//           <div className="order-items">
//             {Array(5).fill().map((_, index) => (
//               <div key={index} className="order-item">
//                 <div>Alcet</div>
//                 <div>Strip</div>
//                 <div>200</div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//       <br />
//       <br />

//     </div >