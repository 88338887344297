import React, { useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import "./index.scss"
import InputField from '../form/FormInput';
import ConfirmationModal from '../../component/modal/ConfirmationModal';

const Configure = ({
  formData,
  handleFormData,
  isConfigurePurchaseOrderModalOpen,
  setIsConfigurePurchaseOrderModalOpen,
  isAutoGeneratedPurchaseOrder,
  setIsAutoGeneratedPurchaseOrder }) => {
  const [purchaseOrderPrefix, purchaseOrderNextNumber] = formData?.purchase_order_number?.split("-")
  const [prefix, setPrefix] = useState(`${purchaseOrderPrefix ? purchaseOrderPrefix + '-' : ''}`);
  const [nextNumber, setNextNumber] = useState(purchaseOrderNextNumber);
  const [prevPrefix, setPrevPrefix] = useState("")
  const [prevNextNumber, setPrevNextNumber] = useState("")
  const [isConfirmSaveModalOpen, setIsConfirmSaveModalOpen] = useState(false);

  const handleSave = () => {
    setIsConfirmSaveModalOpen(true); // Open confirmation modal on save button click
  };

  const handleConfirmSave = () => {
    console.log({ isAutoGeneratedPurchaseOrder })
    if (!isAutoGeneratedPurchaseOrder) {
      handleFormData("purchase_order_number", '');
    }
    // setIsAutoGeneratedPurchaseOrder(true);
    setIsConfigurePurchaseOrderModalOpen(false);
    setIsConfirmSaveModalOpen(false); // Close confirmation modal
  };

  const handleCancelSave = () => {
    setIsConfirmSaveModalOpen(false); // Close confirmation modal
  };


  function handleClose() {
    // setIsAutoGeneratedPurchaseOrder(true)
    setIsConfigurePurchaseOrderModalOpen(false)
  }


  return (
    <div>
      <Modal
        className="configure-modal"
        title={
          <div
            style={{
              fontWeight: '600',
              color: '#000000',
              fontSize: '22px',

            }}
          >Configure Purchase Order
          </div>
        }
        isOpen={isConfigurePurchaseOrderModalOpen}
        onClose={handleClose}
        cancelButton={true}
        handleClose={handleClose}
      >
        <p style={styles.subtext}>
          Your purchase order numbers are set on auto-generate mode to save your time.
        </p>
        <p style={{ fontSize: '16px', color: '#555B67', fontWeight: '600', marginBottom: '20px' }}>Are you sure about changing this setting?</p>

        {/* Radio Options */}
        <div style={styles.option}>
          <input
            type="radio"
            name="orderOption"
            checked={isAutoGeneratedPurchaseOrder}
            onChange={() => setIsAutoGeneratedPurchaseOrder(true)}
            style={styles.radio}
          />
          <label style={{ color: '#00000', fontSize: '16px', fontWeight: '600', color: '#000000' }}>Continue auto-generating purchase order numbers</label>
        </div>

        {/* Input Fields for Prefix and Next Number */}

        <div style={styles.inputContainer}>
          <InputField
            label="Prefix"
            value={prefix}
            placeholder="PO-"
            disabled={isAutoGeneratedPurchaseOrder ? true : false}
            onChange={(value) => setPrefix(value)}
          />

          <InputField
            label="Next Number"
            placeholder="001"
            disabled={isAutoGeneratedPurchaseOrder ? true : false}
            value={nextNumber}
            onChange={(value) => setNextNumber(value)}
          />
        </div>
        {/* {isAutoGeneratedPurchaseOrder && ()} */}

        <div style={styles.option}>
          <input
            type="radio"
            name="orderOption"
            checked={!isAutoGeneratedPurchaseOrder}
            onChange={() => setIsAutoGeneratedPurchaseOrder(false)}
            style={styles.radio}
          />
          <label style={{ color: '#00000', fontSize: '16px', fontWeight: '600', color: '#000000' }}>Enter purchase order numbers manually</label>
        </div>

        <div style={styles.buttonContainer}>
          <button onClick={handleSave} style={styles.saveButton}>Save</button>
          <button onClick={handleClose} style={styles.cancelButton}>Cancel</button>
        </div>

      </Modal>
      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isConfirmSaveModalOpen}
        title="Confirm Save"
        content={
          <p
            style={{
              textAlign: 'center',
              backgroundColor: '#E6F5FF',
              padding: '10px',
              borderRadius: '5px',
              fontWeight: '500',
              color: 'black',
            }}
          >
            Are you sure you want to save these changes?
          </p>
        }
        onCancel={handleCancelSave}
        onOk={handleConfirmSave}
      />
    </div>
  );
};

// CSS Styles
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    width: '650px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    position: 'relative',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  title: {
    margin: 0,
    color: '#000000',
    fontWeight: '600',
    fontSize: '22px'
  },
  closeButton: {
    backgroundColor: '#FF0000',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '6px 11px',
    cursor: 'pointer',
  },
  subtext: {
    fontSize: '14px',
    color: '#666',
    marginBottom: '14px',
    // marginTop: '26px'
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '27px',
    marginTop: '14px'
  },
  radio: {
    width: '22px',
    height: '22px',
    marginRight: '11px',  // Increased the gap between radio button and label
  },
  inputContainer: {
    display: 'flex',
    gap: '10px',
    marginTop: '10px',
  },
  input: {
    width: '100%',
    padding: '8px',
    fontSize: '14px',
    borderRadius: '7px',
    border: '1px solid #ccc',
    marginTop: '8px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px',
    marginTop: '20px',
  },
  saveButton: {
    backgroundColor: '#3B58FF',
    color: '#fff',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '7px',
    cursor: 'pointer',
  },
  cancelButton: {
    backgroundColor: '#424553',
    color: 'white',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '7px',
    cursor: 'pointer',
  },
};

export default Configure;
