// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkmark {
  width: 24px;
  height: 24px;
  border: 1px solid #c3cbdc; /* Border color */
  border-radius: 5px; /* Border radius */
  background-color: #f1f1f1;
  position: relative;
}

.custom-checkbox input:checked + .checkmark {
  background-color: #3b58ff; /* Checked color */
}

.custom-checkbox input:checked + .checkmark::after {
  content: "";
  position: absolute;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white; /* Checkmark color */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}`, "",{"version":3,"sources":["webpack://./src/pages/purchaseOrder/components/EmailCommunication/index.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AACD;;AAEA;EACC,WAAA;EACA,YAAA;EACA,yBAAA,EAAA,iBAAA;EACA,kBAAA,EAAA,kBAAA;EACA,yBAAA;EACA,kBAAA;AACD;;AAEA;EACC,yBAAA,EAAA,kBAAA;AACD;;AAEA;EACC,WAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA,EAAA,oBAAA;EACA,yBAAA;EACA,wBAAA;AACD","sourcesContent":[".custom-checkbox {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tcursor: pointer;\n}\n\n.checkmark {\n\twidth: 24px;\n\theight: 24px;\n\tborder: 1px solid #c3cbdc; /* Border color */\n\tborder-radius: 5px; /* Border radius */\n\tbackground-color: #f1f1f1;\n\tposition: relative;\n}\n\n.custom-checkbox input:checked + .checkmark {\n\tbackground-color: #3b58ff; /* Checked color */\n}\n\n.custom-checkbox input:checked + .checkmark::after {\n\tcontent: '';\n\tposition: absolute;\n\tleft: 8px;\n\ttop: 4px;\n\twidth: 5px;\n\theight: 10px;\n\tborder: solid white; /* Checkmark color */\n\tborder-width: 0 2px 2px 0;\n\ttransform: rotate(45deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
