import React from 'react'
import Table from '../../../../component/table/Table'
import Pagination from '../../../../component/pagination'

function VedorTable({ loader, tableData, vendorColoum, onRowClick, setBulkAction, bulkAction, handleDeselectAll, page, totalPages, handlePageChange }) {

  if (loader) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100%', marginTop: '20%' }}
      >
        <span
          className="Page_loader"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    )
  }
  return (
    <div>
      <div className="productTable-body">
        <Table
          row={tableData}
          coloum={vendorColoum}
          className="productTable"
          onRowClick={onRowClick}
          action={setBulkAction}
          bulkAction={bulkAction}
          onDeselectAll={handleDeselectAll}
        />
      </div >

      <Pagination
        currentPage={page}
        totalPages={totalPages}
        onChangePage={handlePageChange}
      />

    </div>
  )
}

export default VedorTable