import blueCircle from '../../assets/image/blue_circle.png';
import greyCircle from '../../assets/image/grey_circle.png';
import greyBlueCircle from '../../assets/image/partially_blue_grey_circle.png';
export const PurchaseReceivedColoum = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'PR#',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const prIdAndStatus = params.data?.PR || ''; // Fallback to an empty string if undefined
			const [prId, status] = prIdAndStatus.split('|');

			if (status) {
				return (
					<p>
						{prId}
						<span
							style={{
								backgroundColor: '#718096',
								color: 'white',
								borderRadius: '20px',
								padding: '2px 10px',
								paddingBottom: '3px',
								marginLeft: '10px',
								fontSize: '11px',
							}}
						>
							{status}
						</span>
					</p>
				);
			}
			return <p>{prId}</p>;
		},
	},
	{
		field: 'PO#',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const POID = params.data?.PO || ''; // Fallback to an empty string if undefined
			return <p>{POID}</p>;
		},
	},
	{
		field: 'VENDOR NAME',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'RECEIVED',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const status = params.data?.RECEIVED || ''; // Fallback to an empty string if undefined

			if (status) {
				if (status === '0') {
					return (
						<div style={{ textAlign: 'center' }}>
							<img
								src={greyCircle}
								alt="status"
								width="24"
								height="24"
							/>
						</div>
					);
				} else if (status === '1') {
					return (
						<div style={{ textAlign: 'center' }}>
							<img
								src={greyBlueCircle}
								alt="status"
								width="24"
								height="24"
							/>
						</div>
					);
				} else if (status === '2') {
					return (
						<div style={{ textAlign: 'center' }}>
							<img
								src={blueCircle}
								alt="status"
								width="24"
								height="24"
							/>
						</div>
					);
				}
			} else {
				return <p>{status}</p>;
			}
		},
	},
	{
		field: 'BILLED',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const status = params.data?.BILLED || ''; // Fallback to an empty string if undefined

			if (status) {
				if (status === '0') {
					return (
						<div style={{ textAlign: 'center' }}>
							<img
								src={greyCircle}
								alt="status"
								width="24"
								height="24"
							/>
						</div>
					);
				} else if (status === '1') {
					return (
						<div style={{ textAlign: 'center' }}>
							<img
								src={greyBlueCircle}
								alt="status"
								width="24"
								height="24"
							/>
						</div>
					);
				} else if (status === '2') {
					return (
						<div style={{ textAlign: 'center' }}>
							<img
								src={blueCircle}
								alt="status"
								width="24"
								height="24"
							/>
						</div>
					);
				}
			} else {
				return <p>{status}</p>;
			}
		},
	},
];

export const convertToBillColumn = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'PURCHASE ORDER#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'ORDER DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
];

export const Billcolumn = [
	{
		field: 'BILL#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 50,
		flex: 1,
		cellStyle: { color: '#3B58FF' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellStyle: { color: '#555B67' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
	},
	{
		field: 'DUE DATE',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'AMOUNT',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'BALANCE DUE',
		suppressSizeToFit: true,
		minWidth: 70,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
];

export const Ordercolumn = [
	{
		field: 'ORDER#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 50,
		flex: 1,
		cellStyle: { color: '#3B58FF' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellStyle: { color: '#555B67' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
	},
	{
		field: 'DUE DATE',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'AMOUNT',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'BALANCE DUE',
		suppressSizeToFit: true,
		minWidth: 70,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
];
