import Button from '../../../component/ui/Button';

function Header({ componentConfig }) {
	return (
		<div className="product-pg-header add-product">
			<p>{componentConfig.title}</p>
			<div className="add-product-button">
				{componentConfig.isEdit && (
					<Button
						type="button"
						className="btn-gray mt-0 px-4"
						button="Edit"
						onClick={componentConfig.editNavigate}
					/>
				)}
				{componentConfig.isUpdatePOStatus && (
					<Button
						type="button"
						className="btn-gray mt-0 px-4"
						button="Update PO Status"
						onClick={() =>
							componentConfig.setIsUpdatePOStatusOpen(!componentConfig.isUpdatePOStatusOpen)
						}
					/>
				)}
				{componentConfig.isUpdatePOStatusOpen && (
					<div
						className="dropdown-menu show font-14"
						style={{
							width: '160px',
							position: 'absolute',
							zIndex: 1000,
							maxHeight: '300px',
							overflowY: 'auto',
							marginTop: '170px',
							marginLeft: '70px',
						}}
					>
						<div className="dropdown-item">Approved</div>
						<div className="dropdown-item">Issued</div>
						<div className="dropdown-item">In-Transit</div>
						<div className="dropdown-item">Cancelled</div>
					</div>
				)}

				{componentConfig.isSendEmail && (
					<Button
						type="button"
						className="btn-gray mt-0 px-4"
						button="Send Email"
						onClick={componentConfig.sendEmailNavigate}
					/>
				)}

				{componentConfig.isPrintPdf && (
					<Button
						type="button"
						className="mt-0 btn-gray add-pro"
						button="PDF/Print"
						onClick={componentConfig.printPdfNavigate}
					/>
				)}
				{componentConfig.isCreateCreditNote && (
					<Button
						type="button"
						className="mt-0 btn-gray add-pro"
						button="Create Credit Note"
						onClick={componentConfig.createCreditNoteNavigate}
					/>
				)}
				{componentConfig.isClone && (
					<Button
						type="button"
						className="mt-0 btn-gray add-pro"
						button="Clone"
						onClick={componentConfig.cloneNavigate}
					/>
				)}

				{componentConfig.isConvertToBill && (
					<Button
						type="button"
						className="mt-0 btn-gray add-pro"
						button="Convert To Bill"
						onClick={componentConfig.convertToBillHandler}
					/>
				)}
				{componentConfig.isSaved && (
					<Button
						type="button"
						className="mt-0 btn-gray add-pro"
						button="Receive Order"
						onClick={componentConfig.receivedOrderNavigate}
					/>
				)}
				{componentConfig.isVoid && (
					<Button
						type="button"
						className="mt-0 btn-gray add-pro"
						button="Void"
						onClick={componentConfig.voidNavigate}
					/>
				)}
				{componentConfig.isMarkAsTransit && (
					<Button
						type="button"
						className="mt-0 btn-gray add-pro"
						button="Mark as a transit"
						onClick={componentConfig.markAsTransitHandler}
					/>
				)}
				{componentConfig.isDelete && (
					<Button
						type="button"
						className="mt-0 btn-gray add-pro"
						button="Delete"
						onClick={componentConfig.deleteNavigate}
					/>
				)}
				{componentConfig.isUndoReceived && (
					<Button
						type="button"
						className="mt-0 btn-gray add-pro"
						button="Undo Receive"
						onClick={componentConfig.undoReceivedNavigate}
					/>
				)}
				<Button
					type="button"
					className="mt-0 btn-danger add-pro"
					button=""
					close
					onClick={componentConfig.returnScreenUrl}
				/>
			</div>
		</div>
	);
}
export default Header;
