import React from "react";
import { useState } from "react";
import InputField from "../../../../component/form/FormInput";


const Information = () => {
  const [activeTab, setActiveTab] = useState('details');
  return (
    <div style={{ paddingLeft: '10px' }}>
      <div className="CustomerMaindiv">
        <div className="CustomerQ">
          <p>Customer Type</p>
          <p>Customer Name</p>
          <p>Company Name</p>
          <p>Customer Email</p>
          <p>Customer Office No</p>
          <p>Customer Phone No</p>
          <p>Credit Days</p>
        </div>
        <div className="CustomerA">
          <p>Individual</p>
          <p>Raj Kumar Agrawal</p>
          <p>Agrawal Trading company</p>
          <p>rajagrawal38@yourdomain.com</p>
          <p>0141 36254528</p>
          <p>+91 1234567890</p>
          <p>30</p>
        </div>
      </div>
      <br />

      <div className="tabsInformation">
        <div
          className={`tab ${activeTab === 'details' ? 'activeTab' : ''}`}
          onClick={() => setActiveTab('details')}
        >
          Other Details
        </div>
        <div
          className={`tab ${activeTab === 'address' ? 'activeTab' : ''}`}
          onClick={() => setActiveTab('address')}
        >
          Address
        </div>
        <div
          className={`tab ${activeTab === 'remarks' ? 'activeTab' : ''}`}
          onClick={() => setActiveTab('remarks')}
        >
          Remarks
        </div>
      </div>

      <div className="tab-content">
        {activeTab === 'details' && (
          <div className="CustomerMaindiv">
            <div className="CustomerQ">
              <p>PAN</p>
              <p>GST</p>
              <p>Payment Terms</p>
              <p>Documents</p>
            </div>
            <div className="CustomerA">
              <p>AUUPA8710R</p>
              <p>07AUUPA8710R1ZG</p>
              <p>Lorem ipsum is a dummy text</p>
              <a href="#">agrawal-trading-com.doc</a>
            </div>
          </div>
        )}
        {activeTab === 'address' && (
          <div className="CustomerMaindiv">
            <div className="CustomerQ">
              <p>Attention</p>
              <p>Country</p>
              <p>Address Street 1</p>
              <p>Address Street 2</p>
              <p>City</p>
              <p>State</p>
              <p>Zip Code</p>
            </div>
            <div className="CustomerA">
              <p>Lorem ipsum</p>
              <p>270,Building Name, Street Name, Area Name, 123456</p>
              <p>270,Building Name, Street Name, Area Name, 123456</p>
              <p>Jaipur</p>
              <p>Rajasthan</p>
              <p>123456</p>
            </div>
          </div>
        )}
        {activeTab === 'remarks' && (
          <div>
            <div className="">
              <InputField
                label="Remark"
                placeholder="Write Remark"
                type="text"
              // value={remark?.remark_text}
              // onChange={(value) =>
              //   setRemark((prev) => ({
              //     ...prev,
              //     remark_text: value,
              //   }))
              // }
              // disabled={disabled}
              // error={error?.name}
              />
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default Information;
