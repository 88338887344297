import React, { useState } from 'react';
import Button from '../../../component/ui/Button';
import Table from '../../../component/table/Table';
import arrow from '../../../assets/image/arrowD.svg';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './VendorDetail.scss';

const App = () => {

	const transactions = [
		{
			DATE: '15/05/24',
			'TRANSACTION TYPE': 'Lorem Ipsum',
			'TRANSACTION DETAILS': 'Lorem Ipsum',
			AMOUNT: 1000,
			'CR/DR': 'CR',
			BALANCE: 1000,
		},
		{
			DATE: '15/05/24',
			'TRANSACTION TYPE': 'Lorem Ipsum',
			'TRANSACTION DETAILS': 'Lorem Ipsum',
			AMOUNT: 1000,
			'CR/DR': 'CR',
			BALANCE: 1000,
		},
		{
			DATE: '15/05/24',
			'TRANSACTION TYPE': 'Lorem Ipsum',
			'TRANSACTION DETAILS': 'Lorem Ipsum',
			AMOUNT: 1000,
			'CR/DR': 'CR',
			BALANCE: 1000,
		},
		{
			DATE: '15/05/24',
			'TRANSACTION TYPE': 'Lorem Ipsum',
			'TRANSACTION DETAILS': 'Lorem Ipsum',
			AMOUNT: 1000,
			'CR/DR': 'CR',
			BALANCE: 50000,
		},
		{
			DATE: '15/05/24',
			'TRANSACTION TYPE': 'Lorem Ipsum',
			'TRANSACTION DETAILS': 'Lorem Ipsum',
			AMOUNT: 1000,
			'CR/DR': 'CR',
			BALANCE: 50000,
		},
	];

	const columns = [
		{
			field: 'DATE',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 50,
			flex: 1,
			cellStyle: { color: '#555B67' },
			headerStyle: { color: '#555B67' },
		},
		{
			field: 'TRANSACTION TYPE',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellStyle: { color: '#555B67' },
			headerStyle: { color: '#555B67' },
		},
		{
			field: 'TRANSACTION DETAILS',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 300,
			flex: 1,
		},
		{
			field: 'AMOUNT',
			suppressSizeToFit: true,
			minWidth: 40,
			flex: 1,
			cellClass: 'right-align',
			headerClass: 'HeaderBg right-align',
		},
		{
			field: 'CR/DR',
			suppressSizeToFit: true,
			minWidth: 40,
			flex: 1,
			cellRenderer: (params) => (
				<span style={{ color: params.value === 'CR' ? 'green' : 'red' }}>{params.value}</span>
			),
			cellClass: 'right-align',
			headerClass: 'HeaderBg right-align',
		},
		{
			field: 'BALANCE',
			suppressSizeToFit: true,
			minWidth: 70,
			flex: 1,
			cellClass: 'right-align',
			headerClass: 'HeaderBg right-align',
		},
	];

	const [dateRange, setDateRange] = useState('This Month');
	const [showOptions, setShowOptions] = useState(false);
	const [arrowRotated, setArrowRotated] = useState(false);
	const [isCustomDateOpen, setIsCustomDateOpen] = useState(false);
	const [CustomDateRange, setCustomDateRange] = useState([null, null]);
	const [startDate, endDate] = CustomDateRange;

	const handleSelectClick = () => {
		setShowOptions(!showOptions);
		setArrowRotated(!arrowRotated); // Toggle the rotation state
	};

	const handleOptionClick = (option) => {
		setDateRange(option);
		setShowOptions(false);
		setArrowRotated(false); // Reset the arrow rotation when an option is selected
	};

	return (
		<div style={styles.container}>
			<div style={styles.header}>
				<div className='d-flex align-items-center ' style={{ gap: '10px' }}>

					<div
						className="d-flex align-items-center justify-content-center"
						style={{
							border: '1px solid #DADDEC',
							borderRadius: '6px',
							width: '200px',
							position: 'relative',
							paddingLeft: '7px',
						}}
					>
						<div style={{ ...styles.label, width: '130px' }}>Date Range:</div>
						<div
							style={{ width: '100%' }}
							onClick={handleSelectClick}
						>
							<div
								style={styles.dropdown}
								className="fw-bold"
							>
								{dateRange}
								<span
									style={{
										...styles.arrow,
										transform: arrowRotated ? 'rotate(180deg)' : 'rotate(0deg)',
									}}
								>
									{' '}
									<img
										src={arrow}
										alt=""
									/>
								</span>{' '}
								{/* Arrow rotation logic */}
							</div>
							{showOptions && (
								<div style={styles.optionsContainer}>
									{[
										'This Month',
										'This Week',
										'Quarter',
										'This Year',
										'Financial Year',
										'Custom Date',
									].map((option) => (
										<div
											key={option}
											style={styles.option}
											onClick={() => {
												if (option === 'Custom Date') {
													setIsCustomDateOpen(true);
												} else {
													setIsCustomDateOpen(false);
												}
												handleOptionClick(option);
											}}
										>
											{option}
										</div>
									))}
								</div>
							)}
						</div>

					</div>

					{isCustomDateOpen && (
						<div style={{

							width: '210px'

						}}>
							<DatePicker
								placeholderText="Select date range"
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => setCustomDateRange(update)}
								isClearable={true}
								maxDate={new Date()}
							/>
						</div>
					)}

				</div>

				<div style={styles.buttons}>
					<Button
						type="button"
						className="btn-gray mt-0 px-4"
						button="Print/Download"
					/>
					<Button
						type="button"
						className="btn-gray mt-0 px-4"
						button="Send Email"
					/>
				</div>

			</div>



			<div style={styles.centerText}>
				<h2>
					Vendor Statement for Mr. <b>Raj Kumar Agrawal</b>
				</h2>
				<p>from 01/04/2023 to 31/03/2024</p>
			</div>
			<div style={styles.tableContainer}>
				<Table
					coloum={columns}
					row={transactions}
					onRowClick={() => { }}
				/>
			</div>
		</div>
	);
};

const styles = {
	container: {
		padding: '20px',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '20px',
	},
	label: {
		fontSize: '13px',
		color: '#555B67',
	},
	dropdown: {
		padding: '5px',
		fontSize: '12px',
		border: 'none',
		outline: 'none',
		cursor: 'pointer',
		width: '110px',
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},
	arrow: {
		margin: '0',
		transition: 'transform 0.2s ease', // Smooth rotation transition
	},
	optionsContainer: {
		position: 'absolute',
		top: '90%',
		left: 0,
		width: '170px',
		backgroundColor: '#fff',
		border: '1px solid #DADDEC',
		borderRadius: '4px',
		zIndex: 1,
		// display: 'block',
		marginTop: '10px',
	},
	option: {
		padding: '8px',
		cursor: 'pointer',
		backgroundColor: '#F6F6F9',
		borderRadius: '4px',
		'&:hover': {
			backgroundColor: '#f0f0f0',
		},
	},
	buttons: {
		display: 'flex',
		gap: '10px',
	},
	centerText: {
		textAlign: 'center',
		marginBottom: '20px',
	},
	tableContainer: {
		width: '90%',
		margin: '0 auto',
		minHeight: '252px',
		overflowY: 'hidden',
	},
};

export default App;
