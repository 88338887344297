import React, { useState } from "react";
import InputField from "../../component/form/FormInput";
import Button from "../../component/ui/Button";
import InputSelect from "../../component/inputSelect/InputSelect";
import { useNavigate } from "react-router-dom";

const CreatePaymentMade = () => {
  const suburl = localStorage.getItem("suburl");
  const navigate = useNavigate();
  const [paymentAmount, setPaymentAmount] = useState("");
  const [isFullPayment, setIsFullPayment] = useState(false);

  // Dummy data for the table
  const [orders, setOrders] = useState([
    {
      date: "27/02/2024",
      dueDate: "12/02/2024",
      billNumber: "BFGDSR27",
      poNumber: "PO-0004",
      billAmount: 6000,
      amountDue: 6000,
      payment: 6000,
    },
    {
      date: "27/02/2024",
      dueDate: "20/02/2024",
      billNumber: "HYT",
      poNumber: "PO-0004",
      billAmount: 300,
      amountDue: 300,
      payment: 100,
    },
  ]);

  const [isAddNewVendorModalOpen, setIsAddNewVendorModalOpen] = useState(false)

  const handlePaymentChange = (index, value) => {
    const updatedOrders = [...orders];
    updatedOrders[index].payment = value;
    setOrders(updatedOrders);
  };

  const handleFullPaymentToggle = () => {
    setIsFullPayment((prev) => !prev);
    if (!isFullPayment) {
      setPaymentAmount("6000.00");
    } else {
      setPaymentAmount("");
    }
  };

  const calculateTotalPayment = () => {
    return orders.reduce((total, order) => total + parseFloat(order.payment || 0), 0);
  };

  const navigateRouter = (path) => {
    navigate(path);
  };

  return (
    <div>
      {/* Header Section */}
      <div className="product-pg-header add-product">
        <p>Create New Purchase Bill</p>
        <div className="add-product-button">
          <Button
            type="button"
            className="btn-primary mt-0 px-4"
            button="Save"
          />
          <Button
            type="button"
            className="mt-0 btn-danger add-pro"
            button=""
            close
            onClick={() => navigateRouter(`/user/${suburl}/payment-made`)}
          />
        </div>
      </div>

      {/* Vendor Form Section */}
      <div className="max-w-1297" style={{ padding: "1rem 2rem 0" }}>
        <div className="row">
          <div className="col-6">
            <InputSelect
              label="Vendor Name"
              placeholder="Vendor Name"
              required={true}
              autoFocus={true}
              onAddNew={() => setIsAddNewVendorModalOpen(true)}
            />
          </div>
          <div className="col">
            <InputField
              label="Payment#"
              placeholder="INV-008"
              required={true} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div style={{ position: "relative" }}>
              <InputField
                label="Payment Amount"
                placeholder="Amount"
                type="text"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(e.target.value)}
                style={{
                  paddingRight: "50px",
                  backgroundColor: "#f1f3f5",
                  width: "100%",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  right: "1px",
                  top: "64%",
                  borderRadius: '0px 7px 7px 0px',
                  padding: '7px',
                  transform: "translateY(-50%)",
                  fontSize: "14px",
                  color: "#555B67",
                  backgroundColor: '#EDEDF7',
                  fontWeight: "bold",
                }}
              >
                INR
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                id="payFullAmount"
                onChange={handleFullPaymentToggle}
                style={{ width: "18px", height: "20px", marginRight: "10px", marginBottom: '10px' }}
              />
              <label htmlFor="payFullAmount" style={{ fontSize: "14px", display: 'flex' }}>
                <p style={{ color: '#555B67', fontWeight: 'bold' }}> Pay Full Amount</p><b style={{ marginLeft: '8px' }}>(Rs. 6000.00)</b>
              </label>
            </div>
          </div>
          <div className="col-6">
            <InputField
              label="Date"
              placeholder="Date"
              type="date" />
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-6">
            <InputSelect
              label="Payment Made"
              placeholder="Cash"
              type="text"
              onAddNew={() => setIsAddNewVendorModalOpen(true)}
            />
          </div>
          <div className="col">
            <InputField
              label="Reference#"
              placeholder="REF-0008"
              type="text" />
          </div>
        </div>
      </div>

      {/* Order Table Section */}
      <p style={{ float: 'right', marginRight: '20px', borderBottom: '1px solid #3B58FF', color: '#3B58FF', fontWeight: 'bold' }}>Clear Applied Amount</p>
      <div style={{ margin: "20px 16px", Width: "97%" }}>
        <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
          <thead>
            <tr style={{ backgroundColor: "#f8f9fa", color: '#555B67' }}>
              <th style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "left" }}>Date</th>
              <th style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "left" }}>Bill#</th>
              <th style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "left" }}>PO#</th>
              <th style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "right" }}>Bill Amount</th>
              <th style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "right" }}>Amount Due</th>
              <th style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "right" }}>Payment</th>
            </tr>
          </thead>
          <tbody style={{ color: '#555B67', fontWeight: 'bold' }}>
            {orders.map((order, index) => (
              <tr key={index} style={{
                backgroundColor: index === 1 ? "#f0f8ff" : "transparent",
              }}>
                <td style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "left" }}>
                  {order.date}
                  <br />
                  <span style={{ fontSize: "12px" }}>Due Date: <span style={{ color: '#000000', fontWeight: 'bold' }}>{order.dueDate}</span></span>
                </td>
                <td style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "left" }}>{order.billNumber}</td>
                <td style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "left" }}>{order.poNumber}</td>
                <td style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "right" }}>{order.billAmount}</td>
                <td style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "right" }}>{order.amountDue}</td>
                <td style={{ border: "1px solid #dee2e6", padding: "8px" }}>
                  <InputField
                    type="text"
                    value={order.payment}
                    onChange={(e) => handlePaymentChange(index, e.target.value)}
                    style={{ width: "165px", textAlign: "right", float: 'right' }}
                  />
                  <div style={{
                    fontSize: '12px',
                    color: '#3B58FF',
                    fontWeight: 'bold',
                    float: 'right',
                    cursor: 'pointer',
                    marginBottom: '4px',
                    marginLeft: '32px',
                    borderBottom: '1px solid #3B58FF'
                  }}>
                    Pay in Full
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Total Section */}
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "10px", backgroundColor: "#f8f9fa", border: "1px solid #dee2e6" }}>
          <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
            <span style={{ fontWeight: "bold", color: '#555B67' }}>Total :</span>
            <span style={{ fontWeight: "bold" }}>₹ {calculateTotalPayment().toFixed(2)}</span>
          </div>
        </div>
      </div>




      {/* Summary Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "10px",
          backgroundColor: "#f8f9fa",
          width: '355px',
          float: 'right',
          marginRight: '18px'
        }}
      >
        <div>
          <div className='d-flex'>
            <div style={{ color: '#000000', fontWeight: '600', textAlign: 'right' }}>
              <p>Amount Paid</p>
              <p>Amount Used For Payment</p>
              <p>Amount Refunded</p>
              <p style={{ display: 'flex', paddingLeft: '40px' }}><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.71758 2.89393L1.36508 13.4989C1.2341 13.7258 1.1648 13.9829 1.16407 14.2448C1.16334 14.5067 1.23119 14.7643 1.3609 14.9918C1.4906 15.2194 1.67762 15.409 1.90336 15.5418C2.12909 15.6746 2.38568 15.7461 2.64758 15.7489H15.3526C15.6145 15.7461 15.8711 15.6746 16.0968 15.5418C16.3225 15.409 16.5096 15.2194 16.6393 14.9918C16.769 14.7643 16.8368 14.5067 16.8361 14.2448C16.8354 13.9829 16.7661 13.7258 16.6351 13.4989L10.2826 2.89393C10.1489 2.67351 9.96062 2.49127 9.73597 2.3648C9.51133 2.23832 9.25788 2.17188 9.00008 2.17188C8.74228 2.17188 8.48882 2.23832 8.26418 2.3648C8.03954 2.49127 7.85128 2.67351 7.71758 2.89393Z" stroke="#BF9A18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 6.75V9.75" stroke="#BF9A18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 12.75H9.0075" stroke="#BF9A18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
                Amount Excess</p>
            </div>
            <div style={{ marginLeft: '35px', fontWeight: '600' }}>
              <p>₹ 6,100.00/-</p>
              <p>₹ 6,100.00/-</p>
              <p>00.00/-</p>
              <p>00.00/-</p>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default CreatePaymentMade;
