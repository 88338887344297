import React, { useState, useEffect } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputSelect from '../../component/inputSelect/InputSelect';
import BillTable from './BillTable';
import './CreatePurchaseBill.scss';
import add from '../../assets/image/add.svg';
import BlackClose from '../../assets/image/x-squareBlack.svg';
import ProfilePlaceHolder from '../../assets/image/emailPlaceHolder.svg';
import percentIcon from '../../assets/image/percentIcon.svg';
import ProductDetails from '../purchaseOrder/Navigation/ProductDetails';
import Transactions from '../purchaseOrder/Navigation/Transactions';
import { toast } from 'react-toastify';
import { fetchVendors } from '../../services/vendor';
import { fetchPurchaseOrders } from '../../services/purchaseOrder';
import { create_purchase_bill } from '../../services/purchase_bill';
import Modal from '../../component/modal/Modal';


const CreatePurchaseBill = () => {
	const today = new Date().toISOString().split('T')[0];
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [date, setDate] = useState(today);
	const [isErrortable, setIsTableError] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [disabled, setDisabled] = useState(id ? true : false);
	const [TableError, setTableError] = useState(false);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [productDetails, setProductDetails] = useState(null);
	const navTabs = ['Product Details', 'Transactions'];
	const [activeTab, setActiveTab] = useState('bill');
	const [error, setError] = useState({});
	const [loading, setLoading] = useState({
		isLoading: false,
		loaderId: 'draft',
	});
	const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(null);
	const [inputSelectOption, setInputSelectOption] = useState({
		vendorList: [],
		purhcaseOrderIdList: [],
	});
	const paymentMethodList = [
		{ key: 1, value: 'Cash' },
		{ key: 2, value: 'IMPS' },
		{ key: 3, value: 'NEFT' },
		{ key: 4, value: 'RTGS' },
	];
	const [purchaseBillFormData, setPurchaseBillFormData] = useState({
		vendor_id: null,
		purchase_order_number: '',
		purchase_order_id: null,
		bill_number: '',
		bill_date: today,
		due_date: '',
		bill_amount: '',
		payment_method: '',
		discount: '',
		freight: '',
		other_adjustment: '',
		purchase_bill_products: [],
	});
	const [isBillModalOpen, setIsBillModalOpen] = useState(false);

	const [params, setParams] = useState({
		vendor: {
			page: 1,
			pageSize: 20,
			orderBy: null,
			sortBy: null,
			filterBy: null,
			filterValue: null,
		},
		purchaseOrder: {
			page: 1,
			pageSize: 20,
			orderBy: null,
			sortBy: null,
			filterBy: null,
			filterValue: null,
		},
	});

	const renderTabContent = () => {
		switch (activeTab) {
			case 'Product Details':
				return <ProductDetails productDetails={productDetails} />;
			case 'Transactions':
				return <Transactions />;
			default:
				return null;
		}
	};
	//TODO: change form data
	function handleFormData(key, value) {
		setPurchaseBillFormData((prev) => ({
			...prev,
			[key]: value,
		}));
	}

	//TODO: validation of form

	function checkValidation() {
		const newError = { ...error };
		if (
			!purchaseBillFormData.vendor_id ||
			purchaseBillFormData.vendor_id === '' ||
			purchaseBillFormData.vendor_id === undefined
		) {
			newError.vendor_id = 'Vendor name is required';
		} else {
			delete newError.vendor_id; // Agar fill kiya hai to error ko remove karein
		}

		if (
			!purchaseBillFormData.purchase_order_number ||
			purchaseBillFormData.purchase_order_number === '' ||
			purchaseBillFormData.purchase_order_number === undefined
		) {
			newError.purchase_order_number = 'Purchase order number is required';
		} else {
			delete newError.purchase_order_number; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!purchaseBillFormData.date ||
			purchaseBillFormData.date === '' ||
			purchaseBillFormData.date === undefined
		) {
			newError.date = 'Date is required';
		}
		if (
			!purchaseBillFormData.due_date ||
			purchaseBillFormData.due_date === '' ||
			purchaseBillFormData.due_date === undefined
		) {
			newError.date = 'Due Date is required';
		} else {
			delete newError.date; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!purchaseBillFormData.payment_method ||
			purchaseBillFormData.payment_method === '' ||
			purchaseBillFormData.payment_method === undefined
		) {
			newError.payment_method = 'Payment Method is required';
		} else {
			delete newError.payment_method; // Agar fill kiya hai to error ko remove karein
		}

		if (Object.keys(newError).length > 0) {
			setError(newError);
			return false;
		} else {
			return false;
		}
	}

	async function handleSavePurchaseBill(status) {
		const isErrorShow = checkValidation();
		if (isErrorShow) {
			toast.error('Something went wrong!');
		} else {
			try {
				setLoading((prev) => ({
					...prev,
					isLoading: true,
					loaderId: status,
				}));
				const body = {
					...purchaseBillFormData,
					status,
					purchase_receive_products: purchaseBillFormData?.purchase_bill_products.map((prod) => {
						const { qty, is_in_transit, ...rest } = prod;
						return {
							...rest,
							// received_quantity: Number(qty),
						};
					}),
				};
				await create_purchase_bill(body);
				toast.success('Purchase Bill Created Successfully');
				navigate(`/user/${suburl}/purchases-bills`);
			} catch (error) {
				toast.error('Something went wrong!');
			} finally {
				setLoading((prev) => ({
					...prev,
					isLoading: false,
					loaderId: status,
				}));
			}
		}
	}

	//TODO: fetching vendor list
	async function handleFetchVendorList() {
		try {
			const { vendors } = await fetchVendors(params?.vendor);
			const vendorOptionList = vendors?.map((vendor) => {
				return {
					key: vendor?.vendor_id,
					value: vendor?.vendor_name,
				};
			});
			setInputSelectOption((prev) => ({
				...prev,
				vendorList: vendorOptionList,
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	//TODO: fetching purchase order number list according to the vendor id
	async function handleFetchPurchaseOrders() {
		try {
			const { purchaseOrders } = await fetchPurchaseOrders({
				...params?.purchaseOrder,
				filterBy: 'vendor_id',
				filterValue: purchaseBillFormData?.vendor_id,
			});

			const purhcaseOrderIdList =
				purchaseOrders?.length > 0
					? purchaseOrders.map((po) => {
						return {
							key: po.purchase_order_id,
							value: po.purchase_order_number,
						};
					})
					: [];

			setInputSelectOption((prev) => ({
				...prev,
				purhcaseOrderIdList,
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	//TODO: fetching purchase order details products according to the specific purchase order
	async function handleFetchPurchaseOrder() {
		try {
			if (id && id.includes('pr')) return;
			const { purchaseOrders } = await fetchPurchaseOrders(
				id && id
					? { ...params, filterBy: 'purchase_order_id', filterValue: Number(id) }
					: params.purchaseOrder,
			);
			console.log(purchaseOrders);
			const purchaseOrderDetails = purchaseOrders[0];
			const purchase_bill_products =
				purchaseOrderDetails.purchase_order_products.length > 0
					? purchaseOrderDetails.purchase_order_products?.map((pop) => {
						console.log(pop);
						return {
							...pop,
							product_name: pop.product.product_name,
							product_order_id: pop.product_id,
							product_batch_list: pop.product.product_batch,
							batch_number: '',
							quantity: 0,
							mfg_date: today,
							expiry_date: today,
							mrp: 0,
							purchase_rate: 0,
							sale_rate: 0,
							free_quantity: pop.free_quantity || 0,
						};
					})
					: [];

			setPurchaseOrderDetails(purchaseOrderDetails);
			setPurchaseBillFormData((prev) => ({
				...prev,
				purchase_bill_products,
				...(id
					? {
						vendor_id: purchaseOrderDetails?.vendor_id,
						purchase_order_number: purchaseOrderDetails?.purchase_order_number,
						purchase_order_id: purchaseOrderDetails?.purchase_order_id,
					}
					: {}),
			}));
			console.log(setPurchaseBillFormData);
			setParams((prev) => ({
				...prev,
				vendor: {
					...prev.vendor,
					filterBy: 'vendor_id',
					filterValue: Number(purchaseOrderDetails?.vendor_id),
				},
			}));
		} catch (error) {
			toast.error('Something went wrong!' + error);
		}
	}

	useEffect(() => {
		handleFetchVendorList();
	}, [params.vendor]);

	useEffect(() => {
		if (purchaseBillFormData.vendor_id) {
			handleFetchPurchaseOrders();
		}
	}, [purchaseBillFormData.vendor_id]);

	useEffect(() => {
		if (purchaseBillFormData.purchase_order_id || id) {
			handleFetchPurchaseOrder();
		}
	}, [purchaseBillFormData.purchase_order_id, id]);

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	const navigateRouter = (path) => {
		navigate(path);
	};
	return (
		<div>
			<div className="">
				<div className="">
					<div className="product-pg-header add-product">
						<p>Create New Purchase Bill</p>
						<div className="add-product-button">
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save"
								loader={loading?.status === 1 && loading.isLoading}
								onClick={() => {
									handleSavePurchaseBill(1);
								}}
							/>
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save as Draft"
								loader={loading?.status === 0 && loading.isLoading}
								onClick={() => {
									handleSavePurchaseBill(0);
								}}
							/>
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => navigateRouter(`/user/${suburl}/purchases-bills`)}
							/>
						</div>
					</div>
				</div>
				{/*  Vendor Form */}
				<div
					className="max-w-1297"
					style={{ padding: '1rem 2rem 0' }}
				>
					<div className="row">
						<div className="col-6">
							<InputSelect
								options={inputSelectOption.vendorList}
								label="Vendor Name"
								placeholder="Vendor Name"
								required={true}
								returnInputValue={false}
								value={purchaseBillFormData?.vendor_id}
								onSelect={(option) => {
									setPurchaseBillFormData((prev) => ({
										...prev,
										vendor_id: option.key,
									}));

									setParams((prev) => ({
										...prev,
										vendor: {
											...prev.vendor,
											filterBy: 'vendor_id',
											filterValue: option.key,
										},
									}));
								}}
								onChange={(value) =>
									setParams((prev) => ({
										...prev,
										vendor: {
											...prev.vendor,
											filterBy: 'vendor_name',
											filterValue: value,
										},
									}))
								}
								// disabled={disabled}
								// error={error?.vendor_name}
								autoFocus={true}
								onAddNew={() => setIsBillModalOpen(true)}
							/>
						</div>
						<div className="col-3">
							<InputSelect
								options={inputSelectOption.purhcaseOrderIdList}
								label="PO#"
								placeholder="Purchase Order No"
								required={true}
								moreInfobtn={<a href='#' style={{ textDecoration: 'none', fontSize: '11px' }} onClick={toggleSidebar}>More Info</a>}
								returnInputValue={false}
								value={purchaseBillFormData?.purchase_order_id}
								onSelect={(option) => {
									setPurchaseBillFormData((prev) => ({
										...prev,
										purchase_order_id: option.key,
									}));
									setParams((prev) => ({
										...prev,
										purchaseOrder: {
											...prev.purchaseOrder,
											filterBy: 'purchase_order_id',
											filterValue: option.key,
										},
									}));
								}}
								onChange={(value) => {
									setParams((prev) => ({
										...prev,
										purchaseOrder: {
											...prev.purchaseOrder,
											filterBy: 'purchase_order_number',
											filterValue: value,
										},
									}));
								}}
							// disabled={disabled}
							// required={true}
							// error={error?.vendor_display_name}
							/>
						</div>
						<div className="col-3">
							<InputField
								label="Bill No #"
								placeholder="Bill No #"
								type="text"
								required={true}
								onChange={(value) => handleFormData('bill_number', value)}
								value={purchaseBillFormData.bill_number}
								disabled={disabled}
								error={error?.bill_number}
							// onFocus={(e) => {
							// 	isVendorDisplayNameTouched.current = true;
							// }}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							<InputField
								label="Date"
								placeholder="Enter Date"
								type="date"
								value={purchaseBillFormData.bill_date}
								onChange={(val) => handleFormData('bill_date', val)}
								// autoFocus={disabled ? false : true}
								// disabled={disabled}
								required={true}
								error={error?.bill_date}
							/>
						</div>
						<div className="col-3">
							<InputField
								label="Due Date"
								placeholder="Enter Date"
								type="date"
								value={purchaseBillFormData.due_date}
								onChange={(val) => handleFormData('due_date', val)}
								// autoFocus={disabled ? false : true}
								disabled={disabled}
								required={true}
								error={error?.due_date}
							/>
						</div>
						<div className="col-3">
							<InputField
								label="Bill Amount"
								placeholder="Bill Amount"
								type="text"
								// required={true}
								value={purchaseBillFormData.bill_amount}
								onChange={(val) => handleFormData('bill_amount', val)}
								disabled={disabled}
							/>
						</div>
						<div className="col-3">
							<InputSelect
								options={paymentMethodList}
								label="Payment Method"
								placeholder="Payment Method"
								required={true}
								value={purchaseBillFormData.payment_method}
								onChange={(options) => handleFormData('payment_method', options.value)}
								onSelect={(options) => handleFormData('payment_method', options.value)}
							// disabled={disabled}
							// error={error?.vendor_name}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<InputField
								label="Discount"
								placeholder="Discount"
								type="text"
								// required={true}
								value={purchaseBillFormData.discount}
								onChange={(value) => handleFormData('discount', value)}
								disabled={disabled}
							// error={error?.email}
							/>
						</div>

						<div className="col-4">
							<InputField
								label="Freight"
								placeholder="Freight"
								type="text"
								// required={true}
								value={purchaseBillFormData.freight}
								onChange={(value) => handleFormData('freight', value)}
							// disabled={disabled}
							// error={error?.email}
							/>
						</div>

						<div className="col-4">
							<InputField
								label="Other Adjustment"
								placeholder="Other Adjustment"
								type="text"
								// required={true}
								value={purchaseBillFormData.other_adjustment}
								onChange={(value) => handleFormData('other_adjustment', value)}
							// disabled={disabled}
							// error={error?.email}
							/>
						</div>
					</div>

					<button
						className='d-flex align-items-center justify-content-around'
						style={{ height: '32px', width: '160px', backgroundColor: '#3B58FF', marginTop: '12px' }}
						onClick={() => setIsBillModalOpen(true)}
					>
						<img src={add} alt="" /> Add E-Way Bill
					</button>
					<br />
				</div>
				{/* Bill Table */}
				<BillTable
					tableData={purchaseBillFormData.purchase_bill_products}
					setTableData={(data) => {
						setPurchaseBillFormData((prev) => ({
							...prev,
							purchase_bill_products: data,
						}));
					}}
					disabled={disabled}
					paramId={id}
					tableError={TableError}
					setIsTableErrorShow={setIsTableError}
				/>
			</div>
			<div
				style={{
					height: '112px',
					border: '1px solid #C3CBDC',
					backgroundColor: '#DADDEC',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginRight: '25px',
				}}
			>
				<div className="TotalBillFormDiv">
					<InputField
						label="Discont"
						style={{
							width: '106px',
						}}
						placeholder="10"
						otherDiv={<div><img src={percentIcon} alt="percentIcon" style={{ marginBlock: '5px' }} /></div>}

					/>
					<InputField
						label="Adj"
						style={{
							width: '106px',
						}}
						placeholder="0.00"
					/>
					<InputField
						label="Round Off"
						style={{
							width: '106px',
						}}
						placeholder="0.00"
					/>
				</div>

				<div className='TotleBillDiv' style={{ marginTop: '16px' }}>
					<div style={{ textAlign: 'right' }}>
						<p className='TotalBillQ' style={{ marginBottom: '16px' }}>Sub Total</p>
						<p className='TotalBillQ'>Taxes</p>
						<p className='TotalBillFNL'>Bill Total</p>
					</div>
					<div style={{ textAlign: 'right' }}>
						<p className='TotalBillA' style={{ marginBottom: '17px' }}>₹ 200.00/-</p>
						<p className='TotalBillA'>₹ 235.00/-</p>
						<p className='TotalBillFNL'>₹ 6257.00/-</p>
					</div>
				</div>

				{/* Sidebar ka layout */}
				{isSidebarOpen && (
					<div className="sidebar">
						<div
							className="d-flex align-self-center justify-content-sm-between "
							style={{ gap: '60px' }}
						>
							<div className="VenderDetails">Product Details</div>

							<div
								className="d-flex align-self-center "
								style={{ gap: '26px', alignItems: 'center' }}
							>
								<div>
									<Link to={`/user/${suburl}/purchases-order-details/${id}`}>View more details</Link>
								</div>

								<div
									className="SidebaarCloseBTN d-flex align-self-center justify-content-sm-center"
									onClick={toggleSidebar}
								>
									<img
										src={BlackClose}
										alt=""
										style={{ width: '14px', cursor: 'pointer' }}
									/>
								</div>
							</div>
						</div>

						<br />

						<div
							className="d-flex"
							style={{ gap: '20px' }}
						>
							<div className="profileImage d-flex align-self-center justify-content-sm-center">
								<img
									src={ProfilePlaceHolder}
									alt=""
									style={{ width: '55px', borderRadius: '10px' }}
								/>
							</div>

							<div
								className="d-flex flex-column"
								style={{ gap: '15px' }}
							>
								<div
									style={{
										fontSize: '18px',
										fontWeight: '600',
										color: '#333',
									}}
								>
									{productDetails?.product?.product_name}
								</div>

								<div
									style={{
										fontSize: '14px',
										color: 'var(--sub, #555B67)',
									}}
								>
									<b>{productDetails?.product_packaging}</b> contains{' '}
									<b>{productDetails?.product?.contains}</b>
								</div>
							</div>
						</div>
						<br />
						<hr />

						{/* tabs  */}
						<div>
							<ul className="nav mb-4 mt-2">
								{navTabs.map((item, index) => (
									<li
										key={index}
										className="nav-item"
									>
										<a
											className={`nav-link ${activeTab === item ? 'active' : ''} cursur-pointer`}
											href="#"
											onClick={(e) => {
												e.preventDefault(); // Prevent the default anchor click behavior
												setActiveTab(item);
											}}
										>
											{item}
										</a>
									</li>
								))}
							</ul>
							<hr
								style={{
									backgroundColor: '#E3E4E5',
									boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
									marginTop: '-13px',
								}}
							/>
							<div>{renderTabContent()}</div>
						</div>
					</div>
				)}

				{isBillModalOpen && (
					<Modal
						className=" billModal"
						isOpen={isBillModalOpen}
						onClose={setIsBillModalOpen}
						cancelButton={setIsBillModalOpen}
						title={
							<div
								style={{
									fontWeight: '600',
									color: '#000000',
									fontSize: '22px',
								}}
							>
								Add E-Way Bill Details
							</div>
						}
						headerClassName="discard-header"
						bodyClassName="discard-Body"
					>
						<div>
							<div className="row">
								<div className="col">
									<InputField
										label="E-Way Bill No."
										placeholder="Enter E-Way Bill No"
										required={true}
										value={[]}
										// onChange={(value) => handleBasicInputChange('vendor_name', value)}
										// disabled={disabled}
										// error={error?.vendor_name}
										autoFocus={true}
									/>
								</div>
								<div className="col">
									<InputSelect
										label="Challan No."
										placeholder="Enter Challan No"
										required={true}
										value={[]}
									// onChange={(value) => handleBasicInputChange('vendor_name', value)}
									// disabled={disabled}
									// error={error?.vendor_name}
									/>
								</div>
								<div className="col">
									<InputField
										label="Due Date"
										placeholder="Enter Date"
										type="date"
										value={date}
										onChange={(val) => setDate(val)}
										// autoFocus={disabled ? false : true}
										// disabled={disabled}
										required={true}
									// error={error?.date}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<InputField
										label="Transport"
										placeholder="Enter Transport Agency Name"
										required={true}
										value={[]}
									// onChange={(value) => handleBasicInputChange('vendor_name', value)}
									// disabled={disabled}
									// error={error?.vendor_name}

									/>
								</div>
								<div className="col">
									<InputField
										label="Cases"
										placeholder="Enter Number of Cases"
										required={true}
										value={[]}
									// onChange={(value) => handleBasicInputChange('vendor_name', value)}
									// disabled={disabled}
									// error={error?.vendor_name}
									/>
								</div>
								<div className="col">
									<InputField
										label="Purchase Tax"
										placeholder="Enter Purchase Tax"
										value={[]}
										// autoFocus={disabled ? false : true}
										// disabled={disabled}
										required={true}
									// error={error?.date}
									/>
								</div>
							</div>
							<div className='row'>
								<div className='col'>
									<InputField
										label="Remarks"
										placeholder="Enter Purchase Tax"
										value={[]}
										// autoFocus={disabled ? false : true}
										// disabled={disabled}
										required={true}
									// error={error?.date}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'start',
									gap: 12,
									marginTop: '10px',
								}}
							>
								<Button
									type="button"
									className="mt-0 add-pro bill"
									button="Save E-Way Bill"
								// onClick={handleDiscardQuantityClick}
								/>
								<Button
									type="button"
									className="mt-0 add-pro bill2"
									button="Cancel"
								// onClick={handleDiscardQuantityClick}
								/>
							</div>
						</div>
					</Modal>
				)}

			</div>
		</div>
	);
};

export default CreatePurchaseBill;
