import React from 'react';
import BlackClose from '../../assets/image/x-squareBlack.svg';

function Drawer({ isOpen, onClose, title, children, handleViewMoreDetails }) {

  return (
    <div
      style={{
        position: 'fixed',
        right: isOpen ? '0' : '-486px',
        top: '0',
        width: '486px',
        height: '100%',
        backgroundColor: '#fff',
        boxShadow: '-3px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        transition: 'right 0.3s ease-in-out',
        overflowY: 'auto',
        padding: '20px',
        zIndex: 1000,
        marginTop: '60px',
      }}

    >
      <div className="d-flex justify-content-sm-between" style={{ alignItems: 'center', marginBottom: '5px' }} >
        <div className="VenderDetails">{title}</div>

        <div
          className="d-flex align-self-center "
          style={{ gap: '10px' }}
        >
          <div>
            <button
              onClick={handleViewMoreDetails} // Navigate to the details page
              style={{
                background: 'none',
                border: 'none',
                color: '#3B58FF',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              View more details
            </button>
          </div>

          <div
            className="SidebaarCloseBTN d-flex align-self-center justify-content-sm-center"
            onClick={onClose}
          >
            <img
              src={BlackClose}
              alt=""
              style={{ width: '14px', cursor: 'pointer' }}
              onClick={onClose}
            />

          </div>
        </div>
      </div>
      {children}
    </div>
  );
}

export default Drawer;
