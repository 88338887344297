// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalBillFormDiv {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  color: #000;
}

.TotleBillDiv {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  padding-right: 60px;
}

.TotalBillQ {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 14px */
}

.TotalBillA {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.TotalBillF {
  color: #000;
  padding-top: 5px;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 600 !important;
}

.ButtonBGColor {
  border-radius: 8px;
  border: 1px solid #c3cbdc;
  background: #daddec;
  color: var(--main, #0f1c35);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.ButtonBGColor:hover {
  background-color: #c3cbdc;
}`, "",{"version":3,"sources":["webpack://./src/pages/creditNote/CreditNote.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,2BAAA;EACA,kBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;AACD;;AAEA;EACC,aAAA;EACA,yBAAA;EACA,SAAA;EACA,mBAAA;AACD;;AACA;EACC,WAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AACD;;AACA;EACC,WAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACD;;AAEA;EACC,WAAA;EACA,gBAAA;EACA,0BAAA;EACA,kBAAA;EACA,2BAAA;AACD;;AAEA;EACC,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACD;;AACA;EACC,yBAAA;AAED","sourcesContent":[".TotalBillFormDiv {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\tpadding-left: 10px;\n\talign-items: center;\n\tgap: 10px;\n\tfont-weight: 600;\n\tcolor: #000;\n}\n\n.TotleBillDiv {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tgap: 30px;\n\tpadding-right: 60px;\n}\n.TotalBillQ {\n\tcolor: #000;\n\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 600;\n\tline-height: 100%; /* 14px */\n}\n.TotalBillA {\n\tcolor: #000;\n\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 100%;\n}\n\n.TotalBillF {\n\tcolor: #000;\n\tpadding-top: 5px;\n\tfont-size: 15px !important;\n\tfont-style: normal;\n\tfont-weight: 600 !important;\n}\n\n.ButtonBGColor {\n\tborder-radius: 8px;\n\tborder: 1px solid #c3cbdc;\n\tbackground: #daddec;\n\tcolor: var(--main, #0f1c35);\n\tfont-size: 14px;\n\tfont-weight: 600;\n\tline-height: 24px;\n}\n.ButtonBGColor:hover {\n\tbackground-color: #c3cbdc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
