// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  background-color: #f6f6f9;
  height: 54px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.tab.active {
  color: #000000;
  font-weight: bold;
}

.tab.inactive {
  color: #555b67;
  font-weight: normal;
}

.tab.active::after {
  content: "";
  position: absolute;
  width: 75%;
  height: 3px;
  background-color: #3b58ff;
  bottom: 13px;
  left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/BillReceiveTable/index.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,YAAA;AACD;;AAEA;EACC,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;AACD;;AAEA;EACC,cAAA;EACA,iBAAA;AACD;;AAEA;EACC,cAAA;EACA,mBAAA;AACD;;AAEA;EACC,WAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,yBAAA;EACA,YAAA;EACA,UAAA;AACD","sourcesContent":[".tabs {\n\tdisplay: flex;\n\tfont-size: 16px;\n\tfont-weight: 400;\n\tbackground-color: #f6f6f9;\n\theight: 54px;\n}\n\n.tab {\n\tpadding: 10px 20px;\n\tcursor: pointer;\n\tposition: relative;\n\ttransition: all 0.3s ease;\n}\n\n.tab.active {\n\tcolor: #000000;\n\tfont-weight: bold;\n}\n\n.tab.inactive {\n\tcolor: #555b67;\n\tfont-weight: normal;\n}\n\n.tab.active::after {\n\tcontent: '';\n\tposition: absolute;\n\twidth: 75%;\n\theight: 3px;\n\tbackground-color: #3b58ff;\n\tbottom: 13px;\n\tleft: 15px;\n}\n\n.table {\n\t// margin: 'auto';\n\t// height: '200px' !important;\n\t// overflow-y: 'hidden';\n\t// overflow-x: 'hidden';\n\t// border-bottom: '3px solid #C3CBDC';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
