// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FILE UPLOAD */
.hello {
  border-radius: 8px;
  background: #3b58ff;
  display: inline-flex;
  height: 32px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  color: var(--white, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.hello:hover {
  background: #3652f3;
  color: var(--white, #fff);
}

.deleteContactPersonModal {
  width: 500px;
}`, "",{"version":3,"sources":["webpack://./src/pages/purchaseOrder/components/EmailCommunication/AddEmailModal.scss"],"names":[],"mappings":"AAAA,gBAAA;AAKA;EACC,kBAAA;EACA,mBAAA;EACA,oBAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAHD;;AAKA;EACC,mBAAA;EACA,yBAAA;AAFD;;AAKA;EACC,YAAA;AAFD","sourcesContent":["/* FILE UPLOAD */\n.add-contact-person {\n\t.vendorContact-salutation {\n\t}\n}\n.hello {\n\tborder-radius: 8px;\n\tbackground: #3b58ff;\n\tdisplay: inline-flex;\n\theight: 32px;\n\tpadding: 10px 24px;\n\tjustify-content: center;\n\talign-items: center;\n\tcolor: var(--white, #fff);\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 600;\n\tline-height: 100%;\n}\n.hello:hover {\n\tbackground: #3652f3;\n\tcolor: var(--white, #fff);\n}\n\n.deleteContactPersonModal {\n\twidth: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
