import React from 'react';
import Delete from '../../../assets/image/delete.svg';
import './index.scss';
import { getDate } from '../../../utils/dateUtils';

function NonPdfView({
	handleProductDetails,
	handleDiscardQuantity,
	purchaseOrderDetails,
	isPurchaseReceive = false,
	isShowProductDiscardButton = true,
	orderStatus = true,
}) {

	console.log({ purchaseOrderDetails })

	return (
		<div>
			<div className={'invoice'}>
				<div className="header">
					<div>
						<h1 style={{ fontSize: '22px' }}>{purchaseOrderDetails?.purchase_order_number}</h1>
						<br />
						<div>
							<div
								style={{
									color: '#555B67',
									fontWeight: '600',
									fontSize: '14px',
									marginBottom: '5px',
								}}
							>
								STATUS
							</div>
							<div
								className="d-flex"
								style={{
									gap: '30px',
									borderLeft: '2px solid #3B58FF',
									paddingLeft: '14px',
									marginBottom: '16px',
								}}
							>
								<div style={{ marginTop: '6px' }}>
									{!isPurchaseReceive && <p style={{ marginBottom: '17px' }}>Order</p>}
									<p>Receive</p>
									<p>Bill</p>
								</div>
								<div style={{ marginTop: '6px', marginLeft: '30px' }}>
									{!isPurchaseReceive && <p
										style={{
											padding: '0px 10px',
											marginBottom: '17px',
											backgroundColor: purchaseOrderDetails?.is_closed
												? '#6AA247'
												: purchaseOrderDetails?.is_open
													? 'red'
													: '',
											color: '#fff',
											borderRadius: '5px',
											textAlign: 'center',
										}}
									>
										{purchaseOrderDetails?.status}
									</p>}
									<p style={{ color: purchaseOrderDetails?.is_received ? '#6AA247' : 'red' }}>
										{purchaseOrderDetails?.is_received === 1
											? 'Received'
											: purchaseOrderDetails?.is_partially_received === 1 && 'Partially Received'}
										{ }
									</p>
									<p style={{ color: purchaseOrderDetails?.is_billed ? '#6AA247' : 'red' }}>
										{purchaseOrderDetails?.is_billed ? 'Billed' : 'Pending'}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="bill-info">
						<h2 style={{ color: '#555B67', fontSize: '16px', textAlign: 'start' }}>VENDOR NAME</h2>
						<p style={{ textAlign: 'start' }}>
							<a
								href="#"
								style={{ textDecoration: 'none' }}
							>
								{purchaseOrderDetails?.vendor?.vendor_name}
							</a>
						</p>
						<div style={{ marginTop: '33px' }}>
							<h2 style={{ color: '#555B67', fontSize: '16px', textAlign: 'start' }}>
								DELIVERY ADDRESS
							</h2>
							<p style={{ textAlign: 'start', marginTop: '12px' }}>
								{purchaseOrderDetails?.delivery_address}
							</p>
						</div>
					</div>
				</div>

				<div className="delivey-details">
					<div className="address-to">
						<div
							className="d-flex"
							style={{ gap: '25px' }}
						>
							<div style={{ color: '#555B67', fontWeight: '600' }}>
								<p>ORDER DATE</p>
								<p>EXPECTED DELIVERY DATE</p>
							</div>
							<div style={{ color: '#000', fontWeight: 'bold' }}>
								<p>{getDate(purchaseOrderDetails?.date)}</p>
								<p>{getDate(purchaseOrderDetails?.expected_delivery_date)}</p>
							</div>
						</div>
					</div>
				</div>

				<table className="items">
					<thead>
						<tr>
							<th>#</th>
							<th>ITEMS AND DESCTIPTION</th>
							{orderStatus && <th>ORDERED</th>}
							<th>RECEIVED Status</th>
							{isShowProductDiscardButton && <th></th>}
						</tr>
					</thead>
					<tbody style={{ backgroundColor: 'white' }}>
						{purchaseOrderDetails?.purchase_order_products?.map((prod, index) => (
							<tr
								style={{ backgroundColor: 'white' }}
								key={prod.purchase_order_product_id}
							>
								<td>{index + 1}</td>
								<td
									style={{ color: 'blue', cursor: 'pointer' }}
									onClick={() => handleProductDetails(prod)}
								>
									{prod?.product?.product_name}
								</td>
								{orderStatus && (
									<td>
										{prod.order_quantity} {prod.product_packaging}
									</td>
								)}
								<td>
									{prod.received_quantity || 0} {' Received'}
								</td>
								{isShowProductDiscardButton && (
									<td
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<img
											src={Delete}
											alt=""
											style={{
												cursor: 'pointer',
											}}
											onClick={() => handleDiscardQuantity(prod)}
										/>
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default NonPdfView;
