import React, { useState } from 'react';
import Drawer from '../../../../component/Drawer';

import '../../../vendors/NavigationTab.scss'; // Import the custom CSS file
import DetailsTab from '../../Navigation/DetailsTab'; // Import the Details tab component
import OpenPOSTab from '../../Navigation/OpenPOSTab'; // Import the Open POs tab component

import '../../purchaseOrder.scss';
import ProfilePlaceHolder from '../../../../assets/image/emailPlaceHolder.svg';
import phone from '../../../../assets/image/phone-call.svg';
import email from '../../../../assets/image/email.svg';
import { useNavigate } from 'react-router-dom';

function VendorDetailsDrawer({ isOpen, onClose, vendorDetails }) {
  const [activeTab, setActiveTab] = useState('Details');
  const navigate = useNavigate()
  const suburl = localStorage.getItem('suburl');

  const navTabs = ['Details', 'Open POs'];                             

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Details':
        return <DetailsTab vendorDetails={vendorDetails} />;
      case 'Open POs':
        return <OpenPOSTab purchaseOrderDetails={vendorDetails?.purchase_order} />;
      default:
        return null;
    }
  };

  function handleViewMoreDetails() {
    navigate(`/user/${suburl}/vendor-create/${vendorDetails?.vendor_id}`)
  }

  return (
    <Drawer handleViewMoreDetails={handleViewMoreDetails} isOpen={isOpen} onClose={onClose} title="Vendor Details" >

      <br />

      <div
        className="d-flex"
        style={{ gap: '20px' }}
      >
        <div className="profileImage d-flex align-self-center justify-content-sm-center">
          <img
            src={ProfilePlaceHolder}
            alt=""
            style={{ width: '55px', borderRadius: '10px' }}
          />
        </div>

        <div
          className="d-flex flex-column"
          style={{ gap: '15px' }}
        >
          <div
            style={{
              fontSize: '18px',
              fontWeight: '600',
              color: '#333',
            }}
          >
            {vendorDetails?.vendor_name}
          </div>

          <div
            style={{
              fontSize: '14px',
              color: 'var(--sub, #555B67)',
            }}
          >
            {vendorDetails?.vendor_address?.city || 'No address available'}
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div
        style={{
          fontSize: '14px',
          color: 'var(--sub, #555B67)',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          marginTop: '15px',
        }}
      >
        <div style={{
          display: "flex",
          alignItems: "center",
          gap: 10
        }}>
          <img
            src={phone}
            alt=""
          />
          {vendorDetails?.phone_landline} {(vendorDetails?.phone_landline && vendorDetails?.phone_mobile) && ","} {vendorDetails?.phone_mobile}
          {(!vendorDetails?.phone_landline && !vendorDetails?.phone_mobile) && 'Not Available'}
        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          gap: 10
        }}>
          <img
            src={email}
            alt=""
          />
          {vendorDetails?.email || 'Not Available'}
        </div>
      </div>
      <br />
      <br />

      {/* tabs  */}
      <div sty>
        <ul className="nav mb-4 mt-2">
          {navTabs.map((item, index) => (
            <li
              key={index}
              className="nav-item"
            >
              <a
                className={`nav-link ${activeTab === item ? 'active' : ''} cursur-pointer`}
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default anchor click behavior
                  setActiveTab(item);
                }}
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
        <hr
          style={{
            backgroundColor: '#E3E4E5',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            marginTop: '-13px',
          }}
        />
        <div>{renderTabContent()}</div>
      </div>
    </Drawer>
  );
}

export default VendorDetailsDrawer;
