import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Billcolumn } from './purchaseReceivedColoum';
import Header from '../components/Header';
import PageLoader from '../../component/PageLoader';
import BillReceiveTable from '../components/BillReceiveTable';
import { Receivecolumn } from '../purchaseOrder/purchaseOrdereColoum';
import { getDate } from '../../utils/dateUtils';
import { fetchPurchaseReceive, updatePurchaseReceiveStatus } from '../../services/purchase_receive';
import NonPdfView from '../components/NonPdfView';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import Modal from '../../component/modal/Modal';
import { convertToBillColumn } from './purchaseReceivedColoum';
import Table from '../../component/table/Table';
import Button from '../../component/ui/Button';
import { useReactToPrint } from 'react-to-print';
import pdfMake from "pdfmake/build/pdfmake";
import "pdfmake/build/vfs_fonts";

const PurchaseReceiveDetails = () => {
	const [isConvetToBillOpen, setIsConvertToBillOpen] = useState(false);
	const [isProductDetailsOpen, setIsProductDetailsOpen] = useState(false);
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const suburl = localStorage.getItem('suburl');
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [purchaseReceiveDetails, setPurchaseReceiveDetails] = useState(null)
	const printPdfRef = useRef(null);
	const printPurchaseOrderPdf = useReactToPrint({ contentRef: printPdfRef });
	const [purchaseReceiveParams, setPurchaseReceiveParams] = useState({
		page: 1,
		pageNo: 20,
		orderBy: null,
		sortBy: null,
		filterBy: null,
		filterValue: null
	})


	const ConvertToBill = [
		{
			'PURCHASE ORDER#': 'PO-0012',
			'ORDER DATE': '29/03/2024',
			STATUS: 'Issued',
		},
	];

	async function handleDeletePurchaseReceive() {
		try {
			setIsDeleteModalOpen(true);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	const handleConfirmDelete = async () => {
		try {
			const body = {
				purchase_receive_id: id,
				status: "is_deleted",
				value: true
			};
			await updatePurchaseReceiveStatus(body);
			toast.success('Purchase Receive deleted successfully.');
			setIsDeleteModalOpen(false);
			navigate(`/user/${suburl}/purchases-recieve`);
		} catch (error) {
			toast.error('Failed to delete Purchase Receive!');
		}
	};

	const handleCancelDelete = () => {
		setIsDeleteModalOpen(false);
	};



	async function printPurchaseReceivePdf() {

		const { is_received, is_billed, vendor, date, organisation, delivery_address, purchase_order_products } = purchaseReceiveDetails.purchase_order
		const docDefinitions = {
			content: [
				{
					columns: [
						{
							text: `Received Status: ${is_received ? 'Received' : 'Not Received'}`,
							style: 'status',
							color: is_received ? '#6AA247' : 'red',
						},
						{
							text: `Bill Status: ${is_billed ? 'Billed' : 'Pending'}`,
							style: 'status',
							color: is_billed ? '#6AA247' : 'red',
						},
					],
					margin: [0, 0, 0, 10],
				},
				{
					text: vendor?.vendor_name,
					style: 'vendorName',
					margin: [0, 20, 0, 5],
				},
				{
					text: [
						vendor?.address,
						{ text: vendor?.address?.city },
						{ text: `${vendor?.phone_mobile} | ${vendor?.email}`, bold: true },
					],
					style: 'vendorAddress',
				},
				{
					columns: [
						{
							stack: [
								{ text: 'Purchase Receive', style: 'subheader' },
								{ text: `PR-${purchaseReceiveDetails.purchase_receive_id}`, style: 'orderNumber' },
								{ text: `Order Date: ${getDate(date)}`, style: 'orderDate' },
							],
							margin: [0, 20, 0, 10],
						},
					],
				},
				{
					columns: [
						{
							stack: [
								{ text: 'Vendor Address', style: 'label' },
								{ text: organisation?.organisation_name, style: 'detail' },
								{ text: `DL No.: ${vendor?.drug_license_number || 'N/A'}`, style: 'detail' },
								{ text: `GSTIN: ${vendor?.vendor_other_details?.gst || 'N/A'}`, style: 'detail' },
							],
						},
						{
							stack: [
								{ text: 'Deliver To', style: 'label' },
								{ text: delivery_address, style: 'detail' },
							],
						},
					],
					columnGap: 20,
				},
				{
					table: {
						widths: ['5%', '45%', '25%', '25%'],
						body: [
							[
								{ text: '#', bold: true },
								{ text: 'ITEMS AND DESCRIPTION', bold: true },
								{ text: 'PACKAGING', bold: true },
								{ text: 'ORDERED', bold: true },
							],
							...purchase_order_products.map((prod, index) => [
								{ text: index + 1 },
								{ text: prod.product.product_name, color: 'blue' },
								{ text: prod.product_packaging },
								{ text: prod.order_quantity },
							]),
						],
					},
					margin: [0, 20, 0, 10],
				},
				{
					text: 'Authorized Signature --------------------',
					margin: [0, 30, 0, 0],
					alignment: 'left',
				},
			],
			styles: {
				status: {
					fontSize: 10,
					margin: [0, 0, 0, 5],
				},
				vendorName: {
					fontSize: 16,
					bold: true,
				},
				vendorAddress: {
					fontSize: 12,
				},
				subheader: {
					fontSize: 14,
					bold: true,
				},
				orderNumber: {
					fontSize: 12,
					margin: [0, 5, 0, 5],
				},
				orderDate: {
					fontSize: 12,
				},
				label: {
					fontSize: 10,
					bold: true,
					margin: [0, 5, 0, 5],
				},
				detail: {
					fontSize: 10,
					margin: [0, 2, 0, 2],
				},
			},
		}
		pdfMake.createPdf(docDefinitions).print();
	}


	async function handleFetchPurchaseReceiveDetails() {
		try {
			const data = await fetchPurchaseReceive({ ...purchaseReceiveParams, filterBy: "purchase_receive_id", filterValue: Number(id) });
			const purchaseReceiveData = data.purchaseReceive[0]
			setPurchaseReceiveDetails(purchaseReceiveData)
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	useEffect(() => {
		if (id) {
			handleFetchPurchaseReceiveDetails()
		}
	}, [id])

	const handleProductDetails = () => {
		const purchaseOrderId = purchaseReceiveDetails?.purchase_order?.purchase_order_id || id;
		navigate(`/user/${suburl}/purchases-order-details/${purchaseOrderId}`, {
			state: { isSidebarOpen: true }
		});
	};

	return (
		<div style={{
			backgroundColor: "#EDEDF7",
		}}>
			<Header
				headerTitle="Purchase Receive Details"
				closeButton={{
					showCloseButton: true,
					handler: () => navigate(`/user/${suburl}/purchases-order`),
				}}
				buttons={[
					{
						id: 1,
						className: 'btn-gray mt-0 px-4',
						button: 'Edit',
						handler: () => navigate(`/user/${suburl}/purchases-recieve-create/pr-${id}`),
					},
					{
						id: 2,
						className: 'btn-light mt-0',
						button: (
							<div
								className="dropdown"
								style={{
									display: 'inline-block',
									height: '12px',
									width: '80px',
								}}
							>
								<button
									className="btn btn-light dropdown-toggle"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
									style={{
										height: '18px',
										width: '80px',
										marginTop: '-5px',
										fontSize: '14px',
										border: 'none',
										backgroundColor: 'transparent',
										boxShadow: 'none',
										cursor: 'default',
									}}
								>
									Send
								</button>
								<ul
									className="dropdown-menu font-14 DropdownMenuSize "
									style={{ minWidth: '112px', marginTop: '3px' }}
								>
									<li>
										<a
											className="dropdown-item"
											onClick={() => navigate(`/user/${suburl}/purchases-order-send-emails/${id}`)} // Navigate to Send Email Page
											style={{ cursor: 'pointer', fontSize: '14px' }}
										>
											Send Email
										</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											onClick={() => console.log('Send SMS clicked!')} // Add your SMS functionality here
											style={{ cursor: 'pointer', fontSize: '14px' }}
										>
											Send SMS
										</a>
									</li>
								</ul>
							</div>
						),
					},
					{
						id: 3,
						className: 'mt-0 btn-gray add-pro',
						button: 'PDF/Print',
						handler: async () => {
							// await setIsShowPdfView(true)
							printPurchaseReceivePdf()
						},
					},
					{
						id: 4,
						className: 'btn-gray add-pro',
						button: 'Convert To Bill',
						handler: () => setIsConvertToBillOpen(true),

					},
					// {
					// 	id: 6,
					// 	className: 'mt-0 btn-gray add-pro',
					// 	button: 'Mark as in Transit',
					// 	// handler: handleVoidPurchaseOrder,
					// },
					{
						id: 7,
						className: 'btn-gray add-pro',
						button: 'Delete',
						handler: handleDeletePurchaseReceive,
					},
				]}
			/>

			{
				!purchaseReceiveDetails ? <PageLoader />
					:
					<div>

						<BillReceiveTable
							tableConfig={{
								billTable: {
									title: "Bills",
									column: Billcolumn,
									row: purchaseReceiveDetails?.purchase_bill?.length > 0 ? purchaseReceiveDetails?.purchase_bill?.map((pb) => {
										const formattedBillDate = new Date(pb?.bill_date).toLocaleDateString('en-GB');
										const formattedDueDate = new Date(pb?.due_date).toLocaleDateString('en-GB');
										return {
											'BILL#': pb?.bill_number,
											DATE: formattedBillDate,
											STATUS: pb?.status,
											'DUE DATE': formattedDueDate,
											AMOUNT: pb?.amount,
											'BALANCE DUE': pb?.balance_due,
										}
									}) : [],
									count: purchaseReceiveDetails?.purchase_bill.length,
									onRowClick: () => console.log(),
								},
								receiveTable: {
									title: "Purchase Order",
									column: Receivecolumn,
									row: [purchaseReceiveDetails.purchase_order].map((po) => {
										return {
											'id': po?.purchase_order_id,
											'DATE': getDate(po.date),
											'PO#': po?.purchase_order_id,
											'VENDOR NAME': po?.vendor?.vendor_name,
											STATUS: po?.status,
											'RECEIVED': po.is_received,
											'BILLED': po.is_billed,
											'EXPECTED DELIVERY DATE': getDate(po.expected_delivery_date)
										}
									}),
									count: 1,
									onRowClick: (id) => {
										if (id) {
											navigate(`/user/${suburl}/purchases-order-details/${id}`);
										}
									},
								},
							}}
						/>

						<NonPdfView isPurchaseReceive={true} orderStatus={false} isShowProductDiscardButton={false} handleProductDetails={handleProductDetails} handleDiscardQuantity={() => console.log("product details")} purchaseOrderDetails={{
							...purchaseReceiveDetails?.purchase_order,
							purchase_order_number: "PR-" + purchaseReceiveDetails.purchase_receive_id
						}} />
					</div>
			}


			{
				isConvetToBillOpen && (
					<Modal
						className="convertToBillModal"
						isOpen={isConvetToBillOpen}
						onClose={setIsConvertToBillOpen}
						cancelButton={setIsConvertToBillOpen}
						title={
							<div
								style={{
									fontWeight: '600',
									color: '#000000',
									fontSize: '22px',
									paddingTop: '10px',
								}}
							>
								Purchase Receive ({purchaseReceiveDetails?.purchase_receive_number})
							</div>
						}
						headerClassName="custom-header"
						bodyClassName="convertToBillBody"
					>
						<div style={{ backgroundColor: '#E6F5FF', height: '36px', marginBottom: '36px' }}>
							<p
								style={{
									fontSize: '18px',
									color: '#555B67',
									paddingLeft: '10px',
									paddingTop: '3px',
									marginTop: '8px',
								}}
							>
								Note: You can create a bill for either Yet to Receive items or Received Items.
							</p>
						</div>

						<div>
							<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<input
										type="radio"
										id="yetToReceive"
										name="status"
										value="yetToReceive"
										style={{ marginBottom: '21px', width: '16px', height: '23px' }}
									/>
									<label
										htmlFor="yetToReceive"
										style={{ marginLeft: 5 }}
									>
										<div style={{ margin: 0, color: '#555B67', fontSize: '16px' }}>
											Yet to Receive
										</div>
										<div style={{ margin: 0, color: '#9FA6B5', fontSize: '16px' }}>
											Yet to receive product in this PO
										</div>
									</label>
								</div>

								<div style={{ display: 'flex', alignItems: 'center', marginLeft: '104px' }}>
									<input
										type="radio"
										id="received"
										name="status"
										value="received"
										style={{ marginBottom: '19px', width: '16px', height: '23px' }}
									/>
									<label
										htmlFor="received"
										style={{ marginLeft: 5 }}
									>
										<div style={{ margin: 0, color: '#555B67', fontSize: '16px' }}>Received</div>
										<div style={{ margin: 0, color: '#9FA6B5', fontSize: '16px' }}>
											Received product in this PO
										</div>
									</label>
								</div>
							</div>

							<div style={{
								marginTop: '20px',
								maxHeight: '85px',
								overflowY: 'hidden',
								width: '626px',
							}}>
								<Table
									coloum={convertToBillColumn}
									row={ConvertToBill}
									onRowClick={() => { }}
								/>
							</div>

							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'end',
									gap: 4,
									marginTop: '60px',
								}}
							>
								<Button
									type="button"
									className="mt-0 btn-gray add-pro "
									button="Cancel"
									onClick={() => setIsProductDetailsOpen(false)}
								/>
								<Button
									type="button"
									className="mt-0 btn-primary add-pro"
									button="Convert To Bill"
								// onClick={handleDeletePurchaseOrder}
								/>
							</div>
						</div>
					</Modal>
				)
			}

			{/* Confirmation Modal */}
			<ConfirmationModal
				isOpen={isDeleteModalOpen}
				onCancel={handleCancelDelete}
				onOk={handleConfirmDelete}
				title="Confirm Deletion"
				content={
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '500',
							color: 'black',
						}}
					>
						Are you sure you want to delete{' '}
						<span style={{ fontWeight: '600' }}>
							{purchaseReceiveDetails?.purchase_receive_number || 'this purchase receive'}
						</span>
						?
					</p>
				}
			/>
		</div>
	);

};

export default PurchaseReceiveDetails;
