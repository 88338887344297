import React, { useEffect, useState } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputSelect from '../../component/inputSelect/InputSelect';
import OrderTable from './OrderTable';
import { constant } from '../../utils/constant';
import { vendor_list } from '../../store/action/vendor';
import './purchaseOrder.scss';
import {
	create_purchase_order,
	get_delivery_methods,
	get_purchase_order_number,
} from '../../store/action/purchaseOrder';
import { toast } from 'react-toastify';
import { fetchPurchaseOrders } from '../../services/purchaseOrder';
import { getDate } from '../../utils/dateUtils';
import EmailCommunication from './EmailCommunication';
import Drawer from '../../component/Drawer';
import VendorDetails from './VendorDetails';
import Configure from '../../component/Configure/Configure'
import AddVendorModal from './AddNewVendor';
import showVendorDetails from '../../assets/image/Open PO Alert.svg';
// import tooltip from '../../assets/image/tooltip_vendor name.svg';
import tooltip from '../../assets/image/tooltip_vendor name.png';
import setting from '../../assets/image/settings2.svg';


const CreatePurchaseOrder = () => {
	// const todayDate = new Date().toISOString().split('T')[0];
	const [todayDate, setTodayDate] = useState(new Date().toISOString().split('T')[0]);
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isErrortable, setIsTableError] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [disabled, setDisabled] = useState(id ? true : false);
	const [TableError, setTableError] = useState(false);
	const [vendorList, setVendorList] = useState([]);
	const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
	const [selectedVendorId, setSelectedVendorId] = useState(null);
	const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(null);
	const [deliveryMethods, setDeliveryMethods] = useState([]);
	const [referenceNumber, setReferenceNumber] = useState(null);
	const [deliveryAddress, setDeliveryAddress] = useState(null);
	const [emailCommunication, setEmailCommunication] = useState([]);
	const [selectedDeliveryMethodId, setSelectedDeliveryMethodId] = useState(null);
	const [isVendorDetailsDrawerOpen, setIsVendorDetailsDrawerOpen] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const navigateRouter = (path) => {
		navigate(path);
	};

	const handleFetchPurchaseOrderDetails = async () => {
		try {
			const params = {
				filterBy: 'purchase_order_id',
				filterValue: id,
			};
			const purchaseOrderDetails = await fetchPurchaseOrders(params);
			const purchaseDetails = purchaseOrderDetails.purchaseOrders[0];

			setSelectedVendorId(purchaseDetails?.vendor?.vendor_name);
			setPurchaseOrderNumber(purchaseDetails?.purchase_order_number);
			setTodayDate(getDate(purchaseDetails?.date));
			setDeliveryAddress(purchaseDetails?.delivery_address);
			setExpectedDeliveryDate(getDate(purchaseDetails?.expected_delivery_date));
			setReferenceNumber(purchaseDetails?.remarks);
			setSelectedDeliveryMethodId(purchaseDetails?.delivery_method);
			setTableData(
				purchaseDetails?.purchase_order_products.map((prod) => {
					return {
						// product_id: prod?.product_id, prod?.product?.generic_name,
						product_id: prod?.product?.generic_name, //prod?.product_id,
						packaging: prod.product_packaging,
						qty: prod?.order_quantity,
					};
				}),
			);
			setEmailCommunication(purchaseDetails?.email_communication);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	};

	useEffect(() => {
		const param = `pageSize=${constant.bigNumber}`;

		dispatch(vendor_list(param))
			.then((res) => {
				if (res?.success) {
					if (res?.data) {
						if (res?.data?.vendors && res?.data?.vendors?.length > 0) {
							setVendorList(res?.data.vendors);
						}
					}
				} else {
					setVendorList([]);
				}
			})
			.catch((err) => {
				setVendorList([]);
			});

		dispatch(get_purchase_order_number())
			.then((res) => {
				if (res?.success) {
					setPurchaseOrderNumber(res?.purchase_order_number);
				}
			})
			.catch((err) => {
				toast.error('Something went wrong!');
			});

		dispatch(get_delivery_methods())
			.then((res) => {
				if (res?.success) {
					setDeliveryMethods(res?.delivery_methods);
				}
			})
			.catch((err) => {
				toast.error('Something went wrong!');
			});

		if (id) {
			handleFetchPurchaseOrderDetails();
		}
	}, [id]);

	const handleSavePurchaseOrder = (is_draft = 0) => {
		setDisabled(true);
		const data = {
			vendor_id: selectedVendorId,
			purchase_order_number: purchaseOrderNumber, // required
			date: todayDate, // required
			delivery_address: deliveryAddress,
			expected_delivery_date: expectedDeliveryDate,
			remarks: referenceNumber,
			payment_terms: '50',
			delivery_method: selectedDeliveryMethodId,
			is_draft: is_draft, // 1 is draft , 0 is not draft,
			purchase_order_products: tableData.map((order) => ({
				product_id: order.product_id,
				product_packaging: order.packaging,
				order_quantity: Number(order.qty),
			})),
		};

		dispatch(create_purchase_order(data))
			.then((res) => {
				if (res?.success) {
					toast.success('Purchase Order Created Successfully');
					setDisabled(false);
				}
			})
			.catch((err) => {
				toast.error('Something went wrong!');
				setDisabled(false);
			});
	};

	function handleEmailCommunication(emails) {
		setEmailCommunication(emails);
	}

	// ==========
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const handleToggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};


	const handleCloseSidebar = () => {
		setIsSidebarOpen(false);
	};

	const showConfirmAlert = (message, onConfirm, onCancel) => {
		const isConfirmed = window.confirm(message);
		if (isConfirmed && onConfirm) {
			onConfirm(); // Execute the onConfirm callback if user clicks "OK"
		} else if (!isConfirmed && onCancel) {
			onCancel(); // Execute the onCancel callback if user clicks "Cancel"
		}
	};


	return (
		<div>
			<div className="">
				<div className="">
					<div className="product-pg-header add-product">
						<p>Create New Purchase Order</p>
						<div className="add-product-button">
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save"
								onClick={() => {
									if (id) {
										// Show custom confirmation dialog if `id` exists
										showConfirmAlert(
											"Do you want to make changes?",
											() => {
												// If confirmed, call the save function
												handleSavePurchaseOrder();
											},
											() => {
												// Optionally handle cancel if needed
												console.log("Changes were not saved.");
											}
										);
									} else {
										// Directly save if `id` does not exist
										handleSavePurchaseOrder();
									}
								}}

							// onClick={() => handleSavePurchaseOrder()}
							/>

							{
								id ? null : (
									<>
										<Button
											type="button"
											className="btn-primary mt-0 px-4"
											button="Save & Send"
											onClick={() => {
												navigate(`/user/${suburl}/purchases-order-send-emails/1`);
											}}
										/>

										<Button
											type="button"
											className="btn-primary mt-0 px-4"
											button="Save as Draft"
											onClick={() => handleSavePurchaseOrder(1)}
										/>
									</>
								)
							}
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => navigateRouter(`/user/${suburl}/purchases-order`)}
							/>
						</div>
					</div>
				</div>
				{/*  Purchase Order Form */}
				<div
					className="max-w-1297"
					style={{ padding: '1rem 2rem 0' }}
				>
					<div className="row">
						<div className="col-6">

							<InputSelect
								options={vendorList.map((vendor) => ({
									key: vendor.vendor_id,
									value: vendor.vendor_name,

								}))}
								label="Vendor Name"
								placeholder="Vendor Name"
								required={true}
								value={selectedVendorId}
								onChange={(value) => setSelectedVendorId(value)}
								// disabled={disabled}
								// error={error?.vendor_name}
								autoFocus={true}
								onAddNew={(newVendor) => {
									setShowModal(true)
								}}

								className="labelClass"
								leftIcons={[
									{
										src: showVendorDetails,
										alt: 'showVendorDetails',
										onClick: (handleToggleSidebar),
									},
									{
										src: tooltip, // Image 2 path
										alt: 'Icon 2',
										onClick: (handleToggleSidebar),
									},
								]}
							/>

							<div>



								<VendorDetails isOpen={isSidebarOpen} onClose={handleCloseSidebar} />


								{isSidebarOpen && (
									<div
										onClick={handleCloseSidebar}
										style={{
											position: 'fixed',
											top: '0',
											left: '0',
											right: '0',
											bottom: '0',
											float: 'right',
											// backgroundColor: 'rgba(0,0,0,0.3)',
										}}
									></div>
								)}
							</div>
						</div>

						<div className="col d-flex">
							<InputField
								label="Order Number #"
								placeholder="Order Number"
								type="text"
								required={true}
								value={purchaseOrderNumber}
								onChange={(value) => setPurchaseOrderNumber(value)}
								disabled={disabled}
								rightIcon={{
									src: setting,
									alt: 'Your Icon',
									onClick: () => setModalOpen(true),
								}}


							// error={error?.vendor_display_name}
							/>


						</div>
						<div className="col">
							<InputField
								readOnly={true}
								label="Date"
								placeholder="Enter Date"
								type="text"
								value={todayDate}
								required={true}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<InputField
								label="Delivery Address"
								placeholder="Delivery Address"
								type="text"
								required={true}
								value={deliveryAddress}
								onChange={(value) => setDeliveryAddress(value)}
							// disabled={disabled}
							// error={error?.email}
							/>
						</div>

						<div className="col">
							<InputField
								label="Expected delivery Date"
								placeholder="expected delivery  Date"
								type="date"
								value={expectedDeliveryDate}
								onChange={(val) => {
									console.log(val);
									setExpectedDeliveryDate(val);
								}}
								// disabled={disabled}
								required={true}
							// error={error?.date}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<InputField
								label="Reference #"
								placeholder="Reference #"
								type="text"
								value={referenceNumber}
								onChange={(value) => setReferenceNumber(value)}
							// required={true}
							// disabled={disabled}
							// error={error?.email}
							/>
						</div>
						<div className="col">
							<InputSelect
								options={deliveryMethods.map((method) => ({
									key: method.delivery_methods_id,
									value: method.method_name,
								}))}
								label="Delivery Methods"
								placeholder="Delivery Methods"
								returnInputValue={true}
								// required={true}
								value={selectedDeliveryMethodId}
								onChange={(value) => setSelectedDeliveryMethodId(value)}
							// disabled={disabled}
							// error={error?.vendor_name}
							/>
						</div>
					</div>
				</div>
				{/* Purchase order Table */}
				<OrderTable
					tableData={tableData}
					setTableData={setTableData}
					disabled={disabled}
					paramId={id}
					tableError={TableError}
					setIsTableErrorShow={setIsTableError}
				/>

				<EmailCommunication
					emails={emailCommunication}
					handleEmailCommunication={handleEmailCommunication}
				/>

				{/* {isVendorDetailsDrawerOpen && (
					// <Drawer
					// 	isOpen={isVendorDetailsDrawerOpen}
					// 	setIsOpen={setIsVendorDetailsDrawerOpen}
					// >
					// 	Vendor Details
					// </Drawer>
				)} */}
				{modalOpen && <Configure onClose={() => setModalOpen(false)} />}
				{showModal && <AddVendorModal onClose={() => setShowModal(false)} />}

			</div>
		</div>
	);
};

export default CreatePurchaseOrder;
