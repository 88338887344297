import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortbydot from '../../assets/image/sort_by_group.svg';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Pagination from '../../component/pagination';
import { ExportTable } from '../../store/action/export/Export';
import { fetchPurchaseReceive, updatePurchaseReceiveStatus } from '../../services/purchase_receive';
import { getDate } from '../../utils/dateUtils';
import FullCircle from '../../assets/image/full_circle.svg';
import HalfCircle from '../../assets/image/half_circle.svg';
import EmptyCircle from '../../assets/image/empty_circle.svg';
import PageLoader from '../../component/PageLoader';
import { toast } from 'react-toastify';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import DeletePurchaseReceiveModel from './components/DeletePurchaseReceiveModel';
import pdfMake from 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

const PurchaseReceived = () => {
	const suburl = localStorage.getItem('suburl');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const [bulkAction, setBulkAction] = useState([]);
	const [filterparam, setFilterparam] = useState('');
	const [sortParam, setSortParam] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const vendorallList = useSelector((state) => state?.vendorReducer?.vendorList?.data);
	const [tableData, setTableData] = useState([]);
	const [headerDropDown, setHeaderDropDown] = useState('All Purchase Receive');
	const [sortDirection, setSortDirection] = useState({ purchase_order_id: 'asc' });
	const [stateCondition, setStateCondition] = useState('');
	const [isDeleteBulkConfirmationModalOpen, setIsDeleteBulkConfirmationModalOpen] = useState(false);
	const [selectedVendorsListForDelete, setSelectedVendorsListForDelete] = useState([]);
	const [loading, setLoading] = useState({
		pdf: {
			button: 'pdf',
			isLoading: false,
		},
		download: {
			button: 'download',
			isLoading: false,
		},
	});
	const [purchaseReceiveParams, setPurchaseReceiveParams] = useState({
		page: 1,
		pageSize: 20,
		orderBy: 'purchase_receive_id',
		sortBy: 'asc',
		filterBy: null,
		filterValue: null,
	});
	const [purchaseReceive, setPurchaseReceive] = useState({
		currentPage: 1,
		totalPages: 0,
		data: [],
	});
	const [deselectRows, setDeselectRows] = useState(null);

	const handleDeselectAll = (deselectAllRowsFn) => {
		setDeselectRows(() => deselectAllRowsFn);
	};

	const PurchaseReceivedColoum = [
		{
			field: '',
			headerClass: 'HeaderBg',
			checkboxSelection: true,
			headerCheckboxSelection: true,
			minWidth: 50,
			maxWidth: 50,
			lockPosition: true,
		},
		{
			field: 'DATE',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'PR#',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellClass: 'left-align',
			cellRenderer: (params) => {
				const PR = JSON.parse(params.data.PR);
				return (
					<p>
						PR-{PR?.pr}
						{PR?.status === 0 && (
							<span
								style={{
									backgroundColor: '#718096',
									color: 'white',
									borderRadius: '20px',
									padding: '2px 10px',
									paddingBottom: '3px',
									marginLeft: '10px',
									fontSize: '11px',
								}}
							>
								Draft
							</span>
						)}
					</p>
				);
			},
		},
		{
			field: 'PO#',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 150,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'VENDOR NAME',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 150,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'RECEIVED',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 150,
			flex: 1,
			cellClass: 'left-align',
			cellRenderer: (params) => {
				const status = params.data?.RECEIVED || 0;
				if (status === 1) {
					return <img src={FullCircle} />;
				}
				return <img src={EmptyCircle} />;
			},
		},
		{
			field: 'BILLED',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 150,
			flex: 1,
			cellClass: 'left-align',
			cellRenderer: (params) => {
				const status = params.data?.BILLED || ''; // Fallback to an empty string if undefined
				if (status === 3) {
					return <img src={FullCircle} />;
				}
				return <img src={EmptyCircle} />;
			},
		},
	];

	const FilterDatalist = (param, value, dropDownData) => {
		setStateCondition(value);
		setHeaderDropDown(dropDownData);
		setPurchaseReceiveParams((prev) => ({
			...prev,
			...param,
		}));
	};

	const sortIconMap = {
		asc: <IoIosArrowRoundUp />,
		desc: <IoIosArrowRoundDown />,
	};

	const sortDataList = (param) => {
		const newDirection = sortDirection[param.orderBy] === 'desc' ? 'asc' : 'desc';
		setSortDirection({ [param.orderBy]: newDirection });
		setPurchaseReceiveParams((prev) => ({
			...prev,
			orderBy: param.orderBy,
			sortBy: newDirection,
			filterValue: null,
		}));
	};

	const handlePageChange = (page) => {
		setPurchaseReceiveParams((prev) => ({
			...prev,
			page,
		}));
	};

	const navigateRouter = (path) => {
		navigate(path);
	};

	const onRowClick = (id) => {
		if (id) {
			navigate(`/user/${suburl}/purchases-recieve-details/${id}`);
		}
	};

	async function handleBulkDelete() {
		try {
			const body = {
				purchase_receive_id: bulkAction,
				status: 'is_deleted',
				value: true,
			};
			await updatePurchaseReceiveStatus(body);
			toast.success('Purchase Order Deleted.');
			setIsDeleteBulkConfirmationModalOpen(false);
			setBulkAction(false);
			setBulkAction([]);
			deselectRows && deselectRows();
			handleFetchPurchaseReceive();
		} catch (error) {
			toast.error('Something went wrong');
		}
	}

	async function generatePdfToPrintAndDownload() {
		const filterValue = bulkAction.join(',');
		const { purchaseReceive } = await fetchPurchaseReceive({
			filterBy: 'purchase_receive_id',
			filterValue,
		});
		const docDefinitions = [];
		purchaseReceive.forEach((pr) => {
			const {
				is_received,
				is_billed,
				vendor,
				purchase_order_number,
				date,
				organisation,
				delivery_address,
				purchase_order_products,
			} = pr?.purchase_order;
			docDefinitions.push({
				content: [
					{
						columns: [
							{
								text: `Received Status: ${is_received ? 'Received' : 'Not Received'}`,
								style: 'status',
								color: is_received ? '#6AA247' : 'red',
							},
							{ text: '|' },
							{
								text: `Bill Status: ${is_billed ? 'Billed' : 'Pending'}`,
								style: 'status',
								color: is_billed ? '#6AA247' : 'red',
							},
						],
						margin: [0, 0, 0, 10],
					},
					{
						text: vendor?.vendor_name,
						style: 'vendorName',
						margin: [0, 20, 0, 5],
					},
					{
						text: [
							vendor?.address,
							'\nRajasthan\nIndia\n',
							{ text: `${vendor?.phone_mobile} | ${vendor?.email}`, bold: true },
						],
						style: 'vendorAddress',
					},
					{
						columns: [
							{
								stack: [
									{ text: 'Purchase Order', style: 'subheader' },
									{ text: `#${purchase_order_number}`, style: 'orderNumber' },
									{ text: `Order Date: ${getDate(date)}`, style: 'orderDate' },
								],
								margin: [0, 20, 0, 10],
							},
						],
					},
					{
						columns: [
							{
								stack: [
									{ text: 'Vendor Address', style: 'label' },
									{ text: organisation?.organisation_name, style: 'detail' },
									{ text: `DL No.: ${vendor?.drug_license_number || 'N/A'}`, style: 'detail' },
									{ text: `GSTIN: ${vendor?.vendor_other_details?.gst || 'N/A'}`, style: 'detail' },
								],
							},
							{
								stack: [
									{ text: 'Deliver To', style: 'label' },
									{ text: delivery_address, style: 'detail' },
								],
							},
						],
						columnGap: 20,
					},
					{
						table: {
							widths: ['5%', '45%', '25%', '25%'],
							body: [
								[
									{ text: '#', bold: true },
									{ text: 'ITEMS AND DESCRIPTION', bold: true },
									{ text: 'PACKAGING', bold: true },
									{ text: 'ORDERED', bold: true },
								],
								...purchase_order_products.map((prod, index) => [
									{ text: index + 1 },
									{ text: prod.product.product_name, color: 'blue' },
									{ text: prod.product_packaging },
									{ text: prod.order_quantity },
								]),
							],
						},
						margin: [0, 20, 0, 10],
					},
					{
						text: 'Authorized Signature --------------------',
						margin: [0, 30, 0, 0],
						alignment: 'left',
					},
				],
				styles: {
					status: {
						fontSize: 10,
						margin: [0, 0, 0, 5],
					},
					vendorName: {
						fontSize: 16,
						bold: true,
					},
					vendorAddress: {
						fontSize: 12,
					},
					subheader: {
						fontSize: 14,
						bold: true,
					},
					orderNumber: {
						fontSize: 12,
						margin: [0, 5, 0, 5],
					},
					orderDate: {
						fontSize: 12,
					},
					label: {
						fontSize: 10,
						bold: true,
						margin: [0, 5, 0, 5],
					},
					detail: {
						fontSize: 10,
						margin: [0, 2, 0, 2],
					},
				},
			});
		});

		let mergedContent = [];
		docDefinitions.forEach((doc) => {
			mergedContent = mergedContent.concat(doc.content); // Concatenate all content
			// Optionally, you can add a page break between the documents
			mergedContent.push({ text: '', pageBreak: 'after' });
		});

		// Create the final merged document definition
		const mergedDocDefinition = {
			content: mergedContent,
			styles: {
				header: { fontSize: 16, bold: true },
				subheader: { fontSize: 12 },
			},
		};

		return mergedDocDefinition;
	}

	async function handlePrintAndDownloadPdf(isPrint) {
		setLoading((prev) => {
			const obj = {};
			if (isPrint) {
				obj.pdf = {
					button: 'pdf',
					isLoading: true,
				};
			} else {
				obj.download = {
					button: 'download',
					isLoading: true,
				};
			}

			return {
				...prev,
				...obj,
			};
		});
		const mergedDocDefinition = await generatePdfToPrintAndDownload();
		if (isPrint) {
			pdfMake.createPdf(mergedDocDefinition).print();
		} else {
			pdfMake.createPdf(mergedDocDefinition).download();
		}

		setLoading((prev) => ({
			...prev,
			pdf: {
				...prev.pdf,
				isLoading: false,
			},
			download: {
				...prev.download,
				isLoading: false,
			},
		}));
	}

	const exportData = (param) => {
		ExportTable(param);
	};

	async function handleFetchPurchaseReceive() {
		try {
			setIsLoading(true);
			setLoader(true);
			const { purchaseReceive, totalPages } = await fetchPurchaseReceive(purchaseReceiveParams);
			const tableData = purchaseReceive.map((pr) => {
				return {
					id: pr.purchase_receive_id,
					DATE: getDate(pr.receive_date),
					PR: JSON.stringify({ pr: pr?.purchase_receive_id, status: pr?.status }),
					'PO#': pr.purchase_order.purchase_order_number,
					'VENDOR NAME': pr.vendor.vendor_name,
					STATUS: pr?.status,
					RECEIVED: pr?.status,
					BILLED: pr?.status,
				};
			});
			setPurchaseReceive((prev) => ({
				...prev,
				data: tableData,
				totalPages,
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		} finally {
			setIsLoading(false);
			setLoader(false);
		}
	}

	function handleOpenBulkDeletePurchaseReceiveModal() {
		const selectedVendorsList = tableData?.reduce((acc, item) => {
			if (bulkAction.includes(item.id)) {
				acc.push(item.NAME);
				return acc;
			}
			return acc;
		}, []);
		setSelectedVendorsListForDelete(selectedVendorsList);
		setIsDeleteBulkConfirmationModalOpen(true);
	}

	useEffect(() => {
		handleFetchPurchaseReceive();
	}, [purchaseReceiveParams]);

	return (
		<div>
			{bulkAction && bulkAction.length > 0 ? (
				<div className="product-pg-header">
					<div className="dropdown text-end">
						<p className="d-block link-dark text-decoration-none">Bulk Actions</p>
					</div>
					<div className="add-product-button">
						<Button
							type="button"
							button="Print"
							className="mt-0 px-4 btn-gray"
							loader={loading.pdf.isLoading}
							onClick={() => handlePrintAndDownloadPdf(true)}
						/>
						<Button
							type="button"
							button="Download"
							loader={loading.download.isLoading}
							className="mt-0 px-4 btn-gray"
							onClick={() => handlePrintAndDownloadPdf(false)}
						/>

						<Button
							type="button"
							button="Delete"
							className="mt-0 px-4 btn-gray relative"
							onClick={handleOpenBulkDeletePurchaseReceiveModal}
						></Button>
						<Button
							disabled={true}
							type="button"
							button="Mark as In Transit"
							className="mt-0 px-4 btn-gray relative"
						></Button>
						<Button
							type="button"
							className="mt-0 btn-danger add-pro"
							button=""
							close
							onClick={() => {
								setBulkAction(false);
								setBulkAction([]);
								deselectRows && deselectRows();
							}}
						/>
					</div>
				</div>
			) : (
				<div className="product-pg-header add-product">
					<div className="dropdown text-end">
						<a
							className="d-block link-dark text-decoration-none dropdown-toggle"
							id="dropdownUser1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<p>{headerDropDown}</p>
						</a>
						<ul
							className="dropdown-menu font-14"
							aria-labelledby="dropdownUser1"
							style={{ minWidth: '240px' }}
						>
							<li>
								<a
									className="dropdown-item"
									onClick={() => FilterDatalist(
										{ filterBy: null, filterValue: null },
										'',
										'All Purchase Receive',
									)}
								>
									All Purchase Receive
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'status', filterValue: 3 }, 'active', 'Is In Transit')
									}
								>
									In-Transit
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() => FilterDatalist({ filterBy: 'status', filterValue: 1 }, 'active', 'Received')}
								>
									Received
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() => FilterDatalist({ filterBy: 'status', filterValue: 2 }, 'active', 'Received')}
								>
									Not Received
								</a>
							</li>
						</ul>
					</div>

					<div className="add-product-button column-gap-0">
						<Button
							type="button"
							className="btn-primary mt-0 px-5"
							button="Create New Purchase Receive"
							add
							onClick={() => navigateRouter(`/user/${suburl}/purchases-recieve-create`)}
						/>
						<div className="dropdown text-end ms-3">
							<a
								href="#"
								className="d-block link-dark text-decoration-none"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img src={sortbydot} />
							</a>
							{/* date , reason, created_at , description,status,reference_number, */}
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li>
									<a
										className="dropdown-item sortBy"
										style={{ cursor: 'not-allowed' }}
									>
										Sort By
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'receive_date', sortBy: 'asc' })}
									>
										Receive Date
										<span>{sortIconMap[sortDirection?.receive_date]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'created_at', sortBy: 'asc' })}
									>
										Created Time
										<span>{sortIconMap[sortDirection?.created_at]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_receive_id', sortBy: 'asc' })}
									>
										Purchase Receive
										<span>{sortIconMap[sortDirection?.purchase_receive_id]}</span>
									</a>
								</li>

								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_order_number', sortBy: 'asc' })}
									>
										Purchase Order Number
										<span>{sortIconMap[sortDirection?.purchase_order_number]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'vendor_name', sortBy: 'asc' })}
									>
										Vendor Name
										<span>{sortIconMap[sortDirection?.vendor_name]}</span>
									</a>
								</li>
								{/* <li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'amount', sortBy: 'asc' })}
									>
										Amount
										<span>{sortIconMap[sortDirection?.amount]}</span>
									</a>
								</li> */}
								{/* <li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() =>
											sortDataList({ orderBy: 'expected_delivery_date', sortBy: 'asc' })
										}
									>
										Expected Delivery Date
										<span>{sortIconMap[sortDirection?.expected_delivery_date]}</span>
									</a>
								</li> */}

								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'updated_at', sortBy: 'asc' })}
									>
										Last Modified time
										<span>{sortIconMap[sortDirection?.updated_at]}</span>
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										href={() => exportData(`vendor`)}
									>
										Export purchase Order
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_receive_id', sortBy: 'asc' })}
									>
										Refresh
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}

			{isDeleteBulkConfirmationModalOpen && (
				<DeletePurchaseReceiveModel
					isDeleteBulkConfirmationModalOpen={isDeleteBulkConfirmationModalOpen}
					setIsDeleteBulkConfirmationModalOpen={() => {
						setIsDeleteBulkConfirmationModalOpen(false);
						setBulkAction(false);
						setBulkAction([]);
						deselectRows && deselectRows();
					}}
					selectedVendorsListForDelete={selectedVendorsListForDelete}
					handleBulkDelete={handleBulkDelete}
					purchaseReceiveLength={bulkAction.length}
				/>
			)}

			{loader ? (
				<PageLoader />
			) : (
				<div className="productTable-body">
					<Table
						row={purchaseReceive.data}
						coloum={PurchaseReceivedColoum}
						className="productTable"
						onRowClick={onRowClick}
						action={setBulkAction}
						onDeselectAll={handleDeselectAll}
					/>
				</div>
			)}
			<Pagination
				currentPage={purchaseReceiveParams.page}
				totalPages={purchaseReceive.totalPages}
				onChangePage={handlePageChange}
			/>
		</div>
	);
};

export default PurchaseReceived;
