import React, { useEffect, useState } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputSelect from '../../component/inputSelect/InputSelect';
import PurchaseReceiveTable from './PurchaseReceiveTable';
import { fetchVendors } from '../../services/vendor';
import { toast } from 'react-toastify';
import { fetchPurchaseOrders } from '../../services/purchaseOrder';
import {
	create_purchase_receive,
	fetchPurchaseReceive,
	update_purchase_receive,
} from '../../services/purchase_receive';
import { convertToISO, getDate } from '../../utils/dateUtils';

const CreatePurchaseReceive = () => {
	const today = new Date().toISOString().split('T')[0];
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const [date, setDate] = useState(today);
	const [isErrortable, setIsTableError] = useState(false);
	const [loading, setLoading] = useState({
		isLoading: false,
		loaderId: 'draft',
	});
	const [tableData, setTableData] = useState([]);
	const [disabled, setDisabled] = useState(true);
	const [TableError, setTableError] = useState(false);
	const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(null);
	const [params, setParams] = useState({
		vendor: {
			page: 1,
			pageSize: 20,
			orderBy: null,
			sortBy: null,
			filterBy: null,
			filterValue: null,
		},
		purchaseOrder: {
			page: 1,
			pageSize: 20,
			orderBy: null,
			sortBy: null,
			filterBy: null,
			filterValue: null,
		},
	});
	const [inputSelectOption, setInputSelectOption] = useState({
		vendorList: [],
		purhcaseOrderIdList: [],
	});
	const [purchaseReceiveFormData, setPurchaseReceiveFormData] = useState({
		vendor_id: null,
		purchase_order_number: '',
		delivery_address: '',
		receive_date: '',
		purchase_order_id: null,
		purchase_receive_products: [],
	});

	const navigateRouter = (path) => {
		navigate(path);
	};

	//TODO: Saving the purcahse receive products
	async function handleSavePurchaseReceive(status) {
		try {
			setLoading((prev) => ({
				...prev,
				isLoading: true,
				loaderId: status,
			}));
			const body = {
				...purchaseReceiveFormData,
				status,
				purchase_receive_products: purchaseReceiveFormData?.purchase_receive_products.map(
					(prod) => {
						const { qty, is_in_transit, ...rest } = prod;
						return {
							...rest,
							received_quantity: Number(qty),
						};
					},
				),
			};
			await create_purchase_receive(body);
			toast.success('Purchase Receive Created Successfully');
			navigate(`/user/${suburl}/purchases-recieve`);
		} catch (error) {
			toast.error('Something went wrong!');
		} finally {
			setLoading((prev) => ({
				...prev,
				isLoading: false,
				loaderId: status,
			}));
		}
	}

	async function handleUpdatePurchaseReceive() {
		try {
			setLoading((prev) => ({
				...prev,
				status: 2,
				isLoading: false,
			}));

			const body = {
				purchase_receive_id: Number(id.split('-')[1]),
				delivery_address: purchaseReceiveFormData.delivery_address,
				receive_date: convertToISO(purchaseReceiveFormData.receive_date),
				purchase_order_id: Number(purchaseReceiveFormData.purchase_order_id),
				purchase_receive_products: purchaseReceiveFormData?.purchase_receive_products.map(
					(prod) => {
						const { qty, is_in_transit, ...rest } = prod;
						return {
							...rest,
							received_quantity: Number(qty),
						};
					},
				),
			};

			await update_purchase_receive(body);
			toast.success('Purchase Receive Created Successfully');
			navigate(`/user/${suburl}/purchases-recieve`);
		} catch (error) {
			toast.error('Someting went wrong!');
		} finally {
			setLoading((prev) => ({
				...prev,
				status: 2,
				isLoading: false,
			}));
		}
	}

	//TODO: fetching vendor list
	async function handleFetchVendorList() {
		try {
			const { vendors } = await fetchVendors(params?.vendor);
			const vendorOptionList = vendors?.map((vendor) => {
				return {
					key: vendor?.vendor_id,
					value: vendor?.vendor_name,
				};
			});
			setInputSelectOption((prev) => ({
				...prev,
				vendorList: vendorOptionList,
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	//TODO: fetching purchase order number list according to the vendor id
	async function handleFetchPurchaseOrders() {
		try {
			const { purchaseOrders } = await fetchPurchaseOrders({
				...params?.purchaseOrder,
				filterBy: 'vendor_id',
				filterValue: purchaseReceiveFormData?.vendor_id,
			});

			const purhcaseOrderIdList =
				purchaseOrders?.length > 0
					? purchaseOrders.map((po) => {
							return {
								key: po.purchase_order_id,
								value: po.purchase_order_number,
							};
						})
					: [];

			setInputSelectOption((prev) => ({
				...prev,
				purhcaseOrderIdList,
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	//TODO: fetching purchase order details products according to the specific purchase order
	async function handleFetchPurchaseOrder() {
		try {
			if (id && id.includes('pr')) return;
			const { purchaseOrders } = await fetchPurchaseOrders(
				id && id
					? { ...params, filterBy: 'purchase_order_id', filterValue: Number(id) }
					: params.purchaseOrder,
			);
			const purchaseOrderDetails = purchaseOrders[0];
			const purchase_receive_products =
				purchaseOrderDetails.purchase_order_products.length > 0
					? purchaseOrderDetails.purchase_order_products?.map((pop) => {
							return {
								...pop,
								product_name: pop.product.product_name,
								order_quantity: pop.order_quantity,
								received_quantity: pop.received_quantity || 0,
								is_in_transit: 0,
								qty: 0,
							};
						})
					: [];

			setPurchaseOrderDetails(purchaseOrderDetails);
			setPurchaseReceiveFormData((prev) => ({
				...prev,
				delivery_address: purchaseOrderDetails.delivery_address,
				purchase_receive_products,
				...(id
					? {
							vendor_id: purchaseOrderDetails?.vendor_id,
							purchase_order_number: purchaseOrderDetails?.purchase_order_number,
							purchase_order_id: purchaseOrderDetails?.purchase_order_id,
						}
					: {}),
			}));
			setParams((prev) => ({
				...prev,
				vendor: {
					...prev.vendor,
					filterBy: 'vendor_id',
					filterValue: Number(purchaseOrderDetails?.vendor_id),
				},
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	useEffect(() => {
		handleFetchVendorList();
	}, [params.vendor]);

	useEffect(() => {
		if (purchaseReceiveFormData.vendor_id) {
			handleFetchPurchaseOrders();
		}
	}, [purchaseReceiveFormData.vendor_id]);

	useEffect(() => {
		if (purchaseReceiveFormData.purchase_order_id || id) {
			handleFetchPurchaseOrder();
		}
	}, [purchaseReceiveFormData.purchase_order_id, id]);

	async function handleFetchPurchaseReceiveDetails(id) {
		try {
			const data = await fetchPurchaseReceive({
				filterBy: 'purchase_receive_id',
				filterValue: Number(id),
			});
			const purchaseReceiveData = data.purchaseReceive[0];

			setParams((prev) => ({
				...prev,
				vendor: {
					...prev.vendor,
					filterBy: 'vendor_id',
					filterValue: Number(purchaseReceiveData?.vendor_id),
				},
			}));

			setPurchaseReceiveFormData((prev) => ({
				...prev,
				vendor_id: purchaseReceiveData?.vendor_id,
				purchase_order_number: purchaseReceiveData?.purchase_order?.purchase_order_number,
				delivery_address: purchaseReceiveData?.purchase_order?.delivery_address,
				receive_date: getDate(purchaseReceiveData?.receive_date),
				purchase_order_id: purchaseReceiveData?.purchase_order_id,
				purchase_receive_products:
					purchaseReceiveData.purchase_receive_products.length > 0
						? purchaseReceiveData.purchase_receive_products?.map((pop) => {
								return {
									...pop,
									product_name: pop.product.product_name,
									order_quantity: pop.order_quantity,
									received_quantity: pop.received_quantity || 0,
									is_in_transit: 0,
									qty: 0,
								};
							})
						: [],
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	useEffect(() => {
		if (id && id.includes('pr')) {
			handleFetchPurchaseReceiveDetails(id.split('-')[1]);
		}
	}, [id]);

	return (
		<div>
			<div className="">
				<div className="">
					<div className="product-pg-header add-product">
						<p>{id && id.includes('pr') ? 'Update' : 'Create New '} Purchase Receive</p>

						{id && id.includes('pr') ? (
							<div className="add-product-button">
								<Button
									type="button"
									className="btn-primary mt-0 px-4"
									button="Update"
									loader={loading?.status === 2 && loading.isLoading}
									onClick={() => {
										handleUpdatePurchaseReceive();
									}}
								/>
								<Button
									type="button"
									className="mt-0 btn-danger add-pro"
									button=""
									close
									onClick={() => navigateRouter(`/user/${suburl}/purchases-recieve`)}
								/>
							</div>
						) : (
							<div className="add-product-button">
								<Button
									type="button"
									className="btn-primary mt-0 px-4"
									button="Save as Draft"
									loader={loading?.status === 0 && loading.isLoading}
									onClick={() => {
										handleSavePurchaseReceive(0);
									}}
								/>
								<Button
									type="button"
									className="btn-primary mt-0 px-4"
									button="Save as Received"
									loader={loading?.status === 1 && loading.isLoading}
									onClick={() => handleSavePurchaseReceive(1)}
								/>
								<Button
									type="button"
									className="mt-0 btn-danger add-pro"
									button=""
									close
									onClick={() => navigateRouter(`/user/${suburl}/purchases-recieve`)}
								/>
							</div>
						)}
					</div>
				</div>
				{/*  Vendor Form */}
				<div
					className="max-w-1297"
					style={{ padding: '1rem 2rem 0' }}
				>
					<div className="row">
						<div className="col-6">
							<InputSelect
								options={inputSelectOption.vendorList}
								label="Vendor Name"
								placeholder="Vendor Name"
								required={true}
								returnInputValue={false}
								value={purchaseReceiveFormData?.vendor_id}
								onSelect={(option) => {
									setPurchaseReceiveFormData((prev) => ({
										...prev,
										vendor_id: option.key,
									}));

									setParams((prev) => ({
										...prev,
										vendor: {
											...prev.vendor,
											filterBy: 'vendor_id',
											filterValue: option.key,
										},
									}));
								}}
								onChange={(value) =>
									setParams((prev) => ({
										...prev,
										vendor: {
											...prev.vendor,
											filterBy: 'vendor_name',
											filterValue: value,
										},
									}))
								}
								// disabled={disabled}
								// error={error?.vendor_name}
								autoFocus={true}
							/>
						</div>
						<div className="col">
							<InputSelect
								options={inputSelectOption.purhcaseOrderIdList}
								label="Purchase Order#"
								placeholder="Order Number"
								required={true}
								returnInputValue={false}
								value={purchaseReceiveFormData?.purchase_order_id}
								onSelect={(option) => {
									setPurchaseReceiveFormData((prev) => ({
										...prev,
										purchase_order_id: option.key,
									}));
									setParams((prev) => ({
										...prev,
										purchaseOrder: {
											...prev.purchaseOrder,
											filterBy: 'purchase_order_id',
											filterValue: option.key,
										},
									}));
								}}
								onChange={(value) => {
									setParams((prev) => ({
										...prev,
										purchaseOrder: {
											...prev.purchaseOrder,
											filterBy: 'purchase_order_number',
											filterValue: value,
										},
									}));
								}}
								// disabled={disabled}
								// required={true}
								// error={error?.vendor_display_name}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<InputField
								label="Delivery Address"
								placeholder="Delivery Address"
								type="text"
								// required={true}
								value={purchaseReceiveFormData.delivery_address}
								// onChange={(value) => handleBasicInputChange('email', value)}
								// disabled={disabled}
								// error={error?.email}
							/>
						</div>

						<div className="col">
							<InputField
								label="Receive Date"
								placeholder="Receive  Date"
								type="date"
								value={purchaseReceiveFormData.receive_date}
								onChange={(val) =>
									setPurchaseReceiveFormData((prev) => ({
										...prev,
										receive_date: val,
									}))
								}
								// autoFocus={disabled ? false : true}
								// disabled={disabled}
								required={true}
								// error={error?.date}
							/>
						</div>
					</div>
				</div>
				{/* order Table */}
				<PurchaseReceiveTable
					tableData={purchaseReceiveFormData.purchase_receive_products}
					setTableData={(data) => {
						setPurchaseReceiveFormData((prev) => ({
							...prev,
							purchase_receive_products: data,
						}));
					}}
					disabled={false}
					paramId={null}
					tableError={TableError}
					setIsTableErrorShow={setIsTableError}
				/>
			</div>
		</div>
	);
};

export default CreatePurchaseReceive;
