
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortbydot from '../../../assets/image/sort_by_group.svg';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../component/ui/Button';
import Table from '../../../component/table/Table';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import Pagination from '../../../component/pagination';
import { toast } from 'react-toastify';
import { ExportTable } from '../../../store/action/export/Export';
// import { PaymentMadeColoum } from './PaymentmadeColoum';
// import DeletePaymentMadeModel from './components/DeletePaymentMadeModel';2

const Customers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const suburl = localStorage.getItem('suburl');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [vendorList, setVendorLiat] = useState([]);
  const [loader, setLoader] = useState(false);
  const [bulkAction, setBulkAction] = useState([]);
  const [filterparam, setFilterparam] = useState('');
  const [sortParam, setSortParam] = useState('');
  const [sortDirection, setSortDirection] = useState();
  const [stateCondition, setStateCondition] = useState('');
  const vendorallList = useSelector((state) => state?.vendorReducer?.vendorList?.data);
  // const [tableData, setTableData] = useState([]);
  const [headerDropDown, setHeaderDropDown] = useState('All Customers');
  const [isDeleteBulkConfirmationModalOpen, setIsDeleteBulkConfirmationModalOpen] = useState(false);
  const [customersParams, setCustomersParams] = useState({
    page: 1,
    pageSize: 20,
    orderBy: '',
    sortBy: 'asc',
    filterBy: null,
    filterValue: null,
  });


  const navigateRouter = (path) => {
    navigate(path);
  };
  const onRowClick = (id) => {
    if (id) {
      navigate(`/user/${suburl}/customers-details/${id}`);
    }
  };
  const FilterDatalist = (param, value, dropDownData) => {
    setStateCondition(value);
    setHeaderDropDown(dropDownData);
    setCustomersParams((prev) => ({
      ...prev,
      ...param,
    }));
  };

  const sortIconMap = {
    asc: <IoIosArrowRoundUp />,
    desc: <IoIosArrowRoundDown />,
  };

  const sortDataList = (param) => {
    const newDirection = sortDirection[param.orderBy] === 'desc' ? 'asc' : 'desc';
    setSortDirection({ [param.orderBy]: newDirection });
    setCustomersParams((prev) => ({
      ...prev,
      orderBy: param.orderBy,
      sortBy: newDirection,
      filterValue: null,
    }));
  };

  const handlePageChange = (page) => {
    setCustomersParams((prev) => ({
      ...prev,
      page,
    }));
  };

  const exportData = (param) => {
    ExportTable(param);
  };

  const tableData = [
    {
      id: 1,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 2,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 3,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 4,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 5,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 6,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 7,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 8,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 9,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 10,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 11,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 12,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },
    {
      id: 13,
      'CUSTOMERS NAME': 'Creative Concoctions',
      'COMPANY NAME': 'The Walt Disney Company',
      EMAIL: 'yourdomain@domainname.com',
      MOBILE: 1234567890,
      RECEIVABLES: '50,000',
      'UNUSED CREDITS': '50,000',
    },

  ];


  const CustomersColoum = [
    {
      field: '',
      headerClass: 'HeaderBg',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      minWidth: 50,
      maxWidth: 50,
      lockPosition: true,
    },
    {
      field: 'CUSTOMERS NAME',
      headerClass: 'HeaderBg',
      suppressSizeToFit: true,
      minWidth: 200,
      flex: 1,
      cellClass: 'left-align',
    },
    {
      field: 'COMPANY NAME',
      headerClass: 'HeaderBg',
      suppressSizeToFit: true,
      minWidth: 200,
      flex: 1,
      cellClass: 'left-align',
    },
    {
      field: 'EMAIL',
      headerClass: 'HeaderBg',
      suppressSizeToFit: true,
      minWidth: 100,
      flex: 1,
      cellClass: 'left-align',
    },
    {
      field: 'MOBILE',
      headerClass: 'HeaderBg right-align',
      suppressSizeToFit: true,
      minWidth: 50,
      flex: 1,
      cellClass: 'right-align',
    },
    {
      field: 'RECEIVABLES',
      headerClass: 'HeaderBg right-align',
      suppressSizeToFit: true,
      minWidth: 50,
      flex: 1,
      cellClass: 'right-align',
    },
    {
      field: 'UNUSED CREDITS',
      headerClass: 'HeaderBg right-align',
      suppressSizeToFit: true,
      minWidth: 100,
      flex: 1,
      cellClass: 'right-align',
    },
  ]

  return (
    <div>
      {bulkAction && bulkAction.length > 0 ? (
        <div className="product-pg-header">
          <div className="dropdown text-end">
            <p className="d-block link-dark text-decoration-none">Bulk Actions</p>
          </div>
          <div className="add-product-button">
            <Button
              type="button"
              button="Print"
              className="mt-0 px-4 btn-gray"
            // onClick={() => window.print()}
            />

            <Button
              type="button"
              button="Bulk Update"
              className="mt-0 px-4 btn-gray relative"
            ></Button>

            <Button
              type="button"
              button="Delete"
              className="mt-0 px-4 btn-gray"
            // onClick={() => handleUpdateBulkPurchaseOrder("is_deleted")}
            // onClick={handleOpenBulkDeletePaymentMadeModal}
            />

            <Button
              type="button"
              button=""
              className="mt-0 px-4 btn-danger"
              close
              onClick={() => setBulkAction(false)}
            />
          </div>
        </div>
      ) : (
        <div className="product-pg-header add-product">
          <div className="dropdown text-end">
            <a
              className="d-block link-dark text-decoration-none dropdown-toggle"
              id="dropdownUser1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <p>{headerDropDown}</p>
            </a>
            <ul
              className="dropdown-menu font-14"
              aria-labelledby="dropdownUser1"
              style={{ minWidth: '240px' }}
            >
              <li>
                <a
                  className="dropdown-item"
                  onClick={() =>
                    FilterDatalist('orderBy=product_code&sortBy=asc', '', ' Active Customers')
                  }
                >
                  Active Customers
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() =>
                    FilterDatalist('filterBy=status&filterValue=1', 'active', ' Duplicate Customers')
                  }
                >
                  Duplicate Customers
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => FilterDatalist('filterBy=crm&filterValue=0', 'crm', 'Inactive Customers')}
                >
                  Inactive Customers
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => FilterDatalist('filterBy=crm&filterValue=0', 'crm', ' OverDue Customers')}
                >
                  OverDue Customers
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => FilterDatalist('filterBy=crm&filterValue=0', 'crm', 'Unpaid Customers')}
                >
                  Unpaid Customers
                </a>
              </li>
            </ul>
          </div>

          <div className="add-product-button column-gap-0">
            <Button
              type="button"
              className="btn-primary mt-0 px-5"
              button="Create New Customer"
              add
              onClick={() => navigateRouter(`/user/${suburl}/customers-create`)}
            />
            <div className="dropdown text-end ms-3">
              <a
                href="#"
                className="d-block link-dark text-decoration-none"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={sortbydot} />
              </a>
              {/* date , reason, created_at , description,status,reference_number, */}
              <ul
                className="dropdown-menu font-14"
                aria-labelledby="dropdownUser1"
                style={{ minWidth: '240px' }}
              >
                <li>
                  <a className="dropdown-item sortBy">Sort By</a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => sortDataList(`orderBy=vendor_name&sortBy=asc`)}
                  >
                    Name
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => sortDataList(`orderBy=vendor_display_name&sortBy=asc`)}
                  >
                    Company Name
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => sortDataList(`orderBy=reason&sortBy=asc`)}
                  >
                    Email
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => sortDataList(`orderBy=created_at&sortBy=asc`)}
                  >
                    Receivables
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => sortDataList(`orderBy=description&sortBy=asc`)}
                  >
                    Unused Credits
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => sortDataList(`orderBy=description&sortBy=asc`)}
                  >
                    Created Time
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => sortDataList(`orderBy=description&sortBy=asc`)}
                  >
                    Last Modify Time
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '100%', marginTop: '20%' }}
        >
          <span
            className="Page_loader"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      ) : (
        <div className="productTable-body">
          <Table
            row={tableData}
            coloum={CustomersColoum}
            className="productTable"
            onRowClick={onRowClick}
            action={setBulkAction}
          />
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onChangePage={handlePageChange}
      />

    </div>
  );
};

export default Customers;