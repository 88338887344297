import React, { useEffect, useState } from 'react'
import Button from '../../../../component/ui/Button'
import Table from '../../../../component/table/Table'
import Modal from '../../../../component/modal/Modal'
import { convertToBillColumn } from '../../purchaseOrdereColoum';
import { getDate } from '../../../../utils/dateUtils';

function ConvertToBillModal({ purchaseOrderDetails, isConvertToBillOpen, setIsConvertToBillOpen, }) {
  const [isReceived, setIsReceived] = useState('yetToReceive')
  const [tableData, setTableData] = useState({
    yetToReceiveTableData: [],
    receivedTableData: []
  })

  const ConvertToBill = [
    {
      'PURCHASE ORDER#': 'PO-0012',
      'ORDER DATE': '29/03/2024',
      STATUS: 'Issued',
    },
  ];

  const handleChange = (e) => {
    setIsReceived(e.target.value);
  };

  function handleSetTableData() {
    const filteredTableData = purchaseOrderDetails.purchase_receive.reduce((acc, pr) => {
      if (pr.status === 1) {
        acc.receivedTableData.push({
          'PURCHASE ORDER#': pr.purchase_order_number,
          'ORDER DATE': getDate(pr.date),
          STATUS: 'Issued',
        },)
      }
    }, {
      yetToReceiveTableData: [],
      receivedTableData: []
    })
  }

  useEffect(() => {
    handleSetTableData()
  }, [purchaseOrderDetails])

  return (
    <Modal
      className="convertToBillModal"
      isOpen={isConvertToBillOpen}
      onClose={setIsConvertToBillOpen}
      cancelButton={setIsConvertToBillOpen}
      title={
        <div
          style={{
            fontWeight: '600',
            color: '#000000',
            fontSize: '22px',
          }}
        >
          Purchase Order ({purchaseOrderDetails?.purchase_order_number})
        </div>
      }
      headerClassName="custom-header"
      bodyClassName="convertToBillBody"
    >
      <div style={{ backgroundColor: '#E6F5FF', height: '36px', marginBottom: '36px' }}>
        <p
          style={{
            fontSize: '18px',
            color: '#555B67',
            paddingLeft: '10px',
            paddingTop: '3px',
            marginTop: '8px',
          }}
        >
          Note: You can create a bill for either Yet to Receive items or Received Items.
        </p>
      </div>

      <div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              id="yetToReceive"
              name="status"
              value="yetToReceive"
              checked={isReceived === "yetToReceive"}
              onChange={handleChange}
              style={{ marginBottom: '21px', width: '16px', height: '23px' }}
            />
            <label
              htmlFor="yetToReceive"
              style={{ marginLeft: 5 }}
            >
              <div style={{ margin: 0, color: '#555B67', fontSize: '16px' }}>
                Yet to Receive
              </div>
              <div style={{ margin: 0, color: '#9FA6B5', fontSize: '16px' }}>
                Yet to receive product in this PO
              </div>
            </label>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '104px' }}>
            <input
              type="radio"
              id="received"
              name="status"
              value="received"
              checked={isReceived === "received"}
              onChange={handleChange}
              style={{ marginBottom: '19px', width: '16px', height: '23px' }}
            />
            <label
              htmlFor="received"
              style={{ marginLeft: 5 }}
            >
              <div style={{ margin: 0, color: '#555B67', fontSize: '16px' }}>Received</div>
              <div style={{ margin: 0, color: '#9FA6B5', fontSize: '16px' }}>
                Received product in this PO
              </div>
            </label>
          </div>
        </div>

        <div style={styles.tableContainer}>
          <Table
            coloum={convertToBillColumn}
            row={ConvertToBill}
            onRowClick={() => { }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            gap: 4,
            marginTop: '60px',
          }}
        >
          <Button
            type="button"
            className="mt-0 btn-gray add-pro "
            button="Cancel"
          // onClick={() => s(false)}
          />
          <Button
            type="button"
            className="mt-0 btn-primary add-pro"
            button="Convert To Bill"
          // onClick={handleDeletePurchaseOrder}
          />
        </div>
      </div>
    </Modal>
  )
}


const styles = {
  tableContainer: {
    marginTop: '20px',
    maxHeight: '85px',
    overflowY: 'hidden',
    width: '626px',
  },
};

export default ConvertToBillModal

