// import React, { useState } from 'react'
// import NonPdfView from '../NonPdfView'
// import PdfView from '../PdfViewPurchaseBill'
// import "./index.scss"

// function PurchaseBillInvoice({ isShowPdfView,
//   setIsShowPdfView, printPdfRef, handleProductDetails, handleDiscardQuantity, purchaseOrderDetails }) {


//   return (
//     <div style={{
//       width: "60%",
//       margin: "0px auto",
//       padding: "30px 0px"
//     }}>

//       <div className="status-toggle"
//       >
//         <div style={{
//           display: "flex",
//           alignItems: "center",
//           gap: 10
//         }}>
//           <div>
//             <p>Show PDF View</p>
//           </div>
//           <div className="form-check form-switch">
//             <input
//               className="form-check-input"
//               type="checkbox"
//               role="switch"
//               id="flexSwitchCheckChecked"
//               checked={isShowPdfView}
//               onChange={() => setIsShowPdfView(!isShowPdfView)}
//             />
//           </div>

//         </div>

//       </div>


//       <div>
//         {
//           isShowPdfView
//             ? <PdfView printPdfRef={printPdfRef} purchaseOrderDetails={purchaseOrderDetails} />
//             : <NonPdfView purchaseOrderDetails={purchaseOrderDetails} handleProductDetails={handleProductDetails} handleDiscardQuantity={handleDiscardQuantity} />
//         }
//       </div>

//     </div >
//   )
// }

// export default PurchaseBillInvoice;



import React, { useState, useEffect } from 'react';
import "./index.scss"

const Invoice = () => {
  const defaultData = {
    storeName: "Medical Store",
    address: "Rajasthan",
    country: "India",
    email: "aditbhargava.devoy@gmail.com",
    billNumber: "ABCD",
    billDate: ":INV-000010",
    dueDate: ":19/03/2024",
    terms: ":Net 0",
    balanceDue: "Rs. 0.00",
    customerName: "ABC Company Private Limited",
    items: [
      {
        id: 1,
        description: "Product A",
        customerDetails: "Lorem Ipsum",
        notes: "Non Billable",
        quantity: "1.00 ml",
        rate: 600.0,
        amount: 600.0,
      },
    ],
    subTotal: 600.0,
    total: 600.0,
    paymentMade: -320.0,
    balance: 600.0,
  };

  // State to store the invoice data
  const [invoiceData, setInvoiceData] = useState(defaultData);

  // Simulate API call
  useEffect(() => {

    const fetchData = async () => {

      const apiData = await new Promise((resolve) =>
        setTimeout(() => resolve(defaultData), 1000)
      );


      setInvoiceData(apiData);
    };

    fetchData();
  }, []);

  return (
    <div className="invoice-container" style={{ marginTop: '45px' }}>
      {/* Header Section */}
      <div className="invoice-header">
        <div>
          <h2 style={{ fontSize: "22px", fontWeight: 'bold', color: '#0F1C35' }}>{invoiceData.storeName}</h2>
          <div style={{ marginTop: "15px", color: "#555B67" }}>
            <p>{invoiceData.address}</p>
            <p>{invoiceData.country}</p>
            <p>{invoiceData.email}</p>
          </div>
        </div>
        <div className="bill-status">
          <h2 style={{ fontSize: '30px', fontWeight: 'bold' }}>BILL</h2>
          <p style={{ marginTop: '10px', color: '#555B67' }}>Bill# {invoiceData.billNumber}</p>
        </div>
      </div>

      {/* Invoice Details */}
      <div className="invoice-details">
        <div style={{ marginTop: '18px', color: '#555B67', fontSize: '14px' }}>
          <p>Bill Date: </p>
          <p>Due Date: </p>
          <p>Terms:</p>
        </div>
        <div style={{ marginRight: '148px', marginTop: '19px', fontWeight: 'bold', fontSize: '14px', color: '#000000' }}>
          <p>{invoiceData.billDate}</p>
          <p>{invoiceData.dueDate}</p>
          <p>{invoiceData.terms}</p>
        </div>
        <div style={{ marginTop: '18px' }}>
          <p style={{ fontSize: '14px', color: '#555B67' }}>Balance Due:</p>
          <h2 style={{ fontSize: '18px', fontWeight: 'bold', color: '#000000', textAlign: 'right' }}>{invoiceData.balanceDue}</h2>
        </div>
      </div>

      {/* Bill To Section */}
      <div className="bill-to" style={{ border: '1px solid #ddd', padding: '13px' }}>
        <p style={{ color: '#0F1C35', fontSize: '16px', fontWeight: '600' }}>Bill To:</p>
        <h3 style={{ fontWeight: 'bold', color: '#3B58FF', fontSize: '14px' }}>{invoiceData.customerName}</h3>
      </div>

      {/* Items Table */}
      <table className="invoice-table">
        <thead>
          <tr>
            <th>#</th>
            <th>ITEM & DESCRIPTION</th>
            <th>CUSTOMER DETAILS</th>
            <th>QNTY</th>
            <th>RATE</th>
            <th>AMOUNT</th>
          </tr>
        </thead>
        <tbody>
          {invoiceData.items.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td><strong>{item.description}</strong></td>
              <td>
                {item.customerDetails}
                <br />
                <small>{item.notes}</small>
              </td>
              <td>{item.quantity}</td>
              <td>{item.rate.toFixed(2)}</td>
              <td>{item.amount.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Total Section */}
      <div className="total-section">
        <div></div>
        <div className="totals" style={{ fontSize: '14px', fontWeight: 'bold', color: '#555B67', marginLeft: '380px', textAlign: 'right' }}>
          <p>Sub Total: </p>
          <p>Total: </p>
          <p>Payment Made:</p>
          <p>Balance Due:</p>
        </div>
        <div style={{ color: '#555B67', textAlign: 'right' }}>
          <p>{invoiceData.subTotal.toFixed(2)}</p>
          <p><b>Rs.</b> {invoiceData.total.toFixed(2)}</p>
          <p> <span className="negative">(-) {Math.abs(invoiceData.paymentMade).toFixed(2)}</span></p>
          <p><b>Rs.</b> {invoiceData.balance.toFixed(2)}</p>
        </div>
      </div>

      {/* Footer */}
      <div className="footer">
        <p>Authorized Signature: __________________________</p>
      </div>
    </div>
  );
};

export default Invoice;
