import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortbydot from '../../assets/image/sort_by_group.svg';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Pagination from '../../component/pagination';
import { change_status, vendor_list } from '../../store/action/vendor';
import { toast } from 'react-toastify';
import { ExportTable } from '../../store/action/export/Export';
import { PurchaseBillColoum } from './purchaseBillsColoum';
import './CreatePurchaseBill.scss';
import DeletePurchaseBillModel from './components/DeletePurchaseBillModel';
import { fetchPurchaseBill } from '../../services/purchase_bill';
import { getDate } from '../../utils/dateUtils';
import pdfMake, { fonts } from 'pdfmake/build/pdfmake';

const PurchaseBills = () => {
	const navigate = useNavigate();
	const suburl = localStorage.getItem('suburl');
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [purchaseBill, setPurchaseBills] = useState({
		currentPage: 1,
		totalPages: 0,
		data: [],
	});
	const [loader, setLoader] = useState(false);
	const [bulkAction, setBulkAction] = useState([]);
	const [filterparam, setFilterparam] = useState('');
	const [sortParam, setSortParam] = useState('');
	const vendorallList = useSelector((state) => state?.vendorReducer?.vendorList?.data);
	const [tableData, setTableData] = useState([]);
	const [headerDropDown, setHeaderDropDown] = useState('All Purchase Bills');
	const [sortDirection, setSortDirection] = useState({ purchase_bill_id: 'asc' });
	const [stateCondition, setStateCondition] = useState('');
	const [isDeleteBulkConfirmationModalOpen, setIsDeleteBulkConfirmationModalOpen] = useState(false);
	const [selectedVendorsListForDelete, setSelectedVendorsListForDelete] = useState([]);
	const [purchaseBillParams, setPurchaseBillParams] = useState({
		page: 1,
		pageSize: 20,
		orderBy: 'purchase_bill_id',
		sortBy: 'asc',
		filterBy: null,
		filterValue: null,
	});
	const [loading, setLoading] = useState({
		pdf: {
			button: 'pdf',
			isLoading: false,
		},
		download: {
			button: 'download',
			isLoading: false,
		},
	});

	const navigateRouter = (path) => {
		navigate(path);
	};
	const onRowClick = (id) => {
		if (id) {
			navigate(`/user/${suburl}/purchases-bills-details/${id}`);
		}
	};

	// const getAllList = (param, value) => {
	// 	if (!param) {
	// 		param = `page=${currentPage}&pageSize=20`;
	// 	}
	// 	setLoader(true);
	// 	dispatch(fetchPurchaseBill(param))
	// 		.then((res) => {
	// 			console.log(res);
	// 			if (res?.success) {
	// 				setVendorLiat(res?.data);
	// 				setLoader(false);
	// 			} else {
	// 				setVendorLiat([]);
	// 				setLoader(false);
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			setVendorLiat([]);
	// 			setLoader(false);
	// 		});
	// };

	async function handleFetchPurchaseBill() {
		try {
			setLoader(true);
			const { purchaseBills, totalPages } = await fetchPurchaseBill(purchaseBillParams);
			console.log(purchaseBills);
			console.log(totalPages);
			const tableData = purchaseBills
				? purchaseBills.map((pr) => {
						return {
							id: pr.purchase_receive_id,
							DATE: getDate(pr.receive_date),
							PR: JSON.stringify({ pr: pr?.purchase_receive_id, status: pr?.status }),
							'PO#': pr.purchase_order.purchase_order_number,
							'VENDOR NAME': pr.vendor.vendor_name,
							STATUS: pr?.status,
							RECEIVED: pr?.status,
							BILLED: pr?.status,
						};
					})
				: [];
			setPurchaseBills((prev) => ({
				...prev,
				data: tableData,
				totalPages,
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		} finally {
			setLoader(false);
		}
	}

	const FilterDatalist = (param, value, dropDownData) => {
		setFilterparam(param);
		setStateCondition(value);
		setHeaderDropDown(dropDownData);
		setSortParam('');
		setCurrentPage(1);
	};

	// const sortDataList = (param) => {
	// 	setSortParam(param);
	// 	setCurrentPage(1);
	// };
	const sortDataList = (param) => {
		const newDirection = sortDirection[param.orderBy] === 'desc' ? 'asc' : 'desc';
		setSortDirection({ [param.orderBy]: newDirection });
		setPurchaseBillParams((prev) => ({
			...prev,
			orderBy: param.orderBy,
			sortBy: newDirection,
			filterValue: null,
		}));
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		handleFetchPurchaseBill();
	}, [purchaseBillParams]);

	async function generatePdfToPrintAndDownload() {
		const filterValue = bulkAction.join(',');
		const { purchaseBill } = await fetchPurchaseBill({
			filterBy: 'purchase_bill_id',
			filterValue,
		});
		const docDefinitions = [];
		purchaseBill.forEach((pr) => {
			const {
				is_received,
				is_billed,
				vendor,
				bill_number,
				bill_date,
				due_date,
				terms,
				subtotal,
				total,
				balance_due,
				payment_mode,
				purchase_order_products,
			} = pr?.purchase_order;
			docDefinitions.push({
				content: [
					{
						columns: [
							{
								text: `Received Status: ${is_received ? 'Received' : 'Not Received'}`,
								style: 'status',
								color: is_received ? '#6AA247' : 'red',
							},
							{ text: '|' },
							{
								text: `Bill Status: ${is_billed ? 'Billed' : 'Pending'}`,
								style: 'status',
								color: is_billed ? '#6AA247' : 'red',
							},
						],
						margin: [0, 0, 0, 10],
					},
					{
						text: vendor?.vendor_name,
						style: 'vendorName',
						margin: [0, 20, 0, 5],
					},
					{
						columns: [
							{
								stack: [
									{
										text: [
											vendor?.address,
											'\nRajasthan\nIndia\n',
											{ text: `${vendor?.phone_mobile} | ${vendor?.email}`, bold: true },
										],
										style: 'vendorAddress',
									},
								],
							},
							{
								stack: [
									{ text: 'BILL', style: 'header', bold: true },
									{ text: `#${bill_number}`, style: 'billNumber' },
								],
								margin: [0, 20, 0, 10],
							},
						],
					},
					{
						columns: [
							{
								stack: [
									{ text: `Bill Date. : ${bill_date || 'N/A'}`, style: 'detail' },
									{
										text: `Due Date : ${due_date || 'N/A'}`,
										style: 'detail',
									},
									{
										text: `Terms : ${terms || 'N/A'}`,
										style: 'detail',
									},
								],
							},
							{
								stack: [
									{ text: 'Balance Due', style: 'label' },
									{ text: `Rs ${balance_due}`, bold: true },
								],
							},
						],
						columnGap: 100,
					},
					{
						text: [
							{ text: 'Bill To', style: 'label' },
							{ text: `${vendor?.company_name}`, style: 'linkStyle' },
						],
						margin: [10, 0, 10, 10],
					},
					{
						table: {
							widths: ['5%', '35%', '30%', '10%', '10%', '10%'],
							body: [
								[
									{ text: '#', bold: true },
									{ text: 'ITEMS AND DESCRIPTION', bold: true },
									{ text: 'CUSTOMER DETAILS', bold: true },
									{ text: 'QNTY', bold: true },
									{ text: 'RATE', bold: true },
									{ text: 'AMOUNT', bold: true },
								],
								// ...purchase_order_products.map((prod, index) => [
								// 	{ text: index + 1 },
								// 	{ text: prod.product.product_name, color: 'blue' },
								// 	{ text: prod.product_packaging },
								// 	{ text: prod.order_quantity },
								// ]),
							],
						},
						margin: [0, 20, 0, 10],
					},
					{
						columns: [
							{
								stack: [
									{ text: `Sub Total  ${subtotal || 'N/A'}`, style: 'detail' },
									{
										text: ` Total: ${total || 'N/A'}`,
										style: 'detail',
									},
									{
										text: `Payment Made ${payment_mode || 'N/A'}`,
										style: 'detail',
									},
									{
										text: `Balance Due ${balance_due || 'N/A'}`,
										style: 'detail',
									},
								],
							},
						],
					},
					{
						text: 'Authorized Signature --------------------',
						margin: [0, 30, 0, 0],
						alignment: 'left',
					},
				],
				styles: {
					status: {
						fontSize: 10,
						margin: [0, 0, 0, 5],
					},
					linkStyle: {
						fontSize: 12,
						color: '#3B58FF',
					},
					header: {
						fontSize: 15,
						margin: [0, 10, 10, 0],
					},
					vendorName: {
						fontSize: 16,
						bold: true,
					},
					vendorAddress: {
						fontSize: 12,
					},
					subheader: {
						fontSize: 14,
						bold: true,
					},
					orderNumber: {
						fontSize: 12,
						margin: [0, 5, 0, 5],
					},
					orderDate: {
						fontSize: 12,
					},
					label: {
						fontSize: 15,
						bold: true,
						margin: [0, 5, 0, 5],
					},
					detail: {
						fontSize: 10,
						margin: [0, 2, 0, 2],
					},
				},
			});
		});

		let mergedContent = [];
		docDefinitions.forEach((doc) => {
			mergedContent = mergedContent.concat(doc.content); // Concatenate all content
			// Optionally, you can add a page break between the documents
			mergedContent.push({ text: '', pageBreak: 'after' });
		});

		// Create the final merged document definition
		const mergedDocDefinition = {
			content: mergedContent,
			styles: {
				header: { fontSize: 16, bold: true },
				subheader: { fontSize: 12 },
			},
		};

		return mergedDocDefinition;
	}

	async function handlePrintAndDownloadPdf(isPrint) {
		setLoading((prev) => {
			const obj = {};
			if (isPrint) {
				obj.pdf = {
					button: 'pdf',
					isLoading: true,
				};
			} else {
				obj.download = {
					button: 'download',
					isLoading: true,
				};
			}

			return {
				...prev,
				...obj,
			};
		});
		const mergedDocDefinition = await generatePdfToPrintAndDownload();
		if (isPrint) {
			pdfMake.createPdf(mergedDocDefinition).print();
		} else {
			pdfMake.createPdf(mergedDocDefinition).download();
		}

		setLoading((prev) => ({
			...prev,
			pdf: {
				...prev.pdf,
				isLoading: false,
			},
			download: {
				...prev.download,
				isLoading: false,
			},
		}));
	}

	const exportData = (param) => {
		ExportTable(param);
	};

	async function handleBulkDelete() {
		try {
			// 	await deleteVendorService(bulkAction);
			toast.success('Purchase Bill Deleted Successfully.');
			setIsDeleteBulkConfirmationModalOpen(false);
			setBulkAction([]);
			handleFetchPurchaseBill();
		} catch (error) {
			toast.error(error?.message || 'Something went wrong');
		}
	}

	function handleOpenBulkDeletePurchaseBillModal() {
		const selectedVendorsList = tableData?.reduce((acc, item) => {
			if (bulkAction.includes(item.id)) {
				acc.push(item.NAME);
				return acc;
			}
			return acc;
		}, []);
		setSelectedVendorsListForDelete(selectedVendorsList);
		setIsDeleteBulkConfirmationModalOpen(true);
	}

	return (
		<div>
			{bulkAction && bulkAction.length > 0 ? (
				<div className="product-pg-header">
					<div className="dropdown text-end">
						<p className="d-block link-dark text-decoration-none">Bulk Actions</p>
					</div>
					<div className="add-product-button">
						<Button
							type="button"
							button="Link to Purchase Order"
							className="mt-0 px-4 ButtonColor"
							// onClick={() => window.print()}
						/>

						<Button
							type="button"
							button="Print"
							className="mt-0 px-4 btn-gray"
							loader={loading.pdf.isLoading}
							onClick={() => handlePrintAndDownloadPdf(true)}
						/>

						<Button
							type="button"
							button="Delete"
							className="mt-0 px-4 btn-gray"
							onClick={handleOpenBulkDeletePurchaseBillModal}
						/>

						<Button
							type="button"
							button="Record Bulk Payment"
							className="mt-0 px-4 ButtonColor"
							// onClick={() => window.print()}
						/>

						<Button
							type="button"
							button="Bulk Update"
							className="mt-0 px-4  relative ButtonColor"
						></Button>
						{/* <div
							className="dropdown-menu show font-14 mt-2"
							style={{
								width: '250px',
								position: 'absolute',
								zIndex: 1000,
								maxHeight: '300px',
								overflowY: 'auto',
							}}
						>
							{bulkUpdateList?.map((result, index) => (
								<div
									key={result.id}
									className={`dropdown-item mt-1`}
									onClick={() => {
										if (result.label === 'Currency') {
											setCurrencyModal(true);
										} else {
											setOpenPaymentTermsModal(true);
										}
									}}
								>
									{result.label}
								</div>
							))}
						</div> */}

						{/* {stateCondition !== 'active' && (
							<Button
								type="button"
								button="Mark as Active"
								className="mt-0 px-4 btn-gray"
								onClick={markAsActive}
							/>
						)}
						{stateCondition !== 'inactive' && (
							<Button
								type="button"
								button="Mark as Inactive"
								className="mt-0 px-4 btn-gray"
								onClick={markAsinactive}
							/>
						)} */}
					</div>
				</div>
			) : (
				<div className="product-pg-header add-product">
					<div className="dropdown text-end">
						<a
							className="d-block link-dark text-decoration-none dropdown-toggle"
							id="dropdownUser1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<p>{headerDropDown}</p>
						</a>
						<ul
							className="dropdown-menu font-14"
							aria-labelledby="dropdownUser1"
							style={{ minWidth: '240px' }}
						>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist('orderBy=product_code&sortBy=asc', '', 'All Purchase Bills')
									}
								>
									All Purchase Bills
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist('filterBy=status&filterValue=1', 'active', 'In-Trnasit')
									}
								>
									In-Trnasit
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() => FilterDatalist('filterBy=crm&filterValue=0', 'crm', 'Received')}
								>
									Received
								</a>
							</li>
						</ul>
					</div>

					<div className="add-product-button column-gap-0">
						<Button
							type="button"
							className="btn-primary mt-0 px-5"
							button="Create New Purchase Bill"
							add
							onClick={() => navigateRouter(`/user/${suburl}/purchases-bills-create`)}
						/>
						<div className="dropdown text-end ms-3">
							<a
								href="#"
								className="d-block link-dark text-decoration-none"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img src={sortbydot} />
							</a>
							{/* date , reason, created_at , description,status,reference_number, */}
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li>
									<a className="dropdown-item sortBy">Sort By</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'created_time', sortBy: 'asc' })}
									>
										Created Time
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'last_modified_time', sortBy: 'asc' })}
									>
										Last Modified time
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'date', sortBy: 'asc' })}
									>
										Date
									</a>
								</li>

								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_bill_id', sortBy: 'asc' })}
									>
										Bill #
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'vendor_name', sortBy: 'asc' })}
									>
										Vendor Name
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'due_date', sortBy: 'asc' })}
									>
										Due Date
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'amount', sortBy: 'asc' })}
									>
										Amount
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'balance_due', sortBy: 'asc' })}
									>
										Balance Due
									</a>
								</li>
								{/* <li>
									<a
										className="dropdown-item"
										onClick={() => exportData(`vendor`)}
									>
										Export purchase bill
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_bill_id', sortBy: 'asc' })}
									>
										Refresh
									</a>
								</li> */}
							</ul>
						</div>
					</div>
				</div>
			)}

			{isDeleteBulkConfirmationModalOpen && (
				<DeletePurchaseBillModel
					isDeleteBulkConfirmationModalOpen={isDeleteBulkConfirmationModalOpen}
					setIsDeleteBulkConfirmationModalOpen={setIsDeleteBulkConfirmationModalOpen}
					selectedVendorsListForDelete={selectedVendorsListForDelete}
					handleBulkDelete={handleBulkDelete}
					purchaseBillLength={bulkAction.length}
				/>
			)}

			{loader ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: '100%', marginTop: '20%' }}
				>
					<span
						className="Page_loader"
						role="status"
						aria-hidden="true"
					></span>
				</div>
			) : (
				<div className="productTable-body">
					<Table
						row={tableData}
						coloum={PurchaseBillColoum}
						className="productTable"
						onRowClick={onRowClick}
						action={setBulkAction}
					/>
				</div>
			)}
			<Pagination
				currentPage={currentPage}
				totalPages={totalPages}
				onChangePage={handlePageChange}
			/>

			{/* {openPaymentTermsModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openPaymentTermsModal}
						onClose={() => setOpenPaymentTermsModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<AddPaymentTerms
							isOpen={openPaymentTermsModal}
							onCLose={() => setOpenPaymentTermsModal(false)}
							handleSaveClick={handleBulkPaymentTermsUpdate}
						/>
					</Modal>
				</div>
			)} */}
			{/* {openCurrencyModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openCurrencyModal}
						onClose={() => setCurrencyModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<UpdateCurrency
							isOpen={openCurrencyModal}
							onCLose={() => setCurrencyModal(false)}
							handleSaveClick={handleBulkCurrencyUpdate}
						/>
					</Modal>
				</div>
			)} */}
		</div>
	);
};

export default PurchaseBills;
