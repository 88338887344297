import axios from 'axios';
import { APP_API_URL } from '../../utils/xhr-instance';

export const VENDOR_LIST_DONE = 'VENDOR_LIST_DONE';
export const VENDOR_LIST_FAIL = 'VENDOR_LIST_FAIL';

export const VENDOR_BANK_DETAILS_CREATE_DONE = 'VENDOR_BANK_DETAILS_CREATE_DONE';
export const VENDOR_BANK_DETAILS_CREATE_FAIL = 'VENDOR_BANK_DETAILS_CREATE_FAIL';

export const VENDOR_BANK_DETAILS_GET_DONE = 'VENDOR_BANK_DETAILS_GET_DONE';
export const VENDOR_BANK_DETAILS_GET_FAIL = 'VENDOR_BANK_DETAILS_GET_FAIL';

export const VENDOR_BANK_DETAILS_DELETE_DONE = 'VENDOR_BANK_DETAILS_DELETE_DONE';
export const VENDOR_BANK_DETAILS_DELETE_FAIL = 'VENDOR_BANK_DETAILS_DELETE_FAIL';

export const VENDOR_BANK_DETAILS_UPDATE_DONE = 'VENDOR_BANK_DETAILS_UPDATE_DONE';
export const VENDOR_BANK_DETAILS_UPDATE_FAIL = 'VENDOR_BANK_DETAILS_UPDATE_FAIL';

// CONTACT PERSON
export const VENDOR_CONTACT_PERSON_CREATE_DONE = 'VENDOR_CONTACT_PERSON_CREATE_DONE';
export const VENDOR_CONTACT_PERSON_CREATE_FAIL = 'VENDOR_BANK_DETAILS_CREATE_FAIL';

export const VENDOR_CONTACT_PERSON_GET_DONE = 'VENDOR_CONTACT_PERSON_GET_DONE';
export const VENDOR_CONTACT_PERSON_GET_FAIL = 'VENDOR_BANK_DETAILS_GET_FAIL';

export const VENDOR_CONTACT_PERSON_DELETE_DONE = 'VENDOR_CONTACT_PERSON_DELETE_DONE';
export const VENDOR_CONTACT_PERSON_DELETE_FAIL = 'VENDOR_BANK_DETAILS_DELETE_FAIL';

export const VENDOR_CONTACT_PERSON_UPDATE_DONE = 'VENDOR_CONTACT_PERSON_UPDATE_DONE';
export const VENDOR_CONTACT_PERSON_UPDATE_FAIL = 'VENDOR_CONTACT_PERSON_UPDATE_FAIL';

export const CHANGE_STATUS_DONE = 'CHANGE_STATUS_DONE';
export const CHANGE_STATUS_FAIL = 'CHANGE_STATUS_FAIL';

// END POINTS
export const CREATE_VENDOR_API_URL = `${APP_API_URL}/vendor/create-vendor`;
export const UPDATE_VENDOR_API_URL = `${APP_API_URL}/vendor/update-vendor`;
export const GET_PAYMENT_TERMS_MASTER = `${APP_API_URL}/vendor/get-payment-terms-master`;
export const GET_VENDOR_LIST = `${APP_API_URL}/vendor/get-vendor`;

export const VENDOR_CONTACT_PERSON_API_URL = `${APP_API_URL}/vendor/vendor-contact-person`;
export const VENDOR_BANK_DETAILS_API_URL = `${APP_API_URL}/vendor/vendor-bank-details`;
export const VENDOR_PAYMET_TERM_CREATE_API_URL = `${APP_API_URL}/vendor/create-vendor`;
export const CHANGE_STATUS_API_URL = `${APP_API_URL}/vendor/update-status`;
export const CREATE_PAYMENT_TERMS_API_URL = `${APP_API_URL}/vendor/create-payment-terms`;
export const GET_PAYMENT_TERMS = `${APP_API_URL}/vendor/get-payment-terms`;
export const UPDATE_CURRENCY_OR_PAYMENT_TERMS = `${APP_API_URL}/vendor/update-currency-payment-terms`;
export const REUPLOAD_VENDOR_DOCUMENT = `${APP_API_URL}/vendor/reupload-document`;
export const UPLOAD_VENDOR_DOCUMENT = `${APP_API_URL}/vendor/create-document`;

export async function fetchVendors(params) {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axios.get(`${APP_API_URL}/vendor/get-vendor`, {
			params,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});

		return data.data;
	} catch (error) {
		throw error;
	}
}

export async function deleteVendorService(vendor_ids) {
	const token = localStorage.getItem('authToken');
	try {
		await axios.post(
			`${APP_API_URL}/vendor/delete-vendor`,
			{
				vendor_ids,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		);
	} catch (error) {
		throw error;
	}
}

export const changeIsActiveStatus = async (body) => {
	try {
		let API_URL = CHANGE_STATUS_API_URL;
		const token = localStorage.getItem('authToken');
		const { data } = await axios.put(API_URL, body, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		return data?.data;
	} catch (error) {
		throw error;
	}
};

export const fetchPaymentTerm = async (params) => {
	try {
		const API_URL = GET_PAYMENT_TERMS;
		const token = localStorage.getItem('authToken');
		const { data } = await axios.get(API_URL, {
			params,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		return data?.data;
	} catch (error) {
		throw error;
	}
};

export async function createPaymentTerm(paymentTerm) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axios.post(
			`${APP_API_URL}/vendor/create-payment-terms`,
			{ term_name: paymentTerm },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data?.data;
	} catch (error) {
		throw error;
	}
}

export async function deleteBankDetails(vendor_id, bank_details_id) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axios.delete(`${APP_API_URL}/vendor/vendor-bank-details/${vendor_id}`, {
			data: { bank_details_id },
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		return data;
	} catch (error) {
		throw error;
	}
}

export async function deleteVendorDocument(vendorDocumentId, docName) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axios.delete(
			`${APP_API_URL}/vendor/delete-document/${vendorDocumentId}?documentKey=${docName}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export async function addVendorContactPerson(body, vendor_id) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axios.post(
			`${APP_API_URL}/vendor/vendor-contact-person/${vendor_id}`,
			body,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export async function addNewVendor(body) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axios.post(`${APP_API_URL}/vendor//create-vendor`, body, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
}
