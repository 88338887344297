import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortbydot from '../../assets/image/sort_by_group.svg';
import advanceFilterIcon from '../../assets/image/advanceFilter.svg';
import clearAllFilter from '../../assets/image/clearAllFilter.svg';
import addFilter from '../../assets/image/AddFilter.svg';
import { useDispatch } from 'react-redux';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Pagination from '../../component/pagination';
// import { vendorColoum } from './VendorsColoum';
import { bulkUpdateCurrencyOrPaymentTerms } from '../../store/action/vendor';
import { toast } from 'react-toastify';
import { ExportTable } from '../../store/action/export/Export';
import Modal from '../../component/modal/Modal';
import UpdateCurrency from './UpdateCurrecny';
import { changeIsActiveStatus, deleteVendorService, fetchVendors } from '../../services/vendor';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import BulkPaymentTerms from './BulkPaymentTerm';
import axios from 'axios';
import { APP_API_URL } from '../../utils/xhr-instance';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import './Vendors.scss';
import AdvanceFilter from '../../component/Filter/AdvanceFilter';
import advanceFilterOptions from '../../component/Filter/advanceFilterOptions';
import AdvanceFilterSelectInput from '../../component/Filter/AdvanceFilterSelectInput';
import VendorTable from "./components/VendorTable"
import DeleteVendorModal from './components/DeleVendorModal';
import VendorAdvanceFilter from './components/VendorAdvanceFilter';
import VendorCurrencyModal from './components/VendorCurrencyModal';
import VendorBulkPaymentTermModal from './components/VendorBulkPaymentTermModal';

const Vendors = () => {
	const suburl = localStorage.getItem('suburl');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [totalPages, setTotalPages] = useState(0);
	const [loader, setLoader] = useState(false);
	const [bulkAction, setBulkAction] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [headerDropDown, setHeaderDropDown] = useState('All Vendor');
	const [openPaymentTermsModal, setOpenPaymentTermsModal] = useState(false);
	const [openCurrencyModal, setCurrencyModal] = useState(false);
	const [showBulkOptions, setShowBulkOptions] = useState(false);
	const [sortDirection, setSortDirection] = useState({ vendor_name: 'asc' });
	const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
	const [isDeleteBulkConfirmationModalOpen, setIsDeleteBulkConfirmationModalOpen] = useState(false);
	const [selectedVendorsListForDelete, setSelectedVendorsListForDelete] = useState([]);
	const [selectedVendorsList, setSelectedVendorsList] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [statusToUpdate, setStatusToUpdate] = useState(null);
	const [vendorParams, setVendorParams] = useState({
		page: 1,
		pageSize: 20,
		orderBy: null,
		sortBy: null,
		filterBy: null,
		filterValue: null,
	});
	const [deselectRows, setDeselectRows] = useState(null);
	const handleDeselectAll = (deselectAllRowsFn) => {
		setDeselectRows(() => deselectAllRowsFn);
	};

	const [vendorColumn] = useState([
		{
			field: '',
			headerClass: 'HeaderBg',
			checkboxSelection: true,
			headerCheckboxSelection: true,
			minWidth: 50,
			maxWidth: 50,
			lockPosition: true,
		},
		{
			field: 'NAME',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 300,
			flex: 1,
			cellClass: 'left-align',
			sortingOrder: ['desc', 'asc'],
			unSortIcon: true,
			SortDirection: 'asc',
			cellRenderer: (params) => {
				const nameAndStatus = params.data?.NAME || ""; // Fallback to an empty string if undefined
				const [name, status] = nameAndStatus.split("|");

				if (status) {
					return (
						<p>
							{name}
							<span
								style={{
									backgroundColor: "#718096",
									color: "white",
									borderRadius: "20px",
									padding: "2px 10px",
									paddingBottom: "3px",
									marginLeft: "10px",
									fontSize: "11px",
								}}
							>
								{status}
							</span>
						</p>
					);
				}
				return (
					<p>
						{name}
					</p>
				);
			}
		},
		{
			field: 'VENDOR DISPLAY NAME',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 300,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'WORK PHONE',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 100,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'PAYABLE',
			headerClass: 'HeaderBg right-align',
			suppressSizeToFit: true,
			minWidth: 100,
			flex: 1,
			cellClass: 'right-align',
		},
	]
	)

	const navigateRouter = (path) => {
		navigate(path);
	};

	const onRowClick = (id) => {
		if (id) {
			navigate(`/user/${suburl}/vendor-create/${id}`);
		}
	};

	// Sorting handler
	const handleSort = (orderBy) => {
		const newDirection = sortDirection[orderBy] === 'desc' ? 'asc' : 'desc';
		setSortDirection({ [orderBy]: newDirection });
		setVendorParams((prev) => ({
			...prev,
			orderBy: orderBy,
			sortBy: newDirection,
		}));
	};

	const sortIconMap = {
		asc: <IoIosArrowRoundUp />,
		desc: <IoIosArrowRoundDown />,
	};

	const handlePageChange = (page) => {
		setVendorParams((prev) => ({
			...prev,
			page,
		}));
	};

	// Handle status update
	async function handleIsActiveStatus(status) {
		try {
			const formbody = {
				vendor_ids: bulkAction,
				status,
			};
			setLoader(true); // Start loader

			// Simulate API call
			await changeIsActiveStatus(formbody);

			toast.success('Status Changes Successfully');
			setVendorParams((prev) => ({
				...prev,
				filterBy: 'status',
				filterValue: headerDropDown === 'All Vendor' ? null : status ? 0 : 1,
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		} finally {
			setLoader(false); // Stop loader
		}
	}

	// Function to trigger modal with status
	const triggerConfirmationModal = (status) => {
		setStatusToUpdate(status);
		setIsModalOpen(true);
	};

	// Close Modal
	const closeModal = () => {
		setIsModalOpen(false);
		setStatusToUpdate(null); // Reset the status
	};

	const getModalContent = () => {
		return (
			<div>
				<p
					style={{ fontWeight: '600' }}
					className="ConfirmationText"
				>
					Are you sure you want to mark these {bulkAction.length} vendors as{' '}
					{statusToUpdate === 1 ? 'Active' : 'Inactive'}?
				</p>
				<div
					style={{
						padding: '0px 10px',
					}}
				>
					{tableData
						.filter((vendor) => bulkAction.includes(vendor.id))
						.map((vendor, index) => (
							<p key={vendor.id}>
								{index + 1}. {vendor.NAME}
							</p>
						))}
				</div>
			</div>
		);
	};

	const getModalTitle = () => {
		if (statusToUpdate === 1) {
			return 'Mark as Activate Vendors ';
		} else if (statusToUpdate === 0) {
			return 'Mark as Inactivate Vendors';
		}
		return 'Confirm Action';
	};

	const exportData = (param) => {
		ExportTable(param);
	};

	const handleBulkPaymentTermsUpdate = (payment_terms) => {
		if (payment_terms) {
			const obj = {
				vendor_ids: bulkAction,
				payment_terms,
			};
			dispatch(bulkUpdateCurrencyOrPaymentTerms(obj))
				.then((res) => {
					if (res?.success) {
						toast.success(res?.message);
						getAllVendor();
						setBulkAction([]);
					} else {
						toast.error(res?.message || 'Something went wrong');
					}
				})
				.catch((err) => {
					toast.error(err?.message || 'Something went wrong');
				});
			setOpenPaymentTermsModal(false);
		}
	};

	const handleBulkCurrencyUpdate = (currency) => {
		if (currency) {
			const obj = {
				vendor_ids: bulkAction,
				currency,
			};
			dispatch(bulkUpdateCurrencyOrPaymentTerms(obj))
				.then((res) => {
					if (res?.success) {
						toast.success(res?.message);
						setBulkAction([]);
						getAllVendor();
					} else {
						toast.error(res?.message || 'Something went wrong');
					}
				})
				.catch((err) => {
					toast.error(err?.message || 'Something went wrong');
				});
			setCurrencyModal(false);
		}
	};

	const handleBulkActionClick = () => {
		setShowBulkOptions((prev) => !prev);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			const dropdown = document.getElementById('bulk-options-dropdown');
			if (showBulkOptions && dropdown && !dropdown.contains(event.target)) {
				setShowBulkOptions(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [showBulkOptions]);

	async function handleBulkDelete() {
		try {
			await deleteVendorService(bulkAction);
			toast.success('Vendor Deleted Successfully.');
			setIsDeleteBulkConfirmationModalOpen(false);
			setBulkAction([]);
			getAllVendor();
		} catch (error) {
			toast.error(error?.message || 'Something went wrong');
		}
	}

	function handleOpenBulkDeleteVendorModal() {
		console.log({ bulkAction, tableData });
		const selectedVendorsList = tableData?.reduce((acc, item) => {
			if (bulkAction.includes(item.id)) {
				acc.push(item.NAME);
				return acc;
			}
			return acc;
		}, []);
		console.log({ selectedVendorsList });
		setSelectedVendorsListForDelete(selectedVendorsList);
		setIsDeleteBulkConfirmationModalOpen(true);
	}

	function mappedTableData(data) {

		return data?.map((ele) => {
			let nameWithStatus = ""
			if (ele.status === 0) {
				nameWithStatus = `${ele.vendor_name} | Inactive`
			} else {
				nameWithStatus = ele.vendor_name
			}

			return {
				id: ele.vendor_id,
				NAME: nameWithStatus || 'N/A',
				'VENDOR DISPLAY NAME': ele?.vendor_display_name || 'N/A',
				'WORK PHONE': ele?.phone_mobile || 'N/A',
				PAYABLE: 'N/A',
			}
		});
	}

	const getAllVendor = async () => {
		try {
			setLoader(true);
			const { vendors, totalPages } = await fetchVendors(vendorParams);

			const data = mappedTableData(vendors);

			setTableData(data);
			setTotalPages(totalPages);
			setBulkAction([]);
		} catch (error) {
			toast.error('Something went wrong!');
		} finally {
			setLoader(false);
		}
	};

	useEffect(() => {
		getAllVendor();
	}, [vendorParams]);


	const [advanceFilterParams, setAdvanceFilterParams] = useState({
		page: 1,
		pageSize: 20,
		filters: {},
	});
	const [advanceFilterSelectInputList, setadvanceFilterSelectInputList] = useState([]);
	const [selectedAdvanceFilterInputOnVendorTables, setSelectedAdvanceFilterInputOnVendorTables] =
		useState([]);

	const advanceFilterfilterMap = {
		vendor_name: 'Vendor Name',
		vendor_display_name: 'Vendor Display Name',
		email: 'Email',
		phone_mobile: 'Phone Number',
		vendor_id: 'Vendor Id',
		status: 'Status',
	};

	async function handleApplyFilters() {
		const params = advanceFilterSelectInputList.reduce((acc, item) => {
			if (item?.value) {
				acc[item.key] = item?.value;
				return acc;
			}
			return acc;
		}, {});

		setAdvanceFilterParams((prev) => ({
			...prev,
			filters: {
				...prev.filters,
				...params,
			},
		}));
		try {
			const token = localStorage.getItem('authToken');
			const { data } = await axios.post(
				`${APP_API_URL}/vendor/search-filter`,
				{ ...advanceFilterParams, filters: { ...params } },
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				},
			);

			const vendors = mappedTableData(data?.data?.vendors);
			setTableData(vendors);
			setSelectedAdvanceFilterInputOnVendorTables(advanceFilterSelectInputList);
			setIsAdvanceFilterOpen(false);
		} catch (error) {
			toast.error('Something went wrong! Try again.');
		}
	}

	async function handleAdvanceFilterDropdown(value) {
		if (value === null) return;

		const isAlreadyPresent = advanceFilterSelectInputList.find((item) => item.key === value);

		if (isAlreadyPresent) {
			return;
		}

		setadvanceFilterSelectInputList([
			...advanceFilterSelectInputList,
			{
				key: value,
				label: advanceFilterfilterMap[value],
				data: [],
			},
		]);
	}

	function handleRemoveSelectedFilterInput(key, fromTable = false) {
		const updatedFilterInputs = advanceFilterSelectInputList.filter((item) => item?.key !== key);
		setadvanceFilterSelectInputList(updatedFilterInputs);
		if (fromTable) {
			setSelectedAdvanceFilterInputOnVendorTables(updatedFilterInputs);
		}
	}

	async function handleOnFocusSelectedFilterInput(key, fromTable = false) {
		let selectInputData;
		if (key !== 'status') {
			const data = await fetchVendors({ filterBy: key, filterValue: '' });
			selectInputData = data?.vendors?.reduce((acc, vendor) => {
				if (vendor[key]) {
					acc.push({
						key: vendor[key],
						value: vendor[key],
					});
					return acc;
				}
				return acc;
			}, []);
		}

		const updatedSelectInputData = advanceFilterSelectInputList.map((item) => {
			if (item.key === 'status') {
				return {
					...item,
					data: [
						{
							key: '0',
							value: 'Inactive',
						},
						{
							key: '1',
							value: 'Active',
						},
					],
				};
			}
			if (item.key === key) {
				return {
					...item,
					data: selectInputData,
				};
			}
			return item;
		});

		setadvanceFilterSelectInputList(updatedSelectInputData);
		if (fromTable) {
			setSelectedAdvanceFilterInputOnVendorTables(updatedSelectInputData);
		}
	}

	const isFirstRender = useRef(true);

	async function handleOnKeyDownCb(key, value, fromTable = false) {
		try {

			const params = {
				filterBy: key,
				filterValue: value,
			};
			const { vendors } = await fetchVendors(params);

			const optionsList = vendors?.map((vendor) => {
				return {
					key: vendor[key],
					value: vendor[key],
				};
			});

			const updatedAdvanceFilterSelectInputList = advanceFilterSelectInputList?.map((item) => {
				if (item[key]) {
					return {
						...item,
						data: optionsList,
					};
				}
				return item;
			});

			if (fromTable) {
				setadvanceFilterSelectInputList(updatedAdvanceFilterSelectInputList);
			} else {
				setSelectedAdvanceFilterInputOnVendorTables(updatedAdvanceFilterSelectInputList);
			}

		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (selectedAdvanceFilterInputOnVendorTables?.length === 0 && !isFirstRender.current) {
			getAllVendor();
		} else if (selectedAdvanceFilterInputOnVendorTables.length > 0) {
			handleApplyFilters();
		} else {
			isFirstRender.current = false;
		}
	}, [selectedAdvanceFilterInputOnVendorTables]);


	return (
		<div>
			{bulkAction.length > 0 ? (
				<div className="product-pg-header">
					<div className="dropdown text-end">
						<p className="d-block link-dark text-decoration-none">Bulk Actions</p>
					</div>
					<div className="add-product-button">
						<Button
							type="button"
							button="Print"
							className="mt-0 px-4 btn-gray"
							onClick={() => window.print()}
						/>
						<Button
							type="button"
							button="Bulk Update"
							className="mt-0 px-4 btn-gray relative"
							onClick={handleBulkActionClick}
						/>

						{showBulkOptions && (
							<div
								className="dropdown-menu show font-14"
								id="bulk-options-dropdown"
								style={{
									width: '114px',
									position: 'absolute',
									zIndex: 1000,
									maxHeight: '300px',
									overflowY: 'auto',
									marginTop: '120px',
									marginLeft: '80px',
								}}
							>
								<div
									style={{
										padding: '5px',
										cursor: 'pointer',
									}}
									className="dropdown-item"
									onClick={() => {
										setOpenPaymentTermsModal(true);
										setShowBulkOptions(false);
									}}
								>
									Payment Terms
								</div>
								<div
									style={{
										padding: '5px',
										cursor: 'not-allowed',
									}}
									className="dropdown-item"
									onClick={() => {
										setCurrencyModal(true);
										setShowBulkOptions(false);
									}}
								>
									Currency
								</div>
							</div>
						)}

						{headerDropDown === 'Inactive Vendor' && (
							<Button
								type="button"
								button="Mark as Active"
								className="mt-0 px-4 btn-gray"
								onClick={() => triggerConfirmationModal(1)} // Trigger confirmation modal for active
							/>
						)}
						{headerDropDown === 'Active Vendor' && (
							<Button
								type="button"
								button="Mark as Inactive"
								className="mt-0 px-4 btn-gray"
								onClick={() => triggerConfirmationModal(0)} // Trigger confirmation modal for inactive
							/>
						)}
						{headerDropDown === 'All Vendor' && (
							<>
								<Button
									type="button"
									button="Mark as Active"
									className="mt-0 px-4 btn-gray"
									onClick={() => triggerConfirmationModal(1)} // Trigger confirmation modal for active
								/>
								<Button
									type="button"
									button="Mark as Inactive"
									className="mt-0 px-4 btn-gray"
									onClick={() => triggerConfirmationModal(0)} // Trigger confirmation modal for inactive
								/>
							</>
						)}

						<Button
							type="button"
							button="Bulk Delete"
							className="mt-0 px-4 btn-gray relative"
							onClick={handleOpenBulkDeleteVendorModal}
						/>

						<Button
							type="button"
							className="mt-0 btn-danger add-pro"
							button=""
							close
							onClick={() => {
								setShowBulkOptions(false);
								setBulkAction([]);
								deselectRows && deselectRows()
							}}
						/>
					</div>
				</div>
			) : (
				<div className="product-pg-header add-product">
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 15,
						}}
					>
						<div className="dropdown text-end">
							<p
								className="d-block link-dark text-decoration-none dropdown-toggle"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								{headerDropDown}
							</p>
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li>
									<a
										className="dropdown-item"
										onClick={() => {
											setHeaderDropDown('All Vendor');
											setVendorParams((prev) => ({
												...prev,
												filterBy: '',
												filterValue: '',
											}));
										}}
									>
										All Vendor
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => {
											setHeaderDropDown('Active Vendor');
											setVendorParams((prev) => ({
												...prev,
												filterBy: 'status',
												filterValue: 1,
											}));
										}}
									>
										Active Vendor
									</a>
								</li>
								<li>
									<a
										style={{
											cursor: 'not-allowed',
										}}
										className="dropdown-item"
										onClick={() => {
											toast.error('This filter is not yet completed!');
										}}
									>
										CRM Vendor
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => {
											setHeaderDropDown('Inactive Vendor');
											setVendorParams((prev) => ({
												...prev,
												filterBy: 'status',
												filterValue: 0,
											}));
										}}
									>
										Inactive Vendor
									</a>
								</li>
							</ul>
						</div>
						<div>
							{selectedAdvanceFilterInputOnVendorTables?.length > 0 ? (
								<div>
									<img
										src={clearAllFilter}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
										onClick={() => {
											setadvanceFilterSelectInputList([])
											setSelectedAdvanceFilterInputOnVendorTables([])
										}}
									/>
									<a
										onClick={() => {
											setadvanceFilterSelectInputList([])
											setSelectedAdvanceFilterInputOnVendorTables([])
										}}
										style={{
											color: '#FF0000',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Clear All Filters
									</a>
								</div>
							) : (
								<div>
									<img
										src={advanceFilterIcon}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
									/>
									<a
										onClick={() => setIsAdvanceFilterOpen(true)}
										style={{
											color: '#2E63F6',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Advanced Filters
									</a>
								</div>
							)}
						</div>
					</div>

					<div className="add-product-button column-gap-0">
						<Button
							type="button"
							className="btn-primary mt-0 px-5"
							button="Create New Vendor"
							add
							onClick={() => navigateRouter(`/user/${suburl}/vendor-create`)}
						/>
						<div className="dropdown text-end ms-3">
							<a
								href="#"
								className="d-block link-dark text-decoration-none"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img
									alt=""
									src={sortbydot}
								/>
							</a>
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li
									style={{
										padding: 10,
										textDecoration: 'none',
										color: '#212529',
										width: '100%',
										fontWeight: 'bold',
										cursor: 'not-allowed',
										marginBottom: '10px',
									}}
								>
									Sort By
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => handleSort('vendor_name')}
									>
										Name
										<span>{sortIconMap[sortDirection?.vendor_name]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => handleSort('vendor_display_name')}
									>
										Vendor Display Name
										<span>{sortIconMap[sortDirection?.vendor_display_name]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => handleSort('vendor_id')}
									>
										Vendor Id
										<span>{sortIconMap[sortDirection?.vendor_id]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => handleSort('created_at')}
									>
										Created Time
										<span>{sortIconMap[sortDirection?.created_at]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => handleSort('updated_at')}
									>
										Last Modified Time
										<span>{sortIconMap[sortDirection?.updated_at]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => {
											getAllVendor();
											setSortDirection({ vendor_name: 'asc' }); // Reset to default
										}}
									>
										Refresh
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => exportData('vendor')}
									>
										Export Vendor
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: 10,
					padding: '0px 10px',
				}}
			>
				{selectedAdvanceFilterInputOnVendorTables?.length > 0 && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 10,
						}}
					>
						{selectedAdvanceFilterInputOnVendorTables.map((item) => (
							<AdvanceFilterSelectInput
								className="vendorAdvaneFilterSelectInput"
								options={item?.data}
								placeholder={`Select ${item?.label}`}
								value={item?.value}
								onFocusCb={() => {
									handleOnFocusSelectedFilterInput(item?.key, true);
								}}
								onKeyDownCb={(value) => handleOnKeyDownCb(item?.key, value, true)}
								onChange={(value) => {
									const updatedData = advanceFilterSelectInputList.map((_item) => {
										if (_item?.key === item?.key) {
											return {
												..._item,
												value,
											};
										}
										return _item;
									});
									setadvanceFilterSelectInputList(updatedData);
									setSelectedAdvanceFilterInputOnVendorTables(updatedData);
								}}
								removeInput={() => handleRemoveSelectedFilterInput(item?.key, true)}
							/>
						))}

						<div style={{ marginBottom: '8px' }}>
							<img
								src={addFilter}
								alt=""
								onClick={() => setIsAdvanceFilterOpen(true)}
							/>
						</div>
					</div>
				)}
			</div>

			<VendorTable
				loader={loader}
				tableData={tableData}
				vendorColoum={vendorColumn}
				onRowClick={onRowClick}
				setBulkAction={setBulkAction}
				bulkAction={bulkAction}
				handleDeselectAll={handleDeselectAll}
				page={vendorParams?.page}
				totalPages={totalPages}
				handlePageChange={handlePageChange}
			/>

			{openPaymentTermsModal && (
				<VendorBulkPaymentTermModal
					openPaymentTermsModal={openPaymentTermsModal}
					setOpenPaymentTermsModal={setOpenPaymentTermsModal}
					getAllVendor={getAllVendor}
					setBulkAction={setBulkAction}
					bulkAction={bulkAction}
					handleBulkPaymentTermsUpdate={handleBulkPaymentTermsUpdate}
				/>
			)}

			{/* Confirmation Modal */}
			{isModalOpen && <ConfirmationModal
				isOpen={isModalOpen}
				onClose={closeModal}
				title={getModalTitle()}
				content={getModalContent()}
				onCancel={closeModal}
				onOk={() => {
					handleIsActiveStatus(statusToUpdate);
					closeModal();
				}}
				loader={loader}
			/>}

			{openCurrencyModal && (
				<VendorCurrencyModal
					openCurrencyModal={openCurrencyModal}
					setCurrencyModal={setCurrencyModal}
					handleBulkCurrencyUpdate={handleBulkCurrencyUpdate}
				/>

			)}

			{/* Advance Filter  */}
			{isAdvanceFilterOpen && (
				<VendorAdvanceFilter
					advanceFilterOptions={advanceFilterOptions}
					isAdvanceFilterOpen={isAdvanceFilterOpen}
					handleOnKeyDownCb={handleOnKeyDownCb}
					handleAdvanceFilterDropdown={handleAdvanceFilterDropdown}
					advanceFilterSelectInputList={advanceFilterSelectInputList}
					handleRemoveSelectedFilterInput={handleRemoveSelectedFilterInput}
					handleOnFocusSelectedFilterInput={handleOnFocusSelectedFilterInput}
					setadvanceFilterSelectInputList={setadvanceFilterSelectInputList}
					setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
					handleApplyFilters={handleApplyFilters}
				/>

			)}

			{isDeleteBulkConfirmationModalOpen && (
				<DeleteVendorModal
					isDeleteBulkConfirmationModalOpen={isDeleteBulkConfirmationModalOpen}
					setIsDeleteBulkConfirmationModalOpen={setIsDeleteBulkConfirmationModalOpen}
					selectedVendorsListForDelete={selectedVendorsListForDelete}
					handleBulkDelete={handleBulkDelete}
					vendorLength={bulkAction.length}
				/>

			)}
		</div>
	);
};

export default Vendors;
