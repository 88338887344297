import React, { useState } from "react";
import InputField from "../../../component/form/FormInput";
import Button from "../../../component/ui/Button";
import InputSelect from "../../../component/inputSelect/InputSelect";
import { useNavigate } from "react-router-dom";
import Navigation from "./Navigation";
import OtherDetails from './OtherDetails';
import Address from './Address';

const CreateCustomers = () => {
  const suburl = localStorage.getItem("suburl");
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [paymentAmount, setPaymentAmount] = useState("");
  const [isFullPayment, setIsFullPayment] = useState(false);
  const [isAddNewVendorModalOpen, setIsAddNewVendorModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('Other Details');


  const navigateRouter = (path) => {
    navigate(path);
  };
  // const [customersBasicData, setcustomersBasicData] = useState({
  //   vendor_name: '',
  //   vendor_display_name: '',
  //   drug_license_number: '',
  //   email: '',
  //   phone_landline: '',
  //   phone_mobile: '',
  // });
  const [otherDetailsData, setOtherDetailsData] = useState({
    pan: '',
    gst: '',
    currency: 'INR',
    payment_terms: '',
    website_url: '',
    designation: '',
  });
  const [address, setAddress] = useState({
    attention: '',
    country_id: null,
    state_id: null,
    street_1: '',
    street_2: '',
    city: '',
    zip_code: '',
  });
  // const handleBasicInputChange = (key, value) => {
  //   const updatedData = { ...customersBasicData, [key]: value };
  //   setCustomersBasicData(updatedData);
  // }

  return (
    <div>
      {/* Header Section */}
      <div className="product-pg-header add-product">
        <p>Create New Customer</p>
        <div className="add-product-button">
          <Button
            type="button"
            className="btn-primary mt-0 px-4"
            button="Save"
          />
          <Button
            type="button"
            className="mt-0 btn-danger add-pro"
            button=""
            close
            onClick={() => navigateRouter(`/user/${suburl}/customers`)}
          />
        </div>
      </div>

      {/* Vendor Form Section */}
      <div className="max-w-1297" style={{ padding: "1rem 2rem 0" }}>
        <div className="row">
          <div className="col-6">
            <label
              style={{
                fontWeight: "bold",
                marginBottom: "1.3rem",
                display: "block",
              }}
            >
              Customers Type
            </label>
            <div style={{ display: 'flex', gap: '40px' }}>
              <label
                style={{
                  marginRight: "1.5rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  name="customerType"
                  value="individual"
                  style={{
                    marginRight: "0.75rem",
                    width: "20px",
                    height: "20px",
                  }}
                />
                Individual
              </label>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  name="customerType"
                  value="business"
                  style={{
                    marginRight: "0.75rem",
                    width: "20px",
                    height: "20px",
                  }}
                />
                Business
              </label>
            </div>
          </div>

          <div className="col">
            <InputField
              label="Customer Name"
              placeholder="Raj Kumar Agrawal"
              required={true} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div style={{ position: "relative" }}>
              <InputField
                label="Company Name"
                placeholder="Lorem"
                type="text"
              // value={paymentAmount}
              // onChange={(e) => setPaymentAmount(e.target.value)}
              // style={{
              //   paddingRight: "50px",
              //   backgroundColor: "#f1f3f5",
              //   width: "100%",
              // }}
              />
            </div>
          </div>
          <div className="col-6">
            <InputSelect
              label="Customer Display Name"
              placeholder="Raj Kumar Agrawal"
              required={true}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-6">
            <InputField
              label="Customer Email"
              placeholder="youremail@domainname.com"
              type="text"
            />
          </div>
          <div className="col-2">
            <InputField
              label="Phone(Work)"
              placeholder="REF-0008"
              type="text"
              inputStyle={{ textAlign: "right" }}
            />
          </div>
          <div className="col-2">
            <InputField
              label="Phone(Mobile)"
              placeholder="REF-0008"
              type="text"
              inputStyle={{ textAlign: "right" }}
            />
          </div>
          <div className="col-2">
            <InputField
              label="Credit Dayd"
              placeholder="REF-0008"
              type="text"
              inputStyle={{ textAlign: "right" }}
            />
          </div>
        </div>


        <Navigation
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === 'Other Details' && (
          <OtherDetails
            data={otherDetailsData}
            // handleInputChange={handleOtherDetailsInputChange}
            // error={error}
            // disabled={disabled}
            setError={setError}
          />
        )}
        {activeTab === 'Address' && (
          <Address
            data={address}
          // error={error}
          // handleInputChange={handleAddressInputChange}
          // disabled={disabled}
          />
        )}
      </div>





    </div >
  );
};

export default CreateCustomers;