import React from 'react'
import Modal from '../../../../component/modal/Modal';
import Button from '../../../../component/ui/Button';

function DeletePurchaseBillModel({ isDeleteBulkConfirmationModalOpen, setIsDeleteBulkConfirmationModalOpen, selectedVendorsListForDelete, purchaseBillLength, handleBulkDelete }) {
  return (
    <Modal
      className="deleteVendorsModal"
      title="Purchase  Delete"
      isOpen={isDeleteBulkConfirmationModalOpen}
      onClose={() => {
        setIsDeleteBulkConfirmationModalOpen(false);
      }}
      cancelButton={() => {
        setIsDeleteBulkConfirmationModalOpen(false);
      }}
    >
      <p
        style={{
          textAlign: 'center',
          backgroundColor: '#E6F5FF',
          padding: '10px',
          borderRadius: '5px',
          fontWeight: '600',
          color: 'black',
        }}
      >
        Are you sure you want to delete {purchaseBillLength} Purchase Bill?
      </p>
      <div
        style={{
          padding: '0px 10px',
        }}
      >
        {selectedVendorsListForDelete?.length > 0 &&
          selectedVendorsListForDelete?.map((vendorName, index) => (
            <p
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
              key={vendorName + index}
            >
              {index + 1}. {vendorName}
            </p>
          ))}
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          justifyContent: 'end',
        }}
      >
        <Button
          type="button"
          className="mt-0 btn-gray add-pro"
          button="Cancel"
          onClick={() => {
            setIsDeleteBulkConfirmationModalOpen(false);
          }}
        />

        <Button
          type="button"
          button="Confirm"
          className="mt-0 px-4 btn-danger relative"
          onClick={handleBulkDelete}
        />
      </div>
    </Modal>
  )
}

export default DeletePurchaseBillModel;