import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './index.scss';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import PurchaseOrderForm from '../components/PurchaseOrderForm';
import {
	create_purchase_order,
	fetchPurchaseOrders,
	update_purchase_order,
} from '../../../services/purchaseOrder';
import { getDate } from '../../../utils/dateUtils';

const CreatePurchaseOrder = () => {
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const [isErrortable, setIsTableError] = useState(true);
	const [disabled, setDisabled] = useState(id ? true : false);
	const [error, setError] = useState({});
	const [formData, setFormData] = useState({
		vendor_id: '',
		purchase_order_number: '',
		date: getCurrentDate(),
		delivery_address: '',
		expected_delivery_date: '',
		delivery_method: '',
		is_draft: 1,
		purchase_order_products: [],
		email_communication: [],
		remarks: '',
	});

	function handleFormData(key, value) {
		setFormData((prev) => ({
			...prev,
			[key]: value,
		}));
	}

	function getCurrentDate() {
		const today = new Date();
		const formattedDate = today.toISOString().split('T')[0];
		return formattedDate;
	}

	async function handleCreatePurchaseOrder(body) {
		try {
			const data = await create_purchase_order(body);
			return data;
		} catch (error) {
			throw error;
		}
	}

	async function handleUpdatePurchaseOrder(id, body) {
		try {
			const data = await update_purchase_order(id, body);
			return data;
		} catch (error) {
			throw error;
		}
	}

	function checkValidation() {
		const newError = { ...error };
		if (!formData.vendor_id || formData.vendor_id === '' || formData.vendor_id === undefined) {
			newError.vendor_id = 'Vendor name is required';
		} else {
			delete newError.vendor_id; // Agar fill kiya hai to error ko remove karein
		}

		if (
			!formData.purchase_order_number ||
			formData.purchase_order_number === '' ||
			formData.purchase_order_number === undefined
		) {
			newError.purchase_order_number = 'Purchase order number is required';
		} else {
			delete newError.purchase_order_number; // Agar fill kiya hai to error ko remove karein
		}
		if (!formData.date || formData.date === '' || formData.date === undefined) {
			newError.date = 'Date is required';
		} else {
			delete newError.date; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!formData.delivery_address ||
			formData.delivery_address === '' ||
			formData.delivery_address === undefined
		) {
			newError.delivery_address = 'Delivery Address is required';
		} else {
			delete newError.delivery_address; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!formData.expected_delivery_date ||
			formData.expected_delivery_date === '' ||
			formData.expected_delivery_date === undefined
		) {
			newError.expected_delivery_date = 'Expected Delivery Date is required';
		} else {
			delete newError.expected_delivery_date; // Agar fill kiya hai to error ko remove karein
		}
		// if (!formData.remarks || formData.remarks === '' || formData.remarks === undefined) {
		// 	newError.remarks = 'Reference is required';
		// } else {
		// 	delete newError.remarks; // Agar fill kiya hai to error ko remove karein
		// }
		// if (!formData.delivery_method ||
		// 	formData.delivery_method === '' ||
		// 	formData.delivery_method === undefined
		// ) {
		// 	newError.delivery_method = 'Delivery Method is required';
		// } else {
		// 	delete newError.delivery_method; // Agar fill kiya hai to error ko remove karein
		// }

		if (Object.keys(newError).length > 0 || isErrortable) {
			console.log(isErrortable);
			setError(newError);
			return true;
		} else {
			console.log(isErrortable);
			return false;
		}
	}

	const handleSavePurchaseOrder = async (is_draft = 1, isSendEmail = false) => {
		let isError = checkValidation();
		if (isError) {
			toast.error('Please fill required fields');
		} else {
			try {
				let purchase_order_id = null;
				setDisabled(true);
				if (id) {
					//TODO: update purchaseOrder
					await handleUpdatePurchaseOrder(id, { ...formData, is_draft });
					//TODO: set purchase_order_id for send Email
					purchase_order_id = id;
					toast.success('Purchase order updated successfully.');
				} else {
					//TODO: create purchase order
					const data = await handleCreatePurchaseOrder({ ...formData, is_draft });
					//TODO: set purchase_order_id for send Email
					purchase_order_id = data?.data;
					toast.success('New Purchase Order Created Successfully.');
				}
				setDisabled(false);

				if (isSendEmail) {
					navigate(`/user/${suburl}/purchases-order-send-emails/${purchase_order_id}`);
				}

				navigate(`/user/${suburl}/purchases-order`);
			} catch (error) {
				toast.error('Something went wrong!');
			}
		}
	};

	async function handleFetchPurchaseOrderDetails() {
		try {
			const params = {
				filterBy: 'purchase_order_id',
				filterValue: id,
			};
			const purchaseOrderDetails = await fetchPurchaseOrders(params);
			const purchaseDetails = purchaseOrderDetails.purchaseOrders[0];
			const {
				vendor_id,
				purchase_order_number,
				date,
				delivery_address,
				expected_delivery_date,
				delivery_method,
				is_draft,
				payment_terms,
				purchase_order_products,
				remarks,
				vendor,
			} = purchaseDetails;

			setFormData({
				vendor_id,
				purchase_order_number,
				date: getDate(date),
				delivery_address,
				expected_delivery_date: getDate(expected_delivery_date),
				delivery_method,
				is_draft,
				payment_terms,
				purchase_order_products: purchase_order_products || [],
				email_communication:
					vendor?.contact_person?.length > 0
						? vendor?.contact_person?.map((contact) => contact?.email)
						: [],
				remarks,
			});
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	useEffect(() => {
		if (id) {
			handleFetchPurchaseOrderDetails();
		}
	}, [id]);

	return (
		<div>
			<div className="">
				<Header
					headerTitle="Create New Purchase Order"
					buttons={[
						{
							id: 1,
							className: 'btn-primary ',
							button: 'Save',
							handler: () => handleSavePurchaseOrder(0),
						},
						{
							id: 2,
							className: 'btn-primary',
							button: 'Save & Send',
							handler: () => handleSavePurchaseOrder(0, true),
						},
						{
							id: 3,
							className: 'btn-primary mt-0 px-4',
							button: 'Save as Draft',
							handler: () => handleSavePurchaseOrder(1),
						},
					]}
					closeButton={{
						showCloseButton: true,
						handler: () => navigate(`/user/${suburl}/purchases-order`),
					}}
				/>
				<PurchaseOrderForm
					formData={formData}
					error={error}
					handleFormData={handleFormData}
					disabled={disabled}
					paramId={id}
					tableError={isErrortable}
					setIsTableErrorShow={setIsTableError}
				/>
			</div>
		</div>
	);
};

export default CreatePurchaseOrder;
