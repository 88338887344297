// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overflow-container {
  width: 486px;
}

.advance-filter-modal {
  max-width: 753px !important;
  height: 509px !important;
  margin-top: -20px;
  border-radius: 0%;
}

.advance-filter-header {
  background-color: #f6f6f9;
}

.titleClass {
  font-size: 22px !important;
  font-weight: 700;
}

.input-select-wrapper input {
  background-color: transparent !important;
  border: none !important;
}

.selectInput input {
  width: 256px !important;
}`, "",{"version":3,"sources":["webpack://./src/component/Filter/advanceFilter.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;AACD;;AAEA;EACC,2BAAA;EACA,wBAAA;EACA,iBAAA;EACA,iBAAA;AACD;;AAEA;EACC,yBAAA;AACD;;AAEA;EACC,0BAAA;EACA,gBAAA;AACD;;AACA;EACC,wCAAA;EACA,uBAAA;AAED;;AAAA;EACC,uBAAA;AAGD","sourcesContent":[".overflow-container {\n\twidth: 486px;\n}\n\n.advance-filter-modal {\n\tmax-width: 753px !important;\n\theight: 509px !important;\n\tmargin-top: -20px;\n\tborder-radius: 0%;\n}\n\n.advance-filter-header {\n\tbackground-color: #f6f6f9;\n\n}\n.titleClass{\n\tfont-size: 22px !important;\n\tfont-weight: 700;\n}\n.input-select-wrapper input {\n\tbackground-color: transparent !important;\n\tborder: none !important;\n}\n.selectInput input {\n\twidth: 256px !important;\n\t// border: 1px solid rgba(85, 91, 103, 0.15) !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
