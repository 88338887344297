import React from 'react';
import pdf from '../../../assets/image/pdf_icon.svg';
import image from '../../../assets/image/image.svg';

const ViewDocs = ({ docName, docData }) => {
	function getDocExtension(doc) {
		const fileName = doc instanceof File ? doc.name : doc;
		const fileParts = fileName?.split('.');
		return fileParts && fileParts.length > 1 ? fileParts.pop() : '';
	}
	const docExtn = getDocExtension(docData);

	const docNameWithId = docData.split('/').pop();
	return (
		<div className="file_list mt-2">
			{docName && (
				<div>
					<div className="file_name">
						<img
							src={['png', 'jpeg', 'jpg'].includes(docExtn) ? image : pdf}
							title="shree_shyam_departmentstore.pdf"
							alt=""
							height={['png', 'jpeg', 'jpg'].includes(docExtn) ? '25px' : "20px"}
							style={{ marginRight: '1rem' }}
						/>{' '}
						<span title="shree_shyam_departmentstore.pdf">
							{docNameWithId}
						</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default ViewDocs;
