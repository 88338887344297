import React from 'react';
import PurchaseReceiveTableView from '../../component/InputTable/PurchaseReceiveTableView';

const PurchaseReceiveTable = ({
	tableData,
	setTableData,
	disabled,
	paramId,
	tableError,
	setIsTableErrorShow,
}) => {
	const handleTableChange = (newData) => {
		setTableData(newData);
	};

	const columns = [
		{ name: 'product_name', label: 'Product Name', type: 'product_name' },
		{ name: 'order_quantity', label: 'Ordered', type: 'text' },
		{ name: 'received_quantity', label: 'Received', type: 'text' },
		{ name: 'is_in_transit', label: 'In-Transit ', type: 'text' },
		{ name: 'qty', label: 'Quantity to receive ', type: 'text' },
	];

	let initialRows = [
		{
			product_name: '',
			order_quantity: 0,
			received_quantity: 0,
			is_in_transit: 0,
			qty: 0,
		},
	];

	return (
		<div
			className="add-adjustment-inputTable max-w-1297"
			style={{ padding: '0rem 2rem' }}
		>
			<PurchaseReceiveTableView
				initialRows={initialRows}
				columns={columns}
				onChange={handleTableChange}
				value={tableData}
				disabled={disabled}
				newRowgenerate={'Quantity'}
				returnInputvalue={false}
				paramId={paramId}
				tableError={tableError}
				setIsTableErrorShow={setIsTableErrorShow}
			/>
		</div>
	);
};

export default PurchaseReceiveTable;
