import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../component/ui/Button';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import { FaFilePdf } from 'react-icons/fa';

function SendEmail({ pdfName, pdfId, toEmails, formData, handleSend, handleClose }) {
  const [toEmail, setToEmail] = useState([]);
  const [showFromDropdown, setShowFromDropdown] = useState(false);
  const [showToDropdown, setShowToDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredSenders, setFilteredSenders] = useState([]);
  const [isSending, setIsSending] = useState(false)
  const inputRef = useRef(null);
  const fromDropdownRef = useRef(null); // Reference to the "From" dropdown
  const toDropdownRef = useRef(null); // Reference to the "To" dropdown
  const [emailFormData, setEmailFormData] = useState({
    from: "",
    to: [],
    subject: "",
    body: "",
    isAttachment: true,
  })

  const updateFilteredSenders = () => {
    setFilteredSenders(
      emailFormData.to.filter((sender) =>
        sender.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };

  // Handle email selection
  const handleSelectSender = (email) => {
    if (!emailFormData.to.includes(email)) {
      setEmailFormData((prev) => ({
        ...prev,
        to: [...prev.to, email],
      }));
    }
    setShowToDropdown(false);
  };


  // Handle email removal
  const handleRemoveEmail = (email) => {
    setEmailFormData((prev) => ({
      ...prev,
      to: prev.to.filter((e) => e !== email)
    }))
  };

  // Update filtered senders on search query change
  useEffect(() => {
    setFilteredSenders(
      toEmails.filter((email) =>
        email.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery]);



  // Close dropdown if clicked outside of input or dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        // For "To"
        (inputRef.current &&
          !inputRef.current.contains(event.target) &&
          toDropdownRef.current &&
          !toDropdownRef.current.contains(event.target)) ||
        // For "From"
        (fromDropdownRef.current &&
          !fromDropdownRef.current.contains(event.target) &&
          !event.target.closest("input"))
      ) {
        setShowFromDropdown(false); // Close From dropdown
        setShowToDropdown(false); // Close To dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (formData && toEmails) {
      setEmailFormData(formData);
      setFilteredSenders(toEmails); // Initialize with all emails
    }
  }, [formData, toEmails]);



  async function handleSendEmail() {
    try {
      setIsSending(true)
      await handleSend(emailFormData)
    } catch (error) {
      toast.error("Something went wrong!")
    } finally {
      setIsSending(false)
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-between' style={{ backgroundColor: '#EDEDF7', padding: '1rem 2rem', alignItems: 'center' }}>
        <h2 style={{ color: '#0F1C35', fontWeight: '600' }}>Email To Medico Agencies</h2>
        <div className='d-flex' style={{ gap: '12px' }}>
          <Button
            type="button"
            className="btn-primary mt-0 px-4"
            button="Send"
            loader={isSending}
            onClick={handleSendEmail}
          />
          <Button
            type="button"
            className="mt-0 btn-danger add-pro"
            button=""
            close
            onClick={handleClose}
          />
        </div>
      </div>
      <div style={{
        width: '950px', border: '1px solid #ccc', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px', marginTop: '20px', borderRadius: '10px'
      }}>
        {/* From Email */}
        <div style={{ marginBottom: '18px', marginTop: '12px', display: 'flex', alignItems: 'center', borderBottom: '1px solid #DEDEDE', width: '870px' }}>
          <label style={{ fontWeight: '500', width: '80px', color: '#888888', fontSize: '18px' }}>From</label>
          <div style={{ position: 'relative', flex: 1 }}>
            <input
              ref={inputRef}
              type="email"
              value={emailFormData.from}
              onClick={() => setShowFromDropdown(!showFromDropdown)}
              onChange={(e) => setEmailFormData((prev) => ({
                ...prev,
                from: e.target.value
              }))}
              onFocus={() => setShowToDropdown(false)}
              placeholder="Enter your email"
              style={{
                padding: '10px',
                border: 'none',
                outline: 'none',
                fontSize: '18px',
                width: '100%',
                borderBottom: 'none',
                boxShadow: 'none',
                fontWeight: '500',
                color: '#000000',
                marginLeft: '23px'
              }}
            />
            {/* Dropdown for "From" field */}
            {showFromDropdown && (
              <div ref={fromDropdownRef} style={{
                position: 'absolute', top: '52px', left: '28px', width: '97%', border: '1px solid #ccc', backgroundColor: 'white', borderRadius: '4px', zIndex: 10, maxHeight: '200px', overflowY: 'auto', borderTop: 'none',
              }}>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search"
                  style={{
                    padding: '9px',
                    width: '98%',
                    backgroundColor: 'white',
                    border: '1px solid #8c8ce1',
                    margin: '4px',
                    borderRadius: '6px',
                    fontSize: '16px'
                  }}
                />
                {/* Displaying email list */}
                <div style={{ padding: '10px' }}>
                  {filteredSenders.length === 0 ? (
                    <div>No senders found</div>
                  ) : (
                    filteredSenders.map((sender, index) => (
                      <div
                        key={index}
                        style={{
                          padding: '10px',
                          cursor: 'pointer',
                          marginBottom: '10px',
                          transition: 'background-color 0.3s ease',
                          backgroundColor: 'transparent',
                          borderRadius: '5px',
                          color: '#888888',
                          fontSize: '16px'
                        }}
                        onClick={() => {
                          setEmailFormData((prev) => ({
                            ...prev,
                            from: sender
                          }));
                          setShowFromDropdown(false);
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = '#3B58FF';
                          e.target.style.color = 'white';
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = 'transparent';
                          e.target.style.color = '#888888';
                        }}
                      >
                        {sender}
                      </div>
                    ))
                  )}
                  {/* <div
                  style={{
                    padding: '11px', cursor: 'pointer', color: 'blue', backgroundColor: '#EDEDF7', borderRadius: '4px', fontWeight: '600'
                  }}
                  onClick={() => {
                    setFromEmail('');
                    setShowFromDropdown(false);
                  }}
                >
                  + Add New Sender Email
                </div> */}
                </div>
              </div>
            )}
          </div>
        </div>

        <div style={{ marginBottom: "20px", display: "flex", alignItems: "center", borderBottom: "1px solid #DEDEDE", width: "870px" }}>
          <label style={{ fontWeight: "500", width: "80px", color: "#888888", fontSize: "18px" }}>Send To</label>
          <div style={{ position: "relative", flex: 1 }}>
            {/* Email input field */}
            <div style={{ position: "relative" }}>
              <input
                ref={inputRef}
                type="email"
                // value={toEmail}
                onClick={() => setShowToDropdown(!showToDropdown)}
                onChange={(e) => {
                  setToEmail(e.target.value);
                  setSearchQuery(e.target.value); // Update search query as user types
                }}
                onFocus={() => setShowFromDropdown(false)}
                placeholder="Enter recipient's email"
                style={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  fontSize: "18px",
                  // backgroundColor: toEmail ? "#F1F4F7" : "transparent",
                  borderRadius: "3px",
                  padding: "8px",
                  boxShadow: "none",
                  paddingRight: "35px",
                  marginLeft: '23px'
                }}
              />

              {/* Display selected emails within input field */}
              {emailFormData.to.length > 0 && (
                <div style={{
                  top: "0px",
                  left: "0",
                  fontSize: "14px",
                  color: "#000",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  overflowY: "auto", // Enable scrolling when emails exceed max height
                  paddingRight: "35px", // Prevent overlap with input
                  paddingBottom: "10px",
                  marginLeft: '30px'
                }}>
                  {emailFormData.to.map((email, index) => (
                    <div key={index} style={{
                      padding: "0px 10px",
                      backgroundColor: "rgb(241, 244, 247)",
                      color: "#000",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      gap: 10
                    }}>
                      <p>{email}</p>
                      <p
                        onClick={() => handleRemoveEmail(email)}
                        style={{
                          background: "transparent",
                          border: "none",
                          // marginLeft: "8px",
                          color: "#000",
                          cursor: "pointer",
                        }}
                      >
                        &#10005;
                      </p>
                    </div>
                  ))}
                  {/* {toEmails.length > 3 && (
                  <div style={{
                    fontSize: "14px",
                    color: "#888",
                    marginTop: "9px",
                    cursor: "pointer"
                  }} onClick={() => setShowToDropdown(true)}>
                    +{toEmails.length - 3} more
                  </div>
                )} */}
                </div>
              )}

              {/* Close icon for clearing the input */}
              {emailFormData.to.length > 0 && (
                <span
                  onClick={() => setEmailFormData((prev) => ({
                    ...prev,
                    to: []
                  }))}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    fontSize: "20px",
                    color: "#888",
                  }}
                >
                  &times;
                </span>
              )}
            </div>

            {/* Dropdown for selecting emails */}
            {showToDropdown && (
              <div
                ref={fromDropdownRef}
                style={{
                  position: "absolute",
                  top: "52px",
                  left: "28",
                  width: "97%",
                  border: "1px solid #ccc",
                  backgroundColor: "white",
                  borderRadius: "4px",
                  zIndex: 10,
                  maxHeight: "200px",
                  overflowY: "auto",
                  borderTop: "none",
                  marginLeft: '29px'
                }}
              >
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search"
                  style={{
                    padding: "9px",
                    width: "98%",
                    backgroundColor: "white",
                    border: "1px solid #8c8ce1",
                    margin: "4px",
                    borderRadius: "6px",
                    fontSize: "16px",
                  }}
                />
                {/* Display filtered senders */}
                <div style={{ padding: "10px" }}>
                  {filteredSenders.length === 0 ? (
                    <div>No senders found</div>
                  ) : (
                    filteredSenders.map((sender, index) => (
                      <div
                        key={index}
                        style={{
                          padding: "10px",
                          cursor: "pointer",
                          marginBottom: "10px",
                          transition: "background-color 0.3s ease",
                          backgroundColor: emailFormData.to.includes(sender) ? "#3B58FF" : "transparent",
                          borderRadius: "5px",
                          color: emailFormData.to.includes(sender) ? "white" : "#888888",
                          fontSize: "16px",
                        }}
                        onClick={() => handleSelectSender(sender)}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#3B58FF";
                          e.target.style.color = "white";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = emailFormData.to.includes(sender) ? "#3B58FF" : "transparent";
                          e.target.style.color = emailFormData.to.includes(sender) ? "white" : "#888888";
                        }}
                      >
                        {sender}
                        {emailFormData.to.includes(sender) && <span style={{ float: "right" }}>✔</span>}
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Subject */}
        <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', borderBottom: '1px solid #DEDEDE', width: '870px' }}>
          <label style={{ fontWeight: '500', width: '80px', color: '#888888', fontSize: '18px' }}>Subject</label>
          <input
            type="text"
            value={emailFormData.subject}
            onChange={(e) => setEmailFormData((prev) => ({
              ...prev,
              subject: e.target.value
            }))}
            placeholder="Enter subject"
            style={{
              flex: 1,
              padding: '10px',
              border: 'none',
              outline: 'none',
              fontSize: '18px',
              fontWeight: '500',
              color: '#000000',
              background: 'none',
              boxShadow: 'none',
              marginLeft: '21px'
            }}
          />
        </div>

        {/* Message */}
        <div style={{ marginBottom: '20px', width: '92%' }}>
          <ReactQuill
            value={emailFormData.body}
            onChange={(val) => {
              setEmailFormData((prev) => ({
                ...prev,
                body: val
              }))
            }}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'size': ['small', 'medium', 'large', 'huge'] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                ['link', 'image'],
              ],
            }}
            style={{
              width: '100%',
              border: '1px solid #DEDEDE',
              // borderRadius: '5px',
              outline: 'none',
              fontSize: '16px',
              height: '300px',
            }}
          />
          <style>
            {`
    .ql-toolbar {
      background-color: #DEDEDE !important; /* Set background color for the toolbar */
       }
      .ql-container.ql-snow {
      border: none !important;
          }
  `}
          </style>
        </div>

        {/* attachPdf */}

        <div style={{
          width: '870px',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '15px',
          justifyContent: 'space-between',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '10px',
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={emailFormData.isAttachment}
              onChange={() => setEmailFormData((prev) => ({
                ...prev,
                isAttachment: !prev.isAttachment
              }))}
              style={{ marginRight: '10px' }}
            />
            <label style={{ fontWeight: '600' }}>Attach {pdfName} PDF</label>
          </div>

          <FaFilePdf style={{ color: '#e74c3c', marginRight: '-10px', fontSize: '25px', background: 'transparent' }} />
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '5px 10px',
            width: '200px',
            justifyContent: 'flex-start',
          }}>
            <span>{pdfId}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendEmail