import React from 'react';
import SeachStatus from '../../assets/image/search-status.svg';
import arrow from '../../assets/image/arrowD.svg';
import './advanceFilter.scss';
import AdvanceFilterSelectInput from './AdvanceFilterSelectInput';
import InputSelect from '../inputSelect/InputSelect';
import Modal from '../modal/Modal';

const AdvanceFilter = ({
	isOpen,
	advanceFilterOptions,
	handleAdvanceFilterDropdown,
	advanceFilterSelectInputList,
	onFocusSelectedFilterInput,
	handleOnChangeSelectedFilterInput,
	onClose,
	onApply,
	modalLabel,
	handleRemoveSelectedFilterInput,
	onKeyDownCb,
}) => {
	return (
		<Modal
			title="Advance Filters"
			isOpen={isOpen}
			onClose={onClose}
			cancelButton={true}
			className="advance-filter-modal"
			headerClassName="advance-filter-header"
			titleClassName="titleClass"

		>
			<div>
				{/* Note */}
				<div style={styles.note}>{modalLabel}</div>

				<div
					style={{ display: 'flex', flexDirection: 'column', height: '315px', overflowY: 'auto' }}
				>
					<div
						className=""
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							gap: 10,
							padding: "10px 0px"
						}}
					>
						<div
							className="d-flex  align-items-center"
							style={{

								gap: 10,
								width: '485px',
								backgroundColor: '#EDEDF7',
								borderRadius: '8px 8px 0px 0px',
								padding: '1px 10px',
								paddingTop: '5px',
								border: '1px solid var(--sub-stroke-15, rgba(85, 91, 103, 0.15))',
							}}
						>
							<img
								style={{
									marginBottom: 6
								}}
								src={SeachStatus}
								alt=""
							/>
							<InputSelect
								placeholder="Search or Select Filter"
								options={advanceFilterOptions}
								returnInputValue={false}
								onSelect={handleAdvanceFilterDropdown}
								className="input-select-wrapper"
							/>
						</div>

						<div className="overflow-container">
							{advanceFilterSelectInputList?.map((item) => (
								<div key={item?.key} style={{ borderBottom: "1px solid gray", padding: "5px 0px" }}>
									<AdvanceFilterSelectInput
										onKeyDownCb={(value) => onKeyDownCb(item?.key, value)}
										label={item?.label}
										options={item?.data}
										returnInputValue={false}
										placeholder={`Select ${item?.label}`}
										value={item?.value}
										onFocusCb={() => {
											onFocusSelectedFilterInput(item?.key);
										}}
										onChange={(value) => handleOnChangeSelectedFilterInput(item?.key, value?.value)}
										removeInput={() => handleRemoveSelectedFilterInput(item?.key)}
										className='selectInput'
									/>
								</div>
							))}
						</div>
					</div>
				</div>

				{/* Buttons */}
				<div style={styles.buttonGroup}>
					<button
						style={styles.cancelButton}
						onClick={onClose}
					>
						Cancel
					</button>
					<button
						style={styles.applyButton}
						onClick={onApply}
					>
						Apply Filters
					</button>
				</div>
			</div>
		</Modal>
	);
};

// Styles for AdvanceFilter
const styles = {
	closeButton: {
		backgroundColor: 'rgb(246, 246, 249)',
		color: '#fff',
		border: 'none',
		borderRadius: '5px',
		padding: '6px 11px',
		cursor: 'pointer',
	},
	note: {
		backgroundColor: '#E6F5FF',
		padding: '10px',
		fontSize: '15px',
		margin: 'auto',
		color: 'rgba(85, 91, 103, 0.80)',
		fontWeight: '600',
		textAlign: 'center'
	},
	// select: {
	// 	flexGrow: '0.4',
	// 	padding: '8px',
	// 	fontSize: '14px',
	// 	borderRadius: '5px',
	// 	border: '1px solid #ccc',
	// 	color: '#555B67',
	// 	backgroundImage: `url(${arrow})`,
	// 	backgroundRepeat: 'no-repeat',
	// 	backgroundPosition: 'right 10px center',
	// 	backgroundSize: '12px',
	// 	appearance: 'none', // Hide the default dropdown arrow
	// 	marginLeft: '60px',
	// 	width: '230px',
	// },
	clearButton: {
		background: 'none',
		border: 'none',
		fontSize: '16px',
		cursor: 'pointer',
		color: '#f00',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'end',
		marginBottom: '30px',
		marginRight: '30px',
	},
	cancelButton: {
		backgroundColor: '#CCCCCC',
		color: '#000',
		padding: '10px 20px',
		border: 'none',
		borderRadius: '5px',
		cursor: 'pointer',
		marginRight: '20px',
		fontWeight: '600',
		width: '180px',
		height: '40px',
		position: 'sticky',
	},
	applyButton: {
		backgroundColor: '#3B58FF',
		color: '#fff',
		padding: '10px 20px',
		border: 'none',
		borderRadius: '5px',
		fontWeight: '600',
		cursor: 'pointer',
		width: '180px',
		height: '40px',
		position: 'sticky',
	},
	headerTitle: { color: 'black', fontWeight: '700', fontSize: '22px' },
	selectInput: { width: '100%', background: 'transparent', border: 'none' },
	inputGroup: {
		position: 'relative',
		width: '100%',
		marginBottom: '10px',
		display: 'flex',
		flexDirection: 'column',
	},
	inputLabel: { fontWeight: '600', color: 'black', marginBottom: '5px' },
	searchInput: {
		backgroundImage: `url(${arrow})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right 10px center',
		backgroundSize: '12px',
		appearance: 'none', // Hide the default dropdown arrow
		padding: '8px',
		border: '1px solid #ccc',
		borderRadius: '4px',
		color: '#555B67',
		fontSize: '14px',
		width: '100%',
	},
	dropdown: {
		position: 'absolute',
		top: '100%',
		left: '0',
		right: '0',
		background: '#fff',
		border: '1px solid #ddd',
		borderRadius: '4px',
		boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
	},
	dropdownItem: {
		padding: '8px',
		cursor: 'pointer',
	},
	noData: {
		padding: '8px',
		color: '#777',
	},
};

export default AdvanceFilter;

{
	/* {Object?.keys(advanceFilterParams.filters)?.map((key) => {
									return (
										<AdvanceFilterSelectInput
											key={key}
											filterKey={key}
											search={search}
											setSearch={setSearch}
											setActiveSearchKey={setActiveSearchKey}
											placeholder={advanceFilterParams.filters[key]}
											label={advanceFilterfilterMap[key]}
											onFocusCb={() => handleFetchAdvanceFilterData(key)}
											onChange={(value) => {
												setAdvanceFilterParams((prev) => ({
													...prev,
													filters: {
														...prev.filters,
														[key]: value[key],
													},
												}));
												setAdvanceFilterSeachKey((prev) => ({
													...prev,
													[key]: value[key],
												}));
											}}
											options={apiResults[key]}
											removeInput={() => {
												const updatedAdvanceFilters = Object.keys(
													advanceFilterParams.filters,
												).reduce((acc, filterKey) => {
													if (filterKey === key) {
														return acc;
													}
													acc[filterKey] = advanceFilterParams.filters[filterKey];
													return acc;
												}, {});
												setAdvanceFilterParams((prev) => ({
													...prev,
													filters: updatedAdvanceFilters,
												}));

												const updatedAdvanceFilterSearchKey = Object.keys(
													advanceFilterSearchKey,
												).reduce((acc, searchKey) => {
													if (searchKey === key) {
														acc[searchKey] = '';
														return acc;
													}
													acc[searchKey] = advanceFilterSearchKey[searchKey];
													return acc;
												}, {});

												setAdvanceFilterSeachKey(updatedAdvanceFilterSearchKey);

												const newSearch = search;
												delete newSearch[key];
												setSearch(newSearch);
											}}
										/>
									);
								})} */
}
