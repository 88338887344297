import React, { useEffect, useState } from 'react';
import Modal from '../../component/modal/Modal';
import InputField from '../../component/form/FormInput';
import EmailPlaceHolder from '../../assets/image/emailPlaceHolder.svg';
import './purchaseOrder.scss'
import AddContactPerson from '../vendors/ContactPerson/AddContactPerson';


function EmailCommunication({ emails, handleEmailCommunication, vendorContact, setVendorContact, reloadTable, vendorId }) {
	const [newEmail, setNewEmail] = useState('');
	const [isAddEmailModalOpen, setIsAddEmailModalOpen] = useState(false);
	const [isSelectedEmails, setIsSelectedEmails] = useState([]);
	const [open, setOpen] = useState(false);
	const [selectedContactPersonIndex, setSelectContactPersonIndex] = useState(null);

	// Initialize email list with isSelected flag
	useEffect(() => {
		function handleSetInitialEmails() {
			const mappedEmails = ['mdasif@gmail.com', 'johndoe@gmail.com', 'test@gmail.com'].map(
				(email) => ({
					isSelected: false,
					email,
				}),
			);
			console.log({ mappedEmails });
			setIsSelectedEmails(mappedEmails);
		}
		handleSetInitialEmails();
	}, []);

	// Pass selected emails to parent component
	useEffect(() => {
		const selectedEmails = isSelectedEmails.filter((email) => email.isSelected).map((e) => e.email);
		handleEmailCommunication(selectedEmails);
	}, [isSelectedEmails]);

	// Toggle individual email selection
	const handleCheckboxChange = (index) => {
		const updatedEmails = isSelectedEmails.map((email, i) =>
			i === index ? { ...email, isSelected: !email.isSelected } : email,
		);
		setIsSelectedEmails(updatedEmails);
	};

	// Toggle selection of all emails
	const handleSelectAll = () => {
		const allSelected = isSelectedEmails.every((email) => email.isSelected);
		const updatedEmails = isSelectedEmails.map((email) => ({
			...email,
			isSelected: !allSelected,
		}));
		setIsSelectedEmails(updatedEmails);
	};

	// Add new email to the list
	const handleAddNewEmail = () => {
		if (newEmail && !isSelectedEmails.some((e) => e.email === newEmail)) {
			setIsSelectedEmails([...isSelectedEmails, { email: newEmail, isSelected: true }]);
			setNewEmail('');
			setIsAddEmailModalOpen(false);
		}
	};

	return (
		<div style={{ padding: '10px 20px' }}>
			<div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
				<p style={{ fontWeight: '600' }}>Email Communications</p>
				<p
					style={{ color: 'blue', cursor: 'pointer', textDecorationLine: 'underline' }}
					onClick={handleSelectAll}
				>
					Select All
				</p>
				<br />
				<br />

			</div>


			<div className='d-flex align-items-center flex-wrap ' style={{ gap: 15 }}>

				{isSelectedEmails.map((email, index) => (
					<div
						key={email.email}
						style={{
							display: 'flex',
							// justifyContent: 'space-around',
							alignContent: 'center',
							border: '1px solid #C3CBDC',
							backgroundColor: '#F6F6F9',
							borderRadius: '5px',
							fontSize: '14px',
							fontWeight: '600',
							cursor: 'pointer',
							// width: '290px',
							height: '36px',

						}}
						onChange={() => handleCheckboxChange(index)}
					>
						<div className='d-flex align-items-center ' style={{
							gap: '5px',
							// width: '256px',
							display: 'flex',
							justifyContent: 'space-evenly',
							alignItems: 'center',
						}}>

							<div style={{ marginLeft: '5px', width: '24px', }}>
								<label className="custom-checkbox">
									<input
										type="checkbox"
										checked={email.isSelected}
										onChange={() => handleCheckboxChange(index)}
										style={{ display: 'none' }} // Hide the default checkbox
									/>
									<span className="checkmark"></span>
								</label>
								<style jsx>{`
        .custom-checkbox {
            display: flex;
            align-items: center;
						justifyContent: 'center'
            cursor: pointer;
        }

        .checkmark {
				
            width: 24px;
            height: 24px;
            border: 1px solid #C3CBDC; /* Border color */
            border-radius: 5px; /* Border radius */

            background-color: ${email.isSelected ? '#f1f1f1' : '#f1f1f1'};  /* Background color */
            
            position: relative;
        }

        .custom-checkbox input:checked + .checkmark {
            background-color: #3B58FF; /* Checked color */
        }

        .custom-checkbox input:checked + .checkmark::after {
            content: "";
            position: absolute;
            left: 8px;
            top: 4px;
            width: 5px;
            height: 10px;
            border: solid white; /* Checkmark color */
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    `}</style>
							</div>


							<div className='d-flex align-items-center justify-content-center' style={{
								width: '26px',
								height: '26px',
								borderRadius: '5px',
								backgroundColor: '#EDEDF7',
								width: '27px'

							}}>
								<img src={EmailPlaceHolder} alt="" />
							</div>

							<div className='d-flex ' style={{ color: '#000000', width: '200px', overflow: 'scroll auto ', marginTop: '13px', fontWeight: 'bold' }}>

								Name({email.email})
							</div>

							<div style={{ color: 'red', fontSize: '14px', width: '24px ' }}>
								X
							</div>




						</div>




					</div>
				))}
				<p
					style={{ color: 'blue', cursor: 'pointer', textDecorationLine: 'underline' }}
					onClick={() => { setOpen(true); }}
				>
					+ Add New
				</p>
			</div>

			{open && (
				<div>
					<Modal
						className="add-contact-modal"
						isOpen={open}
						onClose={() => {
							setOpen(false);
							setSelectContactPersonIndex(null);
						}}
						title="Add Contact Person"
						cancelButton={true}
					>
						<AddContactPerson
							isOpen={open}
							onClose={() => {
								setOpen(false);
								setSelectContactPersonIndex(null);
							}}
							selectedContactPersonIndex={selectedContactPersonIndex}
							tableData={vendorContact}
							setTableData={setVendorContact}
							reloadTable={reloadTable}
							vendorId={vendorId}
						/>
					</Modal>
				</div>
			)}
		</div>
	);
}

export default EmailCommunication;
