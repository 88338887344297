import React, { useState } from 'react';
import './index.scss';
import Table from '../../../component/table/Table';
import ArrowUp from '../../../assets/image/arrow_up.svg';
import ArrowDown from '../../../assets/image/arrowD.svg';

function BillReceiveTable({ tableConfig }) {
	const [isBill, setIsBill] = useState(true);
	const [isShowTable, setIsShowTable] = useState(false);
	return (
		<div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					backgroundColor: '#F6F6F9',
				}}
			>
				<div className="tabs">
					<div
						className={`tab ${isBill ? 'active' : 'inactive'}`}
						style={{ fontSize: '16px', fontWeight: 'bold' }}
						onClick={() => {
							setIsBill(true);
							setIsShowTable(true);
						}}
					>
						{tableConfig.billTable.title}
						<span
							style={{
								// backgroundColor: '#DDE1FA',
								// borderRadius: '5px',
								color: '#3B58FF',
								marginLeft: '7px',
							}}
						>
							{tableConfig.billTable.count}
						</span>
					</div>
					<div
						className={`tab ${!isBill ? 'active' : 'inactive'}`}
						onClick={() => {
							setIsBill(false);
							setIsShowTable(true);
						}}
						style={{ fontSize: '16px', fontWeight: 'bold' }}
					>
						{tableConfig.receiveTable.title}
						<span
							style={{
								color: '#3B58FF',
								marginLeft: '7px',
							}}
						>
							{tableConfig.receiveTable.count}
						</span>
					</div>
				</div>

				<div
					style={{
						padding: '0px 10px',
					}}
				>
					{isShowTable ? (
						<img
							style={{
								cursor: 'pointer',
							}}
							src={ArrowUp}
							onClick={() => setIsShowTable(false)}
						/>
					) : (
						<img
							style={{
								cursor: 'pointer',
							}}
							src={ArrowDown}
							onClick={() => setIsShowTable(true)}
						/>
					)}
				</div>
			</div>

			{isShowTable && (
				<div
					className="table"
				>
					{isBill ? (
						<Table
							coloum={tableConfig?.billTable.column}
							row={tableConfig?.billTable?.row}
							onRowClick={tableConfig?.billTable?.onRowClick}
							style={{ height: '210px', padding: '0%' }}
						/>
					) : (
						<Table
							coloum={tableConfig?.receiveTable.column}
							row={tableConfig?.receiveTable?.row}
							onRowClick={tableConfig?.receiveTable?.onRowClick}
							style={{ height: '210px', padding: '0%' }}
						/>
					)}
				</div>
			)}
		</div>
	);
}

export default BillReceiveTable;
