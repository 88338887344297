import React, { useState } from "react";
import "./Navigation.scss"; // Import the custom CSS file

const Navigation = ({ activeTab, setActiveTab }) => {

  const navTabs = [
    "Other Details",
    "Address",
    "Remarks",
  ];

  return (
    <div>
      <ul className="nav mb-4 mt-2">
        {navTabs.map((item, index) => (
          <li key={index} className="nav-item">
            <a
              className={`nav-link ${activeTab === item ? 'active' : ''} cursur-pointer`}
              href="#"
              onClick={() => setActiveTab(item)}
            >
              {item}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navigation;
