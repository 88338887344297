export const PurchaseBillColoum = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'PURCHASE BILL#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const PBID = params.data?.PB || ''; // Fallback to an empty string if undefined

			return <p>{PBID}</p>;
		},
	},

	{
		field: 'VENDOR NAME',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'Status',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const status = params.data?.status || ''; // Fallback to an empty string if undefined

			if (status) {
				return (
					<p>
						<span
							style={{
								color:
									status === 'Pending Approval'
										? '#FFC008'
										: status === 'Received'
											? '#6C757D'
											: status === 'Approval'
												? '#68C230'
												: status === 'Cancel'
													? '#DC3545'
													: status === 'Issued'
														? '#3B58FF'
														: '#11CAF0',
							}}
						>
							{status}
						</span>
					</p>
				);
			}
		},
	},
	{
		field: 'Amount',
		headerClass: 'HeaderBg right-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'right-align',
	},
];

export const paymentModeColumn = [
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 50,
		flex: 1,
		cellStyle: { color: '#3B58FF' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'PAYMENT#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'right-align',
		cellStyle: { color: '#555B67' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'REFERENCE#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		cellClass: 'right-align',
		minWidth: 200,
		flex: 1,
	},
	{
		field: 'PAYMENT MODE',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'left-align',
		headerClass: 'HeaderBg',
	},
	{
		field: 'AMOUNT',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg left-align',
	},
	{
		field: '',
		suppressSizeToFit: true,
		minWidth: 70,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
];

export const convertToBillColumn = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'PURCHASE ORDER#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'ORDER DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
];
