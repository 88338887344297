import React, { useState } from 'react';
import './navigation.scss';
// import '../../../../pages/vendors/NavigationTab.scss';


const NavigationTabsCustomersDetails = ({ activeTab, setActiveTab }) => {
  const navTabs = ['Information', 'Transactions', 'Statement'];

  return (
    <div>
      <ul className="nav mb-4 mt-2">
        {navTabs.map((item, index) => (
          <li
            key={index}
            className="nav-item"
          >
            <a
              className={`nav-link ${activeTab === item ? 'active' : ''} cursur-pointer`}
              href="#"
              onClick={() => setActiveTab(item)}
            >
              {item}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavigationTabsCustomersDetails;
