import React from 'react'
import Modal from '../../../../component/modal/Modal'
import UpdateCurrency from '../../UpdateCurrecny'

function VendorCurrencyModal({ openCurrencyModal, setCurrencyModal, handleBulkCurrencyUpdate }) {
  return (
    <Modal
      className="add-payment-terms-modal"
      isOpen={openCurrencyModal}
      onClose={() => setCurrencyModal(false)}
      title="Currency"
      cancelButton={true}
    >
      <UpdateCurrency
        isOpen={openCurrencyModal}
        onCLose={() => setCurrencyModal(false)}
        handleSaveClick={handleBulkCurrencyUpdate}
      />
    </Modal>
  )
}

export default VendorCurrencyModal