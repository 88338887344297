import React from 'react';
// import "./modalRight.scss";
import '../modalRight/modalRight.scss';

const ErrorModal = ({ isOpen, onClose, title, error }) => {
	const date = new Date();
	const ThisYear = date.getFullYear().toString().slice(-2);
	const Thismonth = (date.getMonth() + 1).toString().padStart(2, '0');

	function getUniqueNestedArray(nestedArray) {
		console.log(nestedArray);
		return nestedArray.map((innerArray) => {
			// Convert to Set to remove duplicates, then convert back to Array
			return [...new Set(innerArray)];
		});
	}

	function createNestedArray(errors) {
		const nestedArray = [];
		console.log(errors);
		errors?.length > 0 &&
			errors?.forEach((error) => {
				const pathIndex = error?.index;
				const message = error?.field || error?.name;

				if (!nestedArray[pathIndex]) {
					nestedArray[pathIndex] = [];
				}

				nestedArray[pathIndex].push(message);
			});
		// Convert Sets to  (unnique element)
		return getUniqueNestedArray(nestedArray);
	}
	const GetErrorData = (name) => {
		switch (name) {
			case 'batch_number':
				return 'Batch No can not be empty.';
			case 'order_quantity':
				return 'Order quantity can not be 0.';
			case 'product_packaging':
				return 'Package name can not be empty.';
			case 'quantity':
				return 'Quantity can not be empty or 0';
			case 'mfg_date':
				return `MFG date can not be greater than ${Thismonth}/${ThisYear}`;
			case 'mrp':
				return 'MRP can not be empty';
			case 'purchase_rate':
				return 'Purchase Rate can not be greater than MRP or be empty';
			case 'free_quantity':
				return 'Free Quantity can not be more than Quantity';
			case 'expiry_date':
				return 'EXP Date can not be less than MFG date';
			case 'product_id':
				return 'Name can not be empty.';
			case 'batch_id':
				return 'Batch No can not be empty';
			case 'quantity_adjusted':
				return `Quantity Adjusted can not be greater than Quantity Available`;
			default:
				return '';
		}
	};

	return (
		<div className="modal-productDetail">
			<div
				className="modal-backdrop modal_outer right_modal"
				onClick={onClose}
			>
				<div
					class="modal-dialog"
					role="document"
				>
					<div
						className="modal-content"
						onClick={(e) => e.stopPropagation()}
					>
						<div className="modal-header border-0">
							<span className="modal-title">{title}</span>
							<button
								className="close-button"
								type="button"
								onClick={onClose}
							>
								&times;
							</button>
						</div>
						<div style={{ height: '95vh', overflowY: 'auto' }}>
							{error?.length > 0 &&
								createNestedArray(error)?.map((ele, ind) => {
									return (
										<>
											<b className="m-2"> Line No : {ind + 1} </b>
											<ul className="m-2">
												{ele?.map((item) => {
													return <li>{GetErrorData(item)}</li>;
												})}
											</ul>
										</>
									);
								})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ErrorModal;
