import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProductDetails from '../purchaseOrder/Navigation/ProductDetails';
import Transactions from '../purchaseOrder/Navigation/Transactions';
import { toast } from 'react-toastify';
import { deletePurchaseOrder, fetchPurchaseOrders } from '../../services/purchaseOrder';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Modal from 'react-responsive-modal';
import ProfilePlaceHolder from '../../assets/image/emailPlaceHolder.svg';
import BlackClose from '../../assets/image/x-squareBlack.svg';
import { convertToBillColumn, paymentModeColumn } from './purchaseBillsColoum';
import PageLoader from '../../component/PageLoader';
import PurchaseOrderInvoice from '../components/PurchaseOrderInvoice';
import { useReactToPrint } from 'react-to-print';



import Header from '../components/Header';
import PurchaseBillInvoice from '../components/PurchaseBillInvoice';


const PurchaseBillDetails = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	// const [activeTab, setActiveTab] = useState("Product Details");
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const suburl = localStorage.getItem('suburl');


	const [isProductDetailsOpen, setIsProductDetailsOpen] = useState(false);
	// const [isSendEmailOpen, setIsSendEmailOpen] = useState(false);
	const [isConvetToBillOpen, setIsConvertToBillOpen] = useState(false);
	const [activeTab, setActiveTab] = useState('bill');
	const [isDiscardQuantityModalOpen, setIsDiscardQuantityModalOpen] = useState(false);
	const [isShowPdfView, setIsShowPdfView] = useState(false)
	const printPdfRef = useRef(null);
	const printPurchaseOrderPdf = useReactToPrint({ contentRef: printPdfRef });
	const [productDetails, setProductDetails] = useState(null)


	const navTabs = ['Product Details', 'Transactions'];



	const renderTabContent = () => {
		switch (activeTab) {
			case 'Product Details':
				return <ProductDetails />;
			case 'Transactions':
				return <Transactions />;
			default:
				return null;
		}
	};

	async function handleDeletePurchaseBill() {
		try {
			const areYouSure = window.confirm('Are you sure want to delete ?');

			if (!areYouSure) return;
			// await deletePurchaseOrder(id);

			toast.success('Purchase bill deleted successfully.');
			navigate(`/user/${suburl}/purchases-bills`);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	// async function handleFetchPurchaseOrderDetails() {
	// 	try {
	// 		const params = {
	// 			filterBy: 'purchase_order_id',
	// 			filterValue: id,
	// 		};
	// 		const purchaseOrderDetails = await fetchPurchaseOrders(params);
	// 		const purchaseDetails = purchaseOrderDetails.purchaseOrders[0];
	// 		setPurchaseOrderDetails(purchaseDetails);
	// 	} catch (error) {
	// 		console.log(error);
	// 		toast.error('Something went wrong!');
	// 	}
	// }

	// useEffect(() => {
	// 	handleFetchPurchaseOrderDetails();
	// }, [id]);

	// if (!purchaseOrderDetails) {
	// 	return <PageLoader />;
	// }
	const paymentMode = [
		{
			DATE: '02/06/2024',
			'PAYMENT#': '788',
			'REFERENCE#': 'Ref-00001',
			'PAYMENT MODE': 'CASH',
			AMOUNT: 'Rs.200.00',
			'': '',
		},
	];

	const ConvertToBill = [
		{
			'PURCHASE ORDER#': 'PO-0012',
			'ORDER DATE': '29/03/2024',
			STATUS: 'Issued',
		},
	];

	const returnScreen = () => {
		navigate(`/user/${suburl}/purchases-bills`);
	};

	const editHandler = () => {
		navigate(`/user/${suburl}/purchases-bills-create/${id}`);
	};
	const createCreditNoteHandler = () => { };
	const printPdfHandler = () => { };
	const cloneHandler = () => { };
	const undoReceivedHandler = () => { };

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	return (
		<div style={{ backgroundColor: "#EDEDF7" }}>

			<Header
				headerTitle="Purchase Bills Details"
				closeButton={{
					showCloseButton: true,
					// handler: () => navigate(`/user/${suburl}/purchases-order`),
				}}
				buttons={[
					{
						id: 1,
						className: 'btn-gray mt-0 px-4 ',
						button: 'Edit',
						// handler: () => navigate(`/user/${suburl}/purchases-order-edit/${id}`),
					},
					{
						id: 2,
						className: 'btn-gray mt-0 px-4',
						button: 'PDF/Print',
						handler: async () => {
							await setIsShowPdfView(true)
							printPurchaseOrderPdf()
						},
						// handler: () => navigate(`/user/${suburl}/purchases-order-send-emails/${id}`),
					},
					{
						id: 3,
						className: 'mt-0 btn-gray add-pro',
						button: 'Create Credit Note',
						// handler: async () => {
						// 	await setIsShowPdfView(true)
						// 	printPurchaseOrderPdf()
						// },
					},
					{
						id: 4,
						className: 'btn-gray add-pro',
						button: 'Clone',
						// handler: () => setIsConvertToBillOpen(true),
					},
					{
						id: 5,
						className: 'btn-gray',
						button: 'Delete',
						handler: () => navigate(`/user/${suburl}/purchases-recieve-create/${id}`),
					},
					{
						id: 6,
						className: 'mt-0 btn-gray add-pro',
						button: 'Undo Receive',
						// handler: handleVoidPurchaseOrder,
					},

				]}
			// dropDownButtons={[
			// 	{
			// 		show: isUpdatePOStatusOpen,
			// 		setShow: setIsUpdatePOStatusOpen,
			// 		items: [
			// 			{ title: 'Approved', handler: () => handleUpdatePOStatus('is_approved') },
			// 			{ title: 'Issued', handler: () => handleUpdatePOStatus('is_issued') },
			// 			{ title: 'In-Transit', handler: () => handleUpdatePOStatus('is_in_transit') },
			// 			{ title: 'Cancelled', handler: () => handleUpdatePOStatus('is_cancelled') },
			// 		],
			// 	},
			// ]}
			/>
			<div>
				<div className="tabs">
					<div
						className={`tab`}
						style={{ fontSize: '16px', fontWeight: 'bold' }}
					>
						Payment Mode
						<span
							style={{
								backgroundColor: '#DDE1FA',
								borderRadius: '5px',
								color: '#3B58FF',
								marginLeft: '7px',
							}}
						>
							01
						</span>
					</div>
				</div>

				<div style={styles.table}>
					<Table
						coloum={paymentModeColumn}
						row={paymentMode}
						onRowClick={() => { }}
					/>
				</div>

				<style>
					{`
          .tabs {
            display: flex;
            font-size: 16px;
            font-weight: 400;
            background-color: #F6F6F9;
            height: 54px;
          }

          .tab {
            padding: 10px 20px;
            cursor: pointer;
            position: relative;
            transition: all 0.3s ease;
          }

          .tab.active {
            color: #000000;
            font-weight: bold;
          }

          .tab.inactive {
            color: #555B67; 
            font-weight: normal;
          }

          .tab.active::after {
            content: '';
            position: absolute;
            width: 75%;
            height: 3px;
            background-color: #3B58FF;
            bottom: 13px;
            left: 15px;
          }
        `}
				</style>
			</div>

			{
				!PurchaseBillDetails ? <PageLoader /> :
					<div>
						<PurchaseBillInvoice />

						{/* <PurchaseBillInvoice
							isShowPdfView={isShowPdfView}
							setIsShowPdfView={setIsShowPdfView}
							printPdfRef={printPdfRef}
							PurchaseBillDetails={PurchaseBillDetails}
							handleProductDetails={(product) => {
								setProductDetails(product)
								setIsSidebarOpen(true)
							}
							}
							handleDiscardQuantity={(prod) => {
								setProductDetails(prod)
								setIsDiscardQuantityModalOpen(true)
							}}
						/> */}
					</div>
			}


			{/* Sidebar ka layout */}
			{isSidebarOpen && (
				<div className="sidebar">
					<div
						className="d-flex align-self-center justify-content-sm-between "
						style={{ gap: '60px' }}
					>
						<div className="VenderDetails">Vendor Details</div>

						<div
							className="d-flex align-self-center "
							style={{ gap: '26px', alignItems: 'center' }}
						>
							<div>
								<a href="#">View more details</a>
							</div>

							<div
								className="SidebaarCloseBTN d-flex align-self-center justify-content-sm-center"
								onClick={toggleSidebar}
							>
								<img
									src={BlackClose}
									alt=""
									style={{ width: '14px', cursor: 'pointer' }}
								/>
							</div>
						</div>
					</div>

					<br />

					<div
						className="d-flex"
						style={{ gap: '20px' }}
					>
						<div className="profileImage d-flex align-self-center justify-content-sm-center">
							<img
								src={ProfilePlaceHolder}
								alt=""
								style={{ width: '55px', borderRadius: '10px' }}
							/>
						</div>

						<div
							className="d-flex flex-column"
							style={{ gap: '15px' }}
						>
							<div
								style={{
									fontSize: '18px',
									fontWeight: '600',
									color: '#333',
								}}
							>
								Shree Ram Medicals
							</div>

							<div
								style={{
									fontSize: '14px',
									color: 'var(--sub, #555B67)',
								}}
							>
								<b>Strip</b> contains <b>10 Tablets</b>
							</div>
						</div>
					</div>
					<br />
					<hr />

					{/* tabs  */}
					<div>
						<ul className="nav mb-4 mt-2">
							{navTabs.map((item, index) => (
								<li
									key={index}
									className="nav-item"
								>
									<a
										className={`nav-link ${activeTab === item ? 'active' : ''} cursur-pointer`}
										href="#"
										onClick={(e) => {
											e.preventDefault(); // Prevent the default anchor click behavior
											setActiveTab(item);
										}}
									>
										{item}
									</a>
								</li>
							))}
						</ul>
						<hr
							style={{
								backgroundColor: '#E3E4E5',
								boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
								marginTop: '-13px',
							}}
						/>
						<div>{renderTabContent()}</div>
					</div>
				</div>
			)}

			{isConvetToBillOpen && (
				<Modal
					className="convertToBillModal"
					isOpen={isConvetToBillOpen}
					onClose={setIsConvertToBillOpen}
					cancelButton={setIsConvertToBillOpen}
					title={
						<div
							style={{
								fontWeight: '600',
								color: '#000000',
								fontSize: '22px',
								paddingTop: '10px',
							}}
						>
							Purchase Order ({PurchaseBillDetails?.purchase_bill_number})
						</div>
					}
					headerClassName="custom-header"
					bodyClassName="convertToBillBody"
				>
					<div style={{ backgroundColor: '#E6F5FF', height: '36px', marginBottom: '36px' }}>
						<p
							style={{
								fontSize: '18px',
								color: '#555B67',
								paddingLeft: '10px',
								paddingTop: '3px',
								marginTop: '8px',
							}}
						>
							Note: You can create a bill for either Yet to Receive items or Received Items.
						</p>
					</div>

					<div>
						<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<input
									type="radio"
									id="yetToReceive"
									name="status"
									value="yetToReceive"
									style={{ marginBottom: '21px', width: '16px', height: '23px' }}
								/>
								<label
									htmlFor="yetToReceive"
									style={{ marginLeft: 5 }}
								>
									<div style={{ margin: 0, color: '#555B67', fontSize: '16px' }}>
										Yet to Receive
									</div>
									<div style={{ margin: 0, color: '#9FA6B5', fontSize: '16px' }}>
										Yet to receive product in this PO
									</div>
								</label>
							</div>

							<div style={{ display: 'flex', alignItems: 'center', marginLeft: '104px' }}>
								<input
									type="radio"
									id="received"
									name="status"
									value="received"
									style={{ marginBottom: '19px', width: '16px', height: '23px' }}
								/>
								<label
									htmlFor="received"
									style={{ marginLeft: 5 }}
								>
									<div style={{ margin: 0, color: '#555B67', fontSize: '16px' }}>Received</div>
									<div style={{ margin: 0, color: '#9FA6B5', fontSize: '16px' }}>
										Received product in this PO
									</div>
								</label>
							</div>
						</div>

						<div style={styles.tableContainer}>
							<Table
								coloum={convertToBillColumn}
								row={ConvertToBill}
								onRowClick={() => { }}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'end',
								gap: 4,
								marginTop: '60px',
							}}
						>
							<Button
								type="button"
								className="mt-0 btn-gray add-pro "
								button="Cancel"
								onClick={() => setIsProductDetailsOpen(false)}
							/>
							<Button
								type="button"
								className="mt-0 btn-primary add-pro"
								button="Convert To Bill"
							// onClick={handleDeletePurchaseOrder}
							/>
						</div>
					</div>
				</Modal>
			)}

			{isDiscardQuantityModalOpen && (
				<Modal
					className="discardQuantityModal"
					isOpen={isDiscardQuantityModalOpen}
					onClose={setIsDiscardQuantityModalOpen}
					cancelButton={setIsDiscardQuantityModalOpen}
					title={
						<div
							style={{
								fontWeight: '600',
								color: '#0F1C35',
								fontSize: '22px',
								paddingTop: '10px',
							}}
						>
							Discard Quantity
						</div>
					}
					headerClassName="discard-header"
					bodyClassName="discard-Body"
				>
					<div>
						<div
							style={{
								padding: '20px 0px',
							}}
						>
							<table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '-37px' }}>
								<thead>
									<tr
										style={{
											backgroundColor: '#E6F5FF',
											textAlign: 'left',
											color: '#555B67',
											fontSize: '14px',
										}}
									>
										<th style={{ padding: '10px' }}>ITEMS AND DESCRIPTION</th>
										<th>ORDERED</th>
										<th>RECEIVED STATUS</th>
										<th>REMAINING</th>
										<th>QUANTITY TO DISCARD</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ padding: '10px' }}>
											<a
												href="#"
												style={{ color: 'blue', textDecoration: 'underline' }}
											>
												Product A
											</a>
										</td>
										<td>
											100
											<br />
											<span>Strips</span>
										</td>
										<td>
											<div>90 Received</div>
											<div>90 Billed</div>
										</td>
										<td>10</td>
										<td>
											<input
												type="number"
												placeholder="10"
												style={{
													width: '50px',
													padding: '5px',
													borderRadius: '4px',
													border: '1px solid #ccc',
												}}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'end',
								gap: 4,
								marginTop: '22px',
							}}
						>
							<Button
								type="button"
								className="mt-0 btn-primary add-pro"
								button=" Discard Quantity"
							/>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
};
const styles = {
	table: {
		// width: '530px',
		margin: 'auto',
		maxHeight: '85px',
		overflowY: 'hidden',
		overflowX: 'hidden',
		borderBottom: '3px solid #C3CBDC',
	},
	tableContainer: {
		marginTop: '20px',
		maxHeight: '85px',
		overflowY: 'hidden',
		width: '626px',
	},
};
export default PurchaseBillDetails;
