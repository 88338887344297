import React, { useEffect, useRef, useState } from 'react';
import Button from '../../component/ui/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deletePurchaseOrder, discard_purchase_order_product_quantity, fetchPurchaseOrders, update_purchase_order_status } from '../../services/purchaseOrder';
import PageLoader from '../../component/PageLoader';
import Modal from '../../component/modal/Modal';
import ProfilePlaceHolder from '../../assets/image/emailPlaceHolder.svg';
import BlackClose from '../../assets/image/x-squareBlack.svg';
import './purchaseOrder.scss';
import ProductDetails from '../purchaseOrder/Navigation/ProductDetails';
import Transactions from '../purchaseOrder/Navigation/Transactions';
import './purchaseOrder.scss';
import Header from '../components/Header';
import BillReceiveTable from '../components/BillReceiveTable';
import PurchaseOrderInvoice from '../components/PurchaseOrderInvoice';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import { useReactToPrint } from 'react-to-print';
import { getDate } from '../../utils/dateUtils';
import UpdatePOStatusModel from './components/UpdatePOStatusModel';
import ConvertToBillModal from './components/ConvertToBillModal';

function PurchaseOrderDetails() {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	// const [activeTab, setActiveTab] = useState("Product Details");
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const suburl = localStorage.getItem('suburl');
	const [purchaseOrderDetails, setPurchaseOrderDetails] = useState();
	const [isUpdatePOStatusModelOpen, setIsUpdatePOStatusModelOpen] = useState(false);
	const [isConvetToBillOpen, setIsConvertToBillOpen] = useState(false);
	const [activeTab, setActiveTab] = useState('bill');
	const [isDiscardQuantityModalOpen, setIsDiscardQuantityModalOpen] = useState(false);
	const [productDetails, setProductDetails] = useState(null);
	const navTabs = ['Product Details', 'Transactions'];
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isConfirmDiscardModalOpen, setIsConfirmDiscardModalOpen] = useState(false);
	const printPdfRef = useRef(null);
	const printPurchaseOrderPdf = useReactToPrint({ contentRef: printPdfRef });
	const [isShowPdfView, setIsShowPdfView] = useState(false)
	const [discardProductQuanity, setDiscardProductQuantity] = useState(0)
	const [updatePOStatus, SetUpdatePOStatus] = useState("")

	const Receivecolumn = [
		{
			field: 'PR#',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 50,
			flex: 1,
			cellStyle: { color: '#3B58FF' },
			headerStyle: { color: '#555B67' },
		},
		{
			field: 'DATE',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellStyle: { color: '#555B67' },
			headerStyle: { color: '#555B67' },
		},
		{
			field: 'VENDOR NAME',
			suppressSizeToFit: true,
			minWidth: 40,
			flex: 1,
			cellClass: 'right-align',
			headerClass: 'HeaderBg right-align',
		},
		{
			field: 'STATUS',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellRenderer: (params) => {
				const status = params.data.STATUS ? 'Received' : 'Partially Received';
				return <>{status}</>;
			},
		},
	];

	const Billcolumn = [
		{
			field: 'BILL#',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 50,
			flex: 1,
			cellStyle: { color: '#3B58FF' },
			headerStyle: { color: '#555B67' },
		},
		{
			field: 'DATE',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellStyle: { color: '#555B67' },
			headerStyle: { color: '#555B67' },
		},
		{
			field: 'STATUS',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
		},
		{
			field: 'DUE DATE',
			suppressSizeToFit: true,
			minWidth: 40,
			flex: 1,
			cellClass: 'right-align',
			headerClass: 'HeaderBg right-align',
		},
		{
			field: 'AMOUNT',
			suppressSizeToFit: true,
			minWidth: 40,
			flex: 1,
			cellClass: 'right-align',
			headerClass: 'HeaderBg right-align',
		},
		{
			field: 'BALANCE DUE',
			suppressSizeToFit: true,
			minWidth: 70,
			flex: 1,
			cellClass: 'right-align',
			headerClass: 'HeaderBg right-align',
		},
	];

	const renderTabContent = () => {
		switch (activeTab) {
			case 'Product Details':
				return <ProductDetails productDetails={productDetails} />;
			case 'Transactions':
				return <Transactions />;
			default:
				return null;
		}
	};

	// Open the confirmation modal when discard button is clicked
	const handleDiscardQuantityClick = () => {
		setIsConfirmDiscardModalOpen(true); // Open confirmation modal
	};

	// Confirm discard action
	const handleConfirmDiscard = async () => {
		// Perform the discard action here (e.g., update quantity, etc.)
		const body = {
			purchase_order_id: purchaseOrderDetails.purchase_order_id,
			purchase_order_product_id: productDetails?.purchase_order_product_id,
			discard_quantity: discardProductQuanity
		}
		await discard_purchase_order_product_quantity(body)
		setIsConfirmDiscardModalOpen(false); // Close confirmation modal
		setIsDiscardQuantityModalOpen(false);
		// Close discard quantity modal
		toast.success('Quantity discarded successfully');
		handleFetchPurchaseOrderDetails()
	};

	// Cancel discard action
	const handleCancelDiscard = () => {
		setIsConfirmDiscardModalOpen(false); // Close confirmation modal
	};

	async function handleFetchPurchaseOrderDetails() {
		try {
			const params = {
				filterBy: 'purchase_order_id',
				filterValue: id,
			};
			const response = await fetchPurchaseOrders(params);
			const purchaseOrderData = response.purchaseOrders[0]; // Assuming the response contains the purchase order in an array
			setPurchaseOrderDetails(purchaseOrderData);
		} catch (error) {
			console.error('Error fetching purchase order details:', error);
			toast.error('Something went wrong while fetching purchase order details.');
		}
	}

	const handleConfirmDelete = async () => {
		try {
			if (id) {
				// Call the delete API
				await deletePurchaseOrder(id);
				toast.success('Purchase order deleted successfully.');
				setIsDeleteModalOpen(false); // Close modal after deleting
				navigate(`/user/${suburl}/purchases-order`);
			}
		} catch (error) {
			toast.error('Failed to delete purchase order');
		}
	};
	const handleCancelDelete = () => {
		setIsDeleteModalOpen(false); // Close modal if cancel is clicked
	};

	const handleCancelUpdatePOStatus = () => {
		setIsUpdatePOStatusModelOpen(false);
	};



	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	async function handleUpdatePOStatus(status) {
		try {
			const body = {
				status,
			};
			await update_purchase_order_status(id, body);
			handleCancelUpdatePOStatus();
			toast.success('Purchase order status updated.');
			navigate(`/user/${suburl}/purchases-order`);
		} catch (error) {
			toast.error('Something went wrong while changing the status.');
		}
	}

	useEffect(() => {
		if (id) {
			handleFetchPurchaseOrderDetails();
		}
	}, [id]);

	return (
		<div style={{ backgroundColor: "#EDEDF7" }}>
			<Header
				headerTitle="Purchase Order Details"
				closeButton={{
					showCloseButton: true,
					handler: () => navigate(`/user/${suburl}/purchases-order`),
				}}
				buttons={[
					{
						id: 1,
						className: 'btn-gray mt-0 px-4 ',
						button: 'Edit',
						handler: () => navigate(`/user/${suburl}/purchases-order-edit/${id}`),
					},
					{
						id: 7,
						className: 'mt-0 POStausButton',
						button: (
							<div
								className="dropdown"
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									height: '12px',
									width: '133px',
									paddingLeft: '0%'
								}}
							>
								<button
									className="btn btn-light dropdown-toggle"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
									style={{
										height: '18px',
										width: '100px',
										fontSize: '14px',
										border: 'none',
										backgroundColor: 'transparent',
										boxShadow: 'none',
										cursor: 'default',
									}}
								>
									Update PO Status
								</button>
								<ul
									className="dropdown-menu font-14 DropdownMenuSize  DropdownPadding"
									style={{ minWidth: '150px', }}
								>
									<li>
										<a
											className="dropdown-item"
											onClick={() => {
												SetUpdatePOStatus('is_approved');
												setIsUpdatePOStatusModelOpen(true);
											}}
											style={{ cursor: 'pointer', fontSize: '14px' }}
										>
											Approved
										</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											onClick={() => {
												SetUpdatePOStatus('is_issued');
												setIsUpdatePOStatusModelOpen(true);
											}}
											style={{ cursor: 'pointer', fontSize: '14px' }}
										>
											Issued
										</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											onClick={() => {
												SetUpdatePOStatus('is_in_transit');
												setIsUpdatePOStatusModelOpen(true);
											}}
											style={{ cursor: 'pointer', fontSize: '14px' }}
										>
											In-Transit
										</a>
									</li>
									{purchaseOrderDetails?.is_issued && (
										<li>
											<a
												className="dropdown-item"
												onClick={() => {
													SetUpdatePOStatus('is_cancelled');
													setIsUpdatePOStatusModelOpen(true);
												}}
												style={{ cursor: 'pointer', fontSize: '14px' }}
											>
												Cancelled
											</a>
										</li>
									)}
								</ul>
							</div>
						),
					},
					{
						id: 2,
						className: 'btn-light mt-0',
						button: (
							<div
								className="dropdown"
								style={{
									display: 'inline-block',
									height: '12px',
									width: '80px',
								}}
							>
								<button
									className="btn btn-light dropdown-toggle"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
									style={{
										height: '18px',
										width: '80px',
										marginTop: '-5px',
										fontSize: '14px',
										border: 'none',
										backgroundColor: 'transparent',
										boxShadow: 'none',
										cursor: 'default',
									}}
								>
									Send
								</button>
								<ul
									className="dropdown-menu font-14 DropdownMenuSize "
									style={{ minWidth: '112px', marginTop: '3px' }}
								>
									<li>
										<a
											className="dropdown-item"
											onClick={() => navigate(`/user/${suburl}/purchases-order-send-emails/${id}`)} // Navigate to Send Email Page
											style={{ cursor: 'pointer', fontSize: '14px' }}
										>
											Send Email
										</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											onClick={() => console.log('Send SMS clicked!')} // Add your SMS functionality here
											style={{ cursor: 'pointer', fontSize: '14px' }}
										>
											Send SMS
										</a>
									</li>
								</ul>
							</div>
						),
					},
					{
						id: 3,
						className: 'mt-0 btn-gray add-pro',
						button: 'PDF/Print',
						handler: async () => {
							await setIsShowPdfView(true);
							printPurchaseOrderPdf();
						},
					},
					{
						id: 4,
						className: 'btn-gray add-pro',
						button: 'Convert To Bill',
						handler: () => setIsConvertToBillOpen(true),
					},
					{
						id: 5,
						className: 'btn-gray',
						button: 'Receive Order',
						handler: () => navigate(`/user/${suburl}/purchases-recieve-create/${id}`),
					},
					purchaseOrderDetails &&
					!purchaseOrderDetails?.is_issued && {
						id: 6,
						className: 'btn-gray add-pro',
						button: 'Delete',
						handler: () => setIsDeleteModalOpen(true),
					},
					// Add "Update PO Status" dropdown after the "Edit" button

				].filter(Boolean)}
				dropDownButtons={[]}
			/>


			{!purchaseOrderDetails ? (
				<PageLoader />
			) : (
				<div>
					<BillReceiveTable
						tableConfig={{
							billTable: {
								title: 'Bills',
								column: Billcolumn,
								row:
									purchaseOrderDetails?.purchase_bill.length > 0
										? purchaseOrderDetails?.purchase_bill.map((pb) => {
											const formattedBillDate = new Date(pb.bill_date).toLocaleDateString(
												'en-GB',
											);
											const formattedDueDate = new Date(pb.due_date).toLocaleDateString('en-GB');
											return {
												id: pb?.bill_number,
												'BILL#': pb.bill_number,
												DATE: formattedBillDate,
												STATUS: pb.status,
												'DUE DATE': formattedDueDate,
												AMOUNT: pb.amount,
												'BALANCE DUE': pb.balance_due,
											};
										})
										: [],
								count: purchaseOrderDetails?.purchase_bill.length,
								onRowClick: () => { },
							},
							receiveTable: {
								title: 'Receive',
								column: Receivecolumn,
								row:
									purchaseOrderDetails?.purchase_receive.length > 0
										? purchaseOrderDetails?.purchase_receive.map((pb) => {
											return {
												id: pb.purchase_receive_id,
												'PR#': pb.purchase_receive_id,
												DATE: getDate(pb.receive_date),
												'VENDOR NAME': pb?.vendor?.vendor_name,
												STATUS: pb.status,
											};
										})
										: [],
								count: purchaseOrderDetails?.purchase_receive.length,
								onRowClick: (id) => {
									if (id) {
										navigate(`/user/${suburl}/purchases-recieve-details/${id}`);
									}
								},
							},
						}}
					/>

					<PurchaseOrderInvoice
						isShowPdfView={isShowPdfView}
						setIsShowPdfView={setIsShowPdfView}
						printPdfRef={printPdfRef}
						purchaseOrderDetails={purchaseOrderDetails}
						handleProductDetails={(product) => {
							setProductDetails(product);
							setIsSidebarOpen(true);
						}}
						handleDiscardQuantity={(prod) => {
							setProductDetails(prod);
							setIsDiscardQuantityModalOpen(true);
						}}
					/>
				</div>
			)}

			{/* Sidebar ka layout */}
			{isSidebarOpen && (
				<div className="sidebar">
					<div
						className="d-flex align-self-center justify-content-sm-between "
						style={{ gap: '60px' }}
					>
						<div className="VenderDetails">Product Details</div>

						<div
							className="d-flex align-self-center "
							style={{ gap: '26px', alignItems: 'center' }}
						>
							<div>
								<Link to={`/user/${suburl}/purchases-order-details/${id}`}>View more details</Link>
							</div>

							<div
								className="SidebaarCloseBTN d-flex align-self-center justify-content-sm-center"
								onClick={toggleSidebar}
							>
								<img
									src={BlackClose}
									alt=""
									style={{ width: '14px', cursor: 'pointer' }}
								/>
							</div>
						</div>
					</div>

					<br />

					<div
						className="d-flex"
						style={{ gap: '20px' }}
					>
						<div className="profileImage d-flex align-self-center justify-content-sm-center">
							<img
								src={ProfilePlaceHolder}
								alt=""
								style={{ width: '55px', borderRadius: '10px' }}
							/>
						</div>

						<div
							className="d-flex flex-column"
							style={{ gap: '15px' }}
						>
							<div
								style={{
									fontSize: '18px',
									fontWeight: '600',
									color: '#333',
								}}
							>
								{productDetails?.product?.product_name}
							</div>

							<div
								style={{
									fontSize: '14px',
									color: 'var(--sub, #555B67)',
								}}
							>
								<b>{productDetails?.product_packaging}</b> contains{' '}
								<b>{productDetails?.product?.contains}</b>
							</div>
						</div>
					</div>
					<br />
					<hr />

					{/* tabs  */}
					<div>
						<ul className="nav mb-4 mt-2">
							{navTabs.map((item, index) => (
								<li
									key={index}
									className="nav-item"
								>
									<a
										className={`nav-link ${activeTab === item ? 'active' : ''} cursur-pointer`}
										href="#"
										onClick={(e) => {
											e.preventDefault(); // Prevent the default anchor click behavior
											setActiveTab(item);
										}}
									>
										{item}
									</a>
								</li>
							))}
						</ul>
						<hr
							style={{
								backgroundColor: '#E3E4E5',
								boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
								marginTop: '-13px',
							}}
						/>
						<div>{renderTabContent()}</div>
					</div>
				</div>
			)}

			{isConvetToBillOpen && (
				<ConvertToBillModal isConvertToBillOpen={isConvetToBillOpen} setIsConvertToBillOpen={setIsConvertToBillOpen} purchaseOrderDetails={purchaseOrderDetails} />
			)}

			{isDiscardQuantityModalOpen && (
				<Modal
					className="discardQuantityModal"
					isOpen={isDiscardQuantityModalOpen}
					onClose={setIsDiscardQuantityModalOpen}
					cancelButton={setIsDiscardQuantityModalOpen}
					title={
						<div
							style={{
								fontWeight: '600',
								color: '#0F1C35',
								fontSize: '22px',
								paddingTop: '10px',
							}}
						>
							Discard Quantity
						</div>
					}
					headerClassName="discard-header"
					bodyClassName="discard-Body"
				>
					<div>
						<div
							style={{
								padding: '20px 0px',
							}}
						>
							<table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '-37px' }}>
								<thead>
									<tr
										style={{
											backgroundColor: '#E6F5FF',
											textAlign: 'left',
											color: '#555B67',
											fontSize: '14px',
										}}
									>
										<th style={{ padding: '10px' }}>ITEMS AND DESCRIPTION</th>
										<th>ORDERED</th>
										<th>RECEIVED STATUS</th>
										<th>REMAINING</th>
										<th>QUANTITY TO DISCARD</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ padding: '10px' }}>
											<a
												href="#"
												style={{ color: 'blue', textDecoration: 'underline' }}
											>
												{productDetails?.product?.product_name}
											</a>
										</td>
										<td>
											{productDetails?.order_quantity}
											<br />
											<span>{productDetails?.product_packaging}</span>
										</td>
										<td>
											<div>
												{productDetails?.received_quantity || 0} <br />
												Received
											</div>
										</td>
										<td>
											{productDetails?.order_quantity - productDetails?.received_quantity || 0}
										</td>
										<td>
											<input
												type="number"
												value={discardProductQuanity}
												onChange={(e) => setDiscardProductQuantity(e.target.value)}
												placeholder="10"
												style={{
													width: '50px',
													padding: '5px',
													borderRadius: '4px',
													border: '1px solid #ccc',
												}}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'end',
								gap: 4,
								marginTop: '22px',
							}}
						>
							<Button
								type="button"
								className="mt-0 btn-primary add-pro"
								button=" Discard Quantity"
								onClick={handleDiscardQuantityClick}
							/>
						</div>
					</div>
				</Modal>
			)}
			{/* Confirmation Modal */}
			<ConfirmationModal
				isOpen={isDeleteModalOpen} // Pass the state of the modal
				// onClose={handleCancelDelete} // Pass close handler
				title="Confirm Deletion"
				content={
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '500',
							color: 'black',
						}}
					>
						Are you sure you want to delete
						{" "}<span
							style={{
								fontWeight: '600',
							}}
						>
							{purchaseOrderDetails?.purchase_order_number}
						</span>
						{" "}PO ?
					</p>
				}
				onCancel={handleCancelDelete} // Cancel button logic
				onOk={handleConfirmDelete} // Ok button logic (confirm deletion)
			/>

			{/* Confirmation Modal */}
			<ConfirmationModal
				isOpen={isConfirmDiscardModalOpen}
				title="Confirm Discard"
				content={
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '500',
							color: 'black',
						}}
					>
						Are you sure you want to discard the quantity?
					</p>
				}
				onClose={handleCancelDiscard}
				onCancel={handleCancelDiscard}
				onOk={handleConfirmDiscard}
			/>

			{isUpdatePOStatusModelOpen && (
				<UpdatePOStatusModel
					isUpdatePOStatusModelOpen={isUpdatePOStatusModelOpen}
					setIsUpdatePOStatusModelOpen={setIsUpdatePOStatusModelOpen}
					handleUpdatePOStatus={() => {
						handleUpdatePOStatus(updatePOStatus);
					}}
					status={updatePOStatus}
				/>
			)}
			{/* <ConfirmationModal
				isOpen={isUpdatePOStatusModelOpen} // Pass the state of the modal
				onClose={handleCancelUpdatePOStatus} // Pass close handler
				title="Confirm Update PO Status"
				content={
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '500',
							color: 'black',
						}}
					>
						Are you sure you want to change update PO status
						<span
							style={{
								fontWeight: '600',
								marginLeft: '10px',
							}}
						>
							{purchaseOrderDetails?.purchase_order_number}
						</span>
					</p>
				}
				onCancel={handleCancelUpdatePOStatus} // Cancel button logic
				onOk={() => {
					handleUpdatePOStatus(updatePOStatus);
				}} // Ok button logic (confirm deletion)
			/> */}
		</div>
	);
}

export default PurchaseOrderDetails;
