import React, { useEffect, useRef, useState } from 'react';
import InputField from '../../../component/form/FormInput';
import { VendorTds } from '../../../utils/dropsdown';
import InputSelect from '../../../component/inputSelect/InputSelect';
import Modal from '../../../component/modal/Modal';
import { toast } from 'react-toastify';
import { fetchPaymentTerm } from '../../../services/vendor';
import Upload from '../../../assets/image/upload.svg';

const OtherDetails = ({ error, disabled, setError, data, handleInputChange }) => {
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPaymentTermsModal, setOpenPaymentTermsModal] = useState(false);
  const [paymentTermParams, setPaymetTermParams] = useState({
    page: 1,
    limit: 10,
    search: '',
  });




  return (
    <div>
      <div className="row mt-1">
        <div className="col-6">
          <InputField
            label="PAN"
            placeholder="Vendor PAN"
            type="text"
          />
        </div>
        <div className="col-6">
          <InputField
            label="GST"
            placeholder="Vendor GST"
            type="text"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <InputSelect
            label="Payment Terms"
            returnInputValue={true}
            placeholder="Vendor Payment Terms"
          />
        </div>

        <div className="col-2">
          <div style={{ marginBottom: "10px", marginTop: "1px", color: '#000000' }}>
            <span> Enable Portal</span>
            <label style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: '15px' }}>
              <input
                type="checkbox"
                style={{ width: "20px", height: "20px" }}
              />
              Enable Portal
            </label>
          </div>
        </div>
        <div className="col-4">
          <InputField
            label="Documents"
            placeholder="Upload Document"
            type="text"
            rightIcon={{
              src: Upload,
              alt: "Upload Icon",
              onClick: () => document.getElementById('upload-input').click(),
            }}
          />
          <input
            id="upload-input"
            type="file"
            style={{ display: "none" }}
            onChange={(event) => {
              const file = event.target.files[0];
              if (file) {
                console.log("Selected file:", file.name);
              }
            }}
          />
        </div>
      </div>



    </div>
  );
};

export default OtherDetails;
