export const PurchaseReceivedColoum = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'PURCHASE RECEIVE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'PO NUMBER',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'VENDOR NAME',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'QUANTITY',
		headerClass: 'HeaderBg right-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
];

export const convertToBillColumn = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'PURCHASE ORDER#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'ORDER DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
];

export const Billcolumn = [
	{
		field: 'BILL#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 50,
		flex: 1,
		cellStyle: { color: '#3B58FF' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellStyle: { color: '#555B67' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
	},
	{
		field: 'DUE DATE',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'AMOUNT',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'BALANCE DUE',
		suppressSizeToFit: true,
		minWidth: 70,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
];

export const Ordercolumn = [
	{
		field: 'ORDER#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 50,
		flex: 1,
		cellStyle: { color: '#3B58FF' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellStyle: { color: '#555B67' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
	},
	{
		field: 'DUE DATE',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'AMOUNT',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'BALANCE DUE',
		suppressSizeToFit: true,
		minWidth: 70,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
];
